import { Action, Reducer } from "redux";
import { InitialStateEvents } from "./initial-state";
import { KnownAction } from "../actions/action-events";
import eventDtoToMyCompetitionsByDay from "../mappers/mapper-events";
import { MyCompetitionsByDay } from "src/model/myTypes";
import { UserActions } from "../actions/action-user";

const unloadedState: MyCompetitionsByDay = InitialStateEvents;

export const reducer: Reducer<MyCompetitionsByDay> = (
  state: MyCompetitionsByDay | undefined,
  incomingAction: Action
): MyCompetitionsByDay => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as KnownAction | UserActions;
  switch (action.type) {
    case "IS_LOADING_EVENTS":
      return {
        ...state,
        isLoadingEvents: true,
      };
    case "IS_LOADING_EVENTS_FINISHED":
      return {
        ...state,
        isLoadingEvents: false,
      };
    case "GET_EVENTS_BY_DAY":
      const adaptedEventsByDay = eventDtoToMyCompetitionsByDay(
        action.payload.response,
        action.payload.isFirst,
        state.list
      );
      return {
        ...state,
        list: {
          ...adaptedEventsByDay,
        },
        isLoadingEvents: !action.payload.isFinished,
      };

    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
