import React from "react";
import { CallbackComponent } from "redux-oidc";
import { userManager } from "src/utils/userManager";
import { toast } from "react-toastify";
import styles from "./loginCallback.module.css";

export default class CallbackPage extends React.Component {
  successCallback = (user) => {
    if (process.env.NODE_ENV === "development") {
      console.log("CallbackPage sucess", user);
    }
    this.props.history.push("/dashboard");
  };

  errorCallback = (error) => {
    if (process.env.NODE_ENV === "development") {
      console.log("CallbackPage error", error);
    }
    toast.error(
      "Something went wrong when trying to Login. Please try again, or contact us."
    );
    this.props.history.push("/");
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div className={styles.loadingParent}>
          <div className={styles.loadingContainer}>
            <div className={"loader-circle"}></div>
          </div>
        </div>
      </CallbackComponent>
    );
  }
}
