import React, { Component } from "react";
import styles from "./main.module.css";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";
import { connect } from "react-redux";
import { getBetAccountingMainStats } from "src/redux/actions/action-main";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "src/utils/numberUtils";
import MainStatsSidebar from "./main-stats-sidebar";
import { StatsOverviewChildren, StatsOverviewKeys } from "src/model/myTypes";
import OverviewListCompetitions from "./bets_overview/listCompetitions";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import { optionItem, SelectForm } from "src/components/inputs/selectForm";
import { getTraders } from "src/redux/actions/action-reports";
import { changeTrader } from "src/redux/actions/action-settings";

const TABLE_ROWS: { id: string; name: string }[] = [
  { id: "stake", name: "Stake" },
  { id: "profitLoss", name: "Profit/Loss" },
  { id: "margin", name: "Margin" },
  { id: "bets", name: "Bets" },
];

const TABLE_HEADERS: { title: string; value: keyof StatsOverviewChildren }[] = [
  { title: "Today", value: "today" as keyof StatsOverviewChildren },
  { title: "Yesterday", value: "yesterday" as keyof StatsOverviewChildren },
  { title: "This Week", value: "thisWeek" as keyof StatsOverviewChildren },
  { title: "Last Week", value: "lastWeek" as keyof StatsOverviewChildren },
  { title: "This Month", value: "thisMonth" as keyof StatsOverviewChildren },
  { title: "Last Month", value: "lastMonth" as keyof StatsOverviewChildren },
  { title: "This Year", value: "thisYear" as keyof StatsOverviewChildren },
  { title: "Overall", value: "overall" as keyof StatsOverviewChildren },
];

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  betsOverviewStartDate: Date | null;
  betsOverviewEndDate: Date | null;
  selected: keyof StatsOverviewChildren | null;
  timeline: String;
};

const initalLocalState: State = {
  betsOverviewStartDate: null,
  betsOverviewEndDate: null,
  selected: null,
  timeline: "",
};

class Main extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initalLocalState };
    this.props.getBetAccountingMainStats(this.props.traderSelected);
    this.props.getTraders();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.traderSelected !== this.props.traderSelected) {
      this.props.getBetAccountingMainStats(this.props.traderSelected);
    }
  }

  printValue(value: number, tableRowKey: string) {
    switch (tableRowKey) {
      case "stake":
        return formatCurrency(value, 0);
      case "profitLoss":
        return formatCurrency(value, 0);
      case "margin":
        return formatPercentage(value, 2);
      case "bets":
        return formatNumber(value, 0);

      default:
        break;
    }
  }

  handleListCompetitions = (newSelected: keyof StatsOverviewChildren) => {
    if (this.state.selected !== newSelected) {
      this.getTimeline(newSelected);
      this.setState({
        selected: newSelected,
        betsOverviewStartDate: new Date(
          this.props.statsOverview.configs[newSelected].startDate as Date
        ),
        betsOverviewEndDate: new Date(
          this.props.statsOverview.configs[newSelected].endDate as Date
        ),
      });
    } else {
      this.closeCompetitions();
    }
    this.forceUpdate();
  };

  getTimeline = (selected: keyof StatsOverviewChildren) => {
    TABLE_HEADERS.forEach((header) => {
      if (header.value === selected) {
        this.setState({ timeline: header.title });
      }
    });
  };

  closeCompetitions = () => {
    this.setState({
      selected: null,
      betsOverviewStartDate: null,
      betsOverviewEndDate: null,
      timeline: "",
    });
  };

  renderTableHeader() {
    return TABLE_HEADERS.map((header) => {
      return (
        <div className={styles.statValue} key={header.value}>
          <ButtonBase
            classes={{
              root: classnames(
                styles.btn,
                this.state.selected === header.value && styles.active
              ),
            }}
            component={"div"}
            onClick={() => this.handleListCompetitions(header.value)}
          >
            {header.title}
          </ButtonBase>
        </div>
      );
    });
  }

  renderOverView() {
    return TABLE_ROWS.map((line) => {
      const lineKey = line.id as StatsOverviewKeys;
      if (typeof this.props.statsOverview[lineKey] === "object") {
        const data = { ...this.props.statsOverview[lineKey] };
        const checkLine = line.id === "profitLoss" || line.id === "margin";
        return (
          <div className={styles.tableLine} key={lineKey}>
            <div className={styles.statName}>{line.name}</div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.today > 0 && styles.green,
                checkLine && data.today < 0 && styles.red
              )}
            >
              {this.printValue(data.today, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.yesterday > 0 && styles.green,
                checkLine && data.yesterday < 0 && styles.red
              )}
            >
              {this.printValue(data.yesterday, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.thisWeek > 0 && styles.green,
                checkLine && data.thisWeek < 0 && styles.red
              )}
            >
              {this.printValue(data.thisWeek, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.lastWeek > 0 && styles.green,
                checkLine && data.lastWeek < 0 && styles.red
              )}
            >
              {this.printValue(data.lastWeek, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.thisMonth > 0 && styles.green,
                checkLine && data.thisMonth < 0 && styles.red
              )}
            >
              {this.printValue(data.thisMonth, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.lastMonth > 0 && styles.green,
                checkLine && data.lastMonth < 0 && styles.red
              )}
            >
              {this.printValue(data.lastMonth, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.thisYear > 0 && styles.green,
                checkLine && data.thisYear < 0 && styles.red
              )}
            >
              {this.printValue(data.thisYear, line.id)}
            </div>
            <div
              className={classnames(
                styles.statValue,
                checkLine && data.overall > 0 && styles.green,
                checkLine && data.overall < 0 && styles.red
              )}
            >
              {this.printValue(data.overall, line.id)}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  formatLabelValueProfitLoss = (value: any) => {
    return [formatCurrency(Number(value)), "P/L"];
  };

  formatLabelValueBets = (value: any) => {
    return [formatNumber(Number(value), 0), "Bets"];
  };

  sortAlphabeticallyWithIdZeroFirst = (a: optionItem, b: optionItem) => {
    if (a.id === 0 || a.id === "") {
      return -1;
    } else if (b.id === 0 || b.id === "") {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  };

  handleSelectTrader(traderId: string): void {
    this.props.changeTrader(traderId);
  }

  renderTrader() {
    let optionsTraders: optionItem[] = [];
    let uniqueTraders: { [key: string]: boolean } = {};
    this.props.traders.forEach((trader) => {
      optionsTraders.push({ id: trader.id, name: trader.name });
      uniqueTraders[trader.id] = true;
    });
    // add item "trader.name = ALL" to the beginning of the optionsTraders (only if not exists already)
    if (uniqueTraders.hasOwnProperty("") === false) {
      optionsTraders.unshift({ id: "", name: "ALL" });
    }
    // sort traders a-z
    optionsTraders.sort((a, b) => this.sortAlphabeticallyWithIdZeroFirst(a, b));

    return (
      <div className={styles.traderContainer}>
        <SelectForm
          wrapperClassName={styles.noSpaceLeft}
          changeValue={(value: string) => this.handleSelectTrader(value)}
          label={"Trader"}
          selectedOptionId={this.props.traderSelected}
          optionsArray={optionsTraders}
        ></SelectForm>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.mainWrapper}>
        <div className={styles.mainContent}>
          {this.renderTrader()}
          <span className={styles.title3}>Overview</span>
          <div className={styles.scrollableContainer}>
            <div className={styles.overViewTable}>
              <div className={styles.tableHead}>
                <div className={styles.statName}></div>
                {this.renderTableHeader()}
              </div>
              {this.renderOverView()}
            </div>
          </div>
          {this.state.selected !== null && (
            <OverviewListCompetitions
              startDate={this.state.betsOverviewStartDate as Date}
              endDate={this.state.betsOverviewEndDate as Date}
              timeline={this.state.timeline}
              close={this.closeCompetitions}
              traderId={this.props.traderSelected}
            />
          )}
          <div className={styles.chartsContainer}>
            <div className={styles.chartContainer}>
              <p className={styles.chartTitle}>Bets</p>
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <AreaChart
                  data={this.props.betsChart}
                  margin={{ top: 24, right: 10, bottom: 16, left: 16 }}
                  className={styles.linearChart}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey="day" />
                  <YAxis
                    domain={["auto", "auto"]}
                    type="number"
                    orientation={"right"}
                  />
                  <Tooltip
                    formatter={this.formatLabelValueBets}
                    wrapperStyle={{ boxShadow: "2px 2px 3px 0px rgb(0, 0, 0)" }}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                    labelStyle={{ color: "#22272b" }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#00acee"
                    strokeWidth={2}
                    fill="#000000"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className={styles.chartContainer}>
              <p className={styles.chartTitle}>Profit/Loss Grid</p>
              <ResponsiveContainer width={"100%"} height={"100%"}>
                <AreaChart
                  data={this.props.profitLossChart}
                  margin={{ top: 24, right: 10, bottom: 16, left: 16 }}
                  className={styles.linearChart}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey="day" />
                  <YAxis
                    domain={["auto", "auto"]}
                    type="number"
                    orientation={"right"}
                  />
                  <Tooltip
                    formatter={this.formatLabelValueProfitLoss}
                    wrapperStyle={{ boxShadow: "2px 2px 3px 0px rgb(0, 0, 0)" }}
                    contentStyle={{
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                    labelStyle={{ color: "#22272b" }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#00acee"
                    strokeWidth={2}
                    fill="#000000"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className={styles.footerNote}>
            Resumed data here, only takes into account settled bets.
          </div>
        </div>
        <div className={styles.rightSide}>
          <MainStatsSidebar tableRows={TABLE_ROWS} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsChart: state.charts.betsChart,
    profitLossChart: state.charts.profitLossChart,
    statsOverview: state.statsOverview,
    traderSelected: state.settings.traderId,
    traders: state.reports.traders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBetAccountingMainStats: (traderId: string) => {
      dispatch(getBetAccountingMainStats(traderId));
    },
    getTraders: () => {
      dispatch(getTraders());
    },
    changeTrader: (traderId: string) => {
      dispatch(changeTrader(traderId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
