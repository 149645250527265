import React, { Component } from "react";
import styles from "./listEventBets.module.css";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { getBetsOverView } from "src/redux/actions/action-bets-overview";
import BetInfo from "../../my_bets/betsInfo";

type OwnProps = {
  eventId: string;
  traderId: string;
};
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class OverviewListEventBets extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleGetBetsOverview(this.props.eventId);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.traderId !== this.props.traderId
    ) {
      this.handleGetBetsOverview(this.props.eventId);
    }
  }

  handleGetBetsOverview = (eventId: string) => {
    if (eventId !== "" && eventId.length > 0) {
      this.props.getBetsOverView(eventId, this.props.traderId);
    }
  };

  render() {
    const isReady =
      typeof this.props.betsOverview !== "undefined" &&
      this.props.betsOverview.length > 0 &&
      this.props.eventId !== "";
    return isReady ? (
      <div className={styles.loadingParent}>
        {this.props.isLoadingBets && (
          <div className={styles.loadingContainer}>
            <div className={"loader-circle"}></div>
          </div>
        )}
        <BetInfo isFundPage={false} isBotsPage={false} bets={this.props.betsOverview} />
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsOverview: state.betsOverview.bets,
    isLoadingBets: state.betsOverview.isLoadingBets,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBetsOverView: (eventId: string, traderId: string) => {
      dispatch(getBetsOverView(eventId, traderId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewListEventBets);
