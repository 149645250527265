import { getInstanceBackend } from "./axios";
import {
  Traders,
  TradersCompetition,
  TradersRegion,
  ViewTypeReports,
} from "src/model/myTypes";
import { ReportsObject } from "src/model/classes";
import { toast } from "react-toastify";
import { mapAllTradersCompetitions } from "src/redux/mappers/mapper-fund";

// type GetTradersAction = {
//   type: "GET_TRADERS_WITH_COMPETITIONS";
//   payload: TradersWithCompetitions[];
// };
type GetReportsAction = {
  type: "GET_REPORTS";
  payload: {
    data: ViewTypeReports[];
    type: number;
  };
};
type isLoadingReportsAction = {
  type: "IS_LOADING_REPORTS";
};

type ChangeDatesReportsAction = {
  type: "CHANGE_DATE_REPORTS";
  payload: { initialDay: Date; endDay: Date };
};

type GetTradersAction = {
  type: "GET_TRADERS";
  payload: Traders[];
};
type GetTradersRegionAction = {
  type: "GET_TRADERS_REGION";
  payload: TradersRegion[];
};
type GetTradersCompetitionAction = {
  type: "GET_TRADERS_COMPETITION";
  payload: TradersCompetition[];
};

export type ReportsActions =
  | GetTradersAction
  | GetTradersRegionAction
  | GetTradersCompetitionAction
  | isLoadingReportsAction
  | GetReportsAction
  | ChangeDatesReportsAction; // | action1 | action2 | ...

// export function getTradersAndCompetitions() {
//   return function action(dispatch: any) {
//     const request = getInstanceBackend()({
//       method: "get",
//       url: "/Traders",
//     });
//     return request
//       .then((response) => {
//         dispatch({
//           type: "GET_TRADERS_WITH_COMPETITIONS",
//           payload: response.data,
//         });
//       })
//       .catch((error) => {
//         // do nothing
//         console.log("error in getTradersAndCompetitions", error);
//       });
//   };
// }

export function getTraders() {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/Traders",
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_TRADERS",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getTradersAndCompetitions", error);
      });
  };
}
export function getTradersRegion(traderId: string) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/TradersRegion?TraderId=" + traderId,
      params: traderId,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_TRADERS_REGION",
          payload: response,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getTradersAndCompetitions", error);
      });
  };
}
export function getTradersCompetition(traderId: string) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/TradersCompetition?TraderId=" + traderId,
    });
    return request
      .then((response) => {
        let traderCompetitionList = response.data;
        if (traderId === "") {
          traderCompetitionList = mapAllTradersCompetitions(response.data);
        }
        dispatch({
          type: "GET_TRADERS_COMPETITION",
          payload: traderCompetitionList,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getTradersAndCompetitions", error);
      });
  };
}

export function changeReportsDate(initialDay: Date, endDay: Date) {
  return {
    type: "CHANGE_DATE_REPORTS",
    payload: { initialDay: initialDay, endDay: endDay },
  };
}

export function getReports(reportsObject: ReportsObject) {
  return function action(dispatch: any) {
    dispatch(isLoading());
    // ensure all hours of end day is included in response
    let safeEndDay = new Date(reportsObject.endDay);
    safeEndDay.setHours(23, 59, 59, 999);
    const request = getInstanceBackend()({
      method: "get",
      url: "/reports",
      params: {
        ...reportsObject,
        endDay: safeEndDay.toISOString(),
      },
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_REPORTS",
          payload: {
            data: response.data,
            type: reportsObject.type,
          },
        });
      })
      .catch((error) => {
        // do nothing
        toast.error("error in GetCalendarReports", error);
      });
  };
}

function isLoading() {
  return {
    type: "IS_LOADING_REPORTS",
  };
}
