import React, { Component } from "react";
import styles from "./reports.module.css";
import { connect } from "react-redux";
import {
  getReports,
  changeReportsDate,
} from "src/redux/actions/action-reports";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import TraderRegionCompetition from "./filters/traderRegionCompetition";
import MyDateRangePicker from "src/components/date-picker/MyDateRangePicker";
import {
  ReportViewType,
  ReportMarketPeriod,
  ReportScoreType,
} from "src/model/enums";
import { getDefaultDatesPageReports, options, optionsReports } from "src/myEnv";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import { ReportsObject } from "src/model/classes";
import ReportsData from "./reportsData";
import { optionItem, SelectForm } from "src/components/inputs/selectForm";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  traderId: string;
  regionReadId: number;
  competitionId: string;
  bookie: number;
  reportViewType: ReportViewType;
  reportMarketPeriod: ReportMarketPeriod;
  reportScoreType: ReportScoreType;
};

const initialLocalState: State = {
  traderId: "",
  regionReadId: 0,
  competitionId: "",
  bookie: 0,
  reportViewType: ReportViewType.Calendar,
  reportMarketPeriod: ReportMarketPeriod.All,
  reportScoreType: ReportScoreType.All,
};

class Reports extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialLocalState };
    this.getReportsData();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState !== this.state || prevProps.range !== this.props.range) {
      this.getReportsData();
    }
  }

  handleChangeDates = (newStartDate: Date, newEndDate: Date) => {
    this.props.changeReportsDate(newStartDate, newEndDate);
  };

  handleChangeValueSelected = (newValue: string, key: string) => {
    switch (key) {
      case "traderId":
        this.setState({
          traderId: newValue,
          regionReadId: 0,
          competitionId: "",
        });
        break;
      case "regionReadId":
        this.setState({ regionReadId: Number(newValue), competitionId: "" });
        break;
      case "competitionReadId":
        this.setState({ competitionId: (newValue as unknown) as string });
        break;
      default:
        break;
    }
  };
  handleSelectMarketPeriod(id: number): void {
    this.setState({ reportMarketPeriod: id });
  }
  handleSelectScoreType(id: number): void {
    this.setState({ reportScoreType: id });
  }
  handleSelectViewType(id: number): void {
    this.setState({ reportViewType: id });
  }
  handleSelectBookie(id: number): void {
    this.setState({ bookie: id });
  }
  handleResetFilters = () => {
    this.setState({ ...initialLocalState });
    const defaultRangeDates = getDefaultDatesPageReports();
    this.props.changeReportsDate(
      defaultRangeDates.initialDate,
      defaultRangeDates.endDate
    );
  };

  getReportsData() {
    this.props.getReports(
      new ReportsObject(
        this.state.traderId,
        this.state.regionReadId,
        this.state.competitionId,
        this.props.range.initialDay,
        this.props.range.endDay,
        this.state.reportMarketPeriod,
        this.state.reportScoreType,
        this.state.reportViewType,
        this.state.bookie
      )
    );
  }

  renderBookie() {
    let optionsBookie: optionItem[] = [{ id: 0, name: "ALL" }]; // default item
    options["Bookie"].map((bookie) => {
      return optionsBookie.push({ id: bookie.id, name: bookie.name });
    });
    return (
      <div className={styles.bookieContainer}>
        <SelectForm
          wrapperClassName={styles.noSpaceLeft}
          changeValue={(value: number) => this.handleSelectBookie(value)}
          label={"Bookie"}
          selectedOptionId={this.state.bookie}
          optionsArray={optionsBookie}
        ></SelectForm>
      </div>
    );
  }

  renderMarketPeriod() {
    return (
      <div className={styles.marketPeriodContainer}>
        {optionsReports.marketPeroidReports.map((marketPeriod) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.marketPeriod,
                marketPeriod.id === this.state.reportMarketPeriod &&
                  styles.marketPeriodActive
              ),
            }}
            key={marketPeriod.id}
            onClick={() => this.handleSelectMarketPeriod(marketPeriod.id)}
          >
            {marketPeriod.name}
          </ButtonBase>
        ))}
      </div>
    );
  }
  renderScoreType() {
    return (
      <div className={styles.scoretypeContainer}>
        {optionsReports.scoreTypeReports.map((scoreType) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.scoreType,
                scoreType.id === this.state.reportScoreType &&
                  styles.scoreTypeActive
              ),
            }}
            key={scoreType.id}
            onClick={() => this.handleSelectScoreType(scoreType.id)}
          >
            {scoreType.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  renderReportsViewType() {
    return (
      <div className={styles.viewTypeContainer}>
        {optionsReports.reportViewType.map((viewType) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.viewType,
                viewType.id === this.state.reportViewType &&
                  styles.viewTypeActive
              ),
            }}
            key={viewType.id}
            onClick={() => this.handleSelectViewType(viewType.id)}
          >
            {viewType.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.mainContent}>
          <div className={styles.filtersContainer}>
            <TraderRegionCompetition
              wrapperClassName={styles.traderRegionCompetitionContainer}
              handleChangeValueSelected={this.handleChangeValueSelected}
              selectedTraderId={this.state.traderId}
              selectedRegionReadId={this.state.regionReadId}
              selectedCompetitionReadId={this.state.competitionId}
            />
            {this.renderBookie()}
            <MyDateRangePicker
              rootClassName={styles.datePickerContainer}
              defaultStartDate={new Date(this.props.range.initialDay)}
              defaultEndDate={new Date(this.props.range.endDay)}
              changeDates={this.handleChangeDates}
            />
            {this.renderReportsViewType()}
            {this.renderMarketPeriod()}
            {this.renderScoreType()}
            <div className={styles.reportsbtnContainer}>
              <ButtonBase
                classes={{ root: styles.getReportBtn }}
                onClick={this.handleResetFilters}
              >
                Reset filters
              </ButtonBase>
            </div>
          </div>
          <div>
            <ReportsData viewType={this.state.reportViewType} />
          </div>
          <div className={styles.footerNote}>
            Resumed data here, only takes into account settled bets.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    range: state.reports.range,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getReports: (reportObject: ReportsObject) => {
      dispatch(getReports(reportObject));
    },
    changeReportsDate: (initialDay: Date, endDay: Date) => {
      dispatch(changeReportsDate(initialDay, endDay));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
