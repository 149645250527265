/** ENUMS objects to be imported/used in views */

/** EVENT related enums */

export enum SportType {
  NoSport = 0,
  Football = 1,
}

export enum CompetitionType {
  None = 0,
  League = 1,
  Cup = 2,
}

export enum EventStatus {
  NoStatus = 0,
  Scheduled = 1,
  InProgress = 2,
  Postponed = 3,
  Completed = 4,
  Abandoned = 5,
  Cancelled = 6,
  Deleted = 7,
}

export enum EventInPlayState {
  NoInPlayState = 0,
  NotStarted = 1,
  FirstHalf = 20,
  HalfTime = 21,
  SecondHalf = 22,
  FinishedRegularTime = 23,
  FirstHalfExtraTime = 30,
  HalfTimeExtraTime = 31,
  SecondHalfExtraTime = 32,
  FinishedExtraTime = 33,
  PenaltyShootout = 40,
  Finished = 9999,
}

export enum SportPeriodType {
  NoSportPeriod = 0,
  FirstHalf = 1,
  SecondHalf = 2,
  FirstHalfExtraTime = 3,
  SecondHalfExtraTime = 4,
}

/** MARKET related enums */

export enum MarketType {
  NoMarketType = 0,
  AsianHandicap = 1,
  AsianOverUnder = 2,
  OneXTwo = 6,
}

export enum MarketSelectionType {
  NoMarketSelection = 0,
  Home = 1,
  Draw = 2,
  Away = 3,
  Over = 4,
  Under = 5,
}

export enum MarketPeriod {
  None = 0,
  FullTime = 1,
  FirstHalf = 2,
  SecondHalf = 3,
  PreStart = 4,
}

export enum MarketProvider {
  NoMarketProvider = 0,
  SboBet = 1,
  Pinnacle = 2,
  BetISN = 3,
  IbcBet = 4,
  SingBet = 5,
  Betfair = 6,
  KMail88 = 51,
  Molly = 52,
  BetFootball = 61,
  Digeelva = 62,
}

export enum MarketGroupPeriod {
  NO_MARKET_GROUP_PERIOD = 0,
  ORDINARY_TIME = 1,
  EXTRA_TIME = 2,
  PENALTIES = 3,
}

export enum MarketStatus {
  NoMarketStatus = 0,
  Inactive = 1,
  Open = 2,
  Suspended = 3,
  Closed = 4,
}

export enum MarketSelectionStatus {
  NoSelectionStatus = 0,
  Active = 1,
  Winner = 2,
  Loser = 3,
  Placed = 4,
  Removed = 9,
}

export enum BetStatus {
  NoBetStatus = 0,
  Executable = 1,
  Placed = 2,
  Winner = 3,
  Loser = 4,
  Void = 5,
  Settled = 6,
  Deleted = 9,
}

export enum ScoreType {
  NoScoreType = 0,
  Goals = 11,
  Corners = 12,
  YellowCards = 13,
  RedCards = 14,
  ShotsOnTarget = 15,
  ShotsOffTarget = 16,
  Assists = 18,
  Bookings = 91,
}

export enum ReportViewType {
  Calendar = 1,
  BetType = 2,
}
export enum ReportMarketPeriod {
  All = 0,
  FullTime = MarketPeriod.FullTime,
  FirstHalf = MarketPeriod.FirstHalf,
}
export enum ReportScoreType {
  All = 0,
  Goals = ScoreType.Goals,
  Corners = ScoreType.Corners,
  Bookings = ScoreType.Bookings,
}

export enum BetListStatus {
  All = 0,
  Open = 1,
}

export enum StatsChartType {
  BetsChart = 1,
  ProfitLossChart = 2,
}

/** PUSH API related enums */
export enum ModelType {
  NoModelType = 0,
  Tampa = 1,
  DangerousAttack = 2,
  BasicStats = 3,
  AverageFairResult = 4,
  Blend = 5,
  Market = 6,
  MarketBlend = 7,
}

export enum EventSetupState { //dummy enum, not needed for now
  NoEventSetupState = 0,
}

export enum MyHubEventActionsScoresType {
  FullTime = 1,
  HalfTime = 2,
}

export enum TimeScanningOdds { // ScanningOddsPreConditions
  WhenEventEnd = 0,
  OnEventStart = 1,
  BeforeEventStart1 = 2,
  BeforeEventStart6 = 3,
  BeforeEventStart12 = 4,
  BeforeEventStart24 = 5,
  // BeforeEventStart48 = 6, // removed in 2021/02/16 because of the way odds are fetched in bookies (we fetch all odds from markets in the "today" - case ISN)
}

export enum TimeToWaitBookieOdds {
  Sec0 = 0,
  Sec5 = 1,
  Sec30 = 2,
  Min1 = 3,
}

export enum PlaceBetOdd {
  OddForMinimumValueThreshold = 0, // not supported on bookies isn/pin, so was removed from editbot page in 2021/02/16
  OddFoundInBookie = 1,
}

export enum TimeToWaitMatchBet {
  Sec0 = 0,
  Sec20 = 1,
  Min1 = 2,
  Min5 = 3,
  Hour1 = 4,
  Hour6 = 5,
  Hour24 = 6,
}

/** Logs related enums */

export enum LoggerType {
  NoLoggerType = -1,
  System = 0,
  Alive = 1,
  RefreshEvents = 2,
  RefreshOdds = 3,
  RefreshToken = 4,
  PlaceBet = 5,
  BetProfit = 6,
}

export enum BotStatus {
  Offline = 0,
  StoppedWhite = 1,
  StoppedOrange = 2,
  RunningGreen = 3,
  RunningOrange = 4,
}
