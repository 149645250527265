// import LocaleCurrency from "locale-currency";
import { isNumber } from "lodash";

export function formatNumber(
  number: number,
  minimumDecimals: number = 0
): string {
  if (typeof number === "undefined" || isNaN(number) || !isNumber(number)) {
    return "";
  } else {
    // format with user locale settings decimal separetor
    const options = {
      minimumFractionDigits: minimumDecimals,
      maximumFractionDigits: minimumDecimals, // assume always X number of decimals requested
    };
    return number.toLocaleString(undefined, options);

    // format with toFixed (requested by client)
    // return number.toFixed(minimumDecimals);
  }
}

export function formatPercentage(
  number: number,
  minimumDecimals: number = 0
): string {
  if (typeof number === "undefined" || isNaN(number)) {
    return "";
  } else {
    // format with user locale settings decimal separetor
    const options = {
      style: "percent",
      minimumFractionDigits: minimumDecimals,
    };
    return (number / 100).toLocaleString(undefined, options);
  }
}

export function formatCurrency(
  number: number,
  minimumDecimals: number = 0
): string {
  if (typeof number === "undefined" || isNaN(number) || !isNumber(number)) {
    return "";
  } else {
    // format with user locale settings decimal separetor
    // disabled currency formating, as a client request
    // const userCurrency = LocaleCurrency.getCurrency(navigator.language);
    // const options = {
    //   style: "currency",
    //   currency: userCurrency,
    //   minimumFractionDigits: minimumDecimals,
    // };

    // format like number (requested by client)
    return formatNumber(number, minimumDecimals);
  }
}
