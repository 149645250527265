import classNames from "classnames";
import React, { Component } from "react";
import styles from "./modal.module.css";

type Props = {
  // children?: React.ReactNode;
  handleClickOutside: Function;
  customStyles?: string;
};

export default class Modal extends Component<Props> {
  render() {
    return (
      <div className={styles.modalHelper}>
        <div
          className={classNames(styles.modalContent, this.props.customStyles)}
        >
          {this.props.children}
        </div>
        <div
          className={styles.modalOverlay}
          onClick={() => this.props.handleClickOutside()}
        ></div>
      </div>
    );
  }
}
