import { CompetitionsOverview } from "src/model/myTypes";
import { getInstanceBackend } from "./axios";
import { EventBetOverView, MyBetInterface } from "src/model/myTypes";
import { defaultPagination, Pagination } from "../actions/action-events";

type getCompetitionsOverview = {
  type: "GET_COMPETITIONS_OVERVIEW";
  payload: CompetitionsOverview[];
};
type GetEventsOverviewAction = {
  type: "GET_EVENTS_OVERVIEW";
  payload: EventBetOverView[];
};
type GetBetsOverviewAction = {
  type: "GET_BETS_OVERVIEW";
  payload: MyBetInterface[];
};
type isLoadingBetsAction = {
  type: "IS_LOADING_BETS_OVERVIEW";
};
type isLoadingCompetitionsAction = {
  type: "IS_LOADING_COMPETITIONS_OVERVIEW";
};

export type OverviewActions =
  | getCompetitionsOverview
  | GetEventsOverviewAction
  | GetBetsOverviewAction
  | isLoadingBetsAction
  | isLoadingCompetitionsAction;

export function getCompetitionsOverview(
  startDate: Date,
  endDate: Date,
  traderId: string
) {
  return function action(dispatch: any) {
    dispatch(isLoadingCompetitions());
    const request = getInstanceBackend()({
      method: "get",
      url:
        "/StatsCompetitions?StartDate=" +
        startDate.toISOString() +
        "&EndDate=" +
        endDate.toISOString() +
        "&traderId=" +
        traderId,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_COMPETITIONS_OVERVIEW",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getCompetitionsOverview", error);
      });
  };
}

export function getEventsOverView(
  startDay: Date,
  endDay: Date,
  competitionId: string,
  traderId: string
) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url:
        "/StatsEvents?StartDate=" +
        startDay.toISOString() +
        "&EndDate=" +
        endDay.toISOString() +
        "&CompetitionId=" +
        competitionId +
        "&traderId=" +
        traderId,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_EVENTS_OVERVIEW",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in GetEventsByDay", error);
      });
  };
}

export function getBetsOverView(
  eventId: string,
  traderId: string,
  pagination?: Pagination
) {
  return function action(dispatch: any) {
    dispatch(isLoadingBets());
    if (typeof pagination === "undefined") {
      pagination = defaultPagination;
    }
    fetchByPageBetsOverview(
      eventId,
      traderId,
      pagination.pageSize, // Api returns requested number of entries x2
      pagination.fetchedCount, // Api starts at requested position x2
      dispatch
    );
  };
}

const fetchByPageBetsOverview = function (
  eventId: string,
  traderId: string,
  pageSize: number,
  fetchedCount: number,
  dispatch: any,
  data?: string[]
) {
  let responseData: string[] = [];
  if (typeof data !== "undefined" && data !== null) {
    responseData = data;
  }

  getInstanceBackend()({
    method: "get",
    url:
      "/bets?EventId=" +
      eventId +
      "&traderId=" +
      traderId +
      "&PageSize=" +
      pageSize +
      "&FetchedCount=" +
      fetchedCount,
  })
    .then((response) => {
      responseData = responseData.concat(response.data.data);
      if (response.data.data.length === pageSize) {
        fetchedCount = fetchedCount + pageSize;
        fetchByPageBetsOverview(
          eventId,
          traderId,
          pageSize,
          fetchedCount,
          dispatch,
          responseData
        );
      } else {
        dispatch({
          type: "GET_BETS_OVERVIEW",
          payload: responseData,
        });
        return;
      }
    })
    .catch((error) => {
      // do nothing
      console.log("error in GetBetsOverview", error);
    });
  return {};
};

function isLoadingBets() {
  return {
    type: "IS_LOADING_BETS_OVERVIEW",
  };
}

function isLoadingCompetitions() {
  return {
    type: "IS_LOADING_COMPETITIONS_OVERVIEW",
  };
}
