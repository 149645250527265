import React, { Component } from "react";
import styles from "./login.module.css";
import { Button } from "@material-ui/core";
import { userManager } from "src/utils/userManager";

class Login extends Component {
  render() {
    return (
      <div className={styles.wrapperNarrow}>
        <Button
          classes={{ root: styles.loginBtn, disabled: styles.isDisabled }}
          disabled={false}
          fullWidth
          size="large"
          onClick={() => userManager.signinRedirect()}
          variant="contained"
        >
          Login open id
        </Button>
      </div>
    );
  }
}

export default Login;
