import {
  StatsChartReceived,
  BankRollFund,
  FundChangeTable,
  TradersCompetition,
} from "src/model/myTypes";
import { getDayString } from "src/utils/dateUtils";

export function formatBankRollDataByRange(
  data: StatsChartReceived[],
  numberOfDays: number,
  date: Date = new Date(),
  initialBankRoll: number
) {
  // step 1: ensure we have normalized received data (with date as keys)
  let receivedDataObject: { [key: string]: StatsChartReceived } = {};
  data.forEach((item) => {
    const dayString = new Date(item.day).toISOString();
    const element: StatsChartReceived = {
      day: dayString,
      value: item.value,
    };
    receivedDataObject[getDayString(new Date(dayString))] = element;
  });

  // step 2: build array with all days plus day before
  let bankRollData: BankRollFund = {};
  let elementValue: number = initialBankRoll;
  for (let index = 0; index <= numberOfDays; index++) {
    const elementDay: Date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - (numberOfDays - index)
    );
    // check received data if has value, to use it
    const dayString = getDayString(elementDay);
    if (receivedDataObject.hasOwnProperty(dayString) === true) {
      elementValue = elementValue + receivedDataObject[dayString].value;
    }
    bankRollData = {
      ...bankRollData,
      [dayString]: { day: dayString, value: elementValue },
    };
  }
  return bankRollData;
}

export function formatFundChangeTableData(
  data: StatsChartReceived[],
  startDate: Date,
  endDate: Date,
  initialBankRoll: number,
  initialIndex: number
) {
  let resultArray: FundChangeTable[] = [];
  // step 1: create first row
  resultArray.push({
    day: new Date(startDate),
    profitLoss: 0,
    profitLossCumulative: 0,
    bankRoll: initialBankRoll,
    index: initialIndex,
  });

  // step 2: process received data
  data.forEach((dayData, index) => {
    let wasAdded = false;
    const currentDate = new Date(dayData.day);
    if (index === 0) {
      // check first row: if is the same as requested startDate should update first row
      const requestStartDate = new Date(startDate);
      if (currentDate.toDateString() === requestStartDate.toDateString()) {
        // first item is requested startDate, so should update first row
        resultArray[0] = {
          day: currentDate,
          profitLoss: dayData.value,
          profitLossCumulative: dayData.value,
          bankRoll: initialBankRoll + dayData.value,
          index:
            ((initialBankRoll + dayData.value) / initialBankRoll) *
            initialIndex,
        };
        wasAdded = true;
      }
    }
    if (wasAdded === false) {
      const previousItem: FundChangeTable = resultArray[resultArray.length - 1];
      resultArray.push({
        day: currentDate,
        profitLoss: dayData.value,
        profitLossCumulative: previousItem.profitLossCumulative + dayData.value,
        bankRoll: previousItem.bankRoll + dayData.value,
        index:
          ((previousItem.bankRoll + dayData.value) / initialBankRoll) *
          initialIndex,
      });
    }
  });

  // step 3: create last row
  // last row: should be request endDate (if not received the end date, create it)
  const lastItem: FundChangeTable = resultArray[resultArray.length - 1];
  const requestEndDate = new Date(endDate);
  if (lastItem.day.toDateString() !== requestEndDate.toDateString()) {
    // last item is not endDate, so should create last item manually
    resultArray.push({
      day: requestEndDate,
      profitLoss: 0,
      profitLossCumulative: lastItem.profitLossCumulative,
      bankRoll: lastItem.bankRoll,
      index: lastItem.index,
    });
  }

  return resultArray;
}

export function mapAllTradersCompetitions(
  data: TradersCompetition[]
): TradersCompetition[] {
  let response: TradersCompetition[] = [
    { id: "ALL", name: "ALL", competitions: [] },
  ];
  data.forEach((trader) => {
    trader.competitions.forEach((competition) => {
      if (response[0].competitions.indexOf(competition) === -1) {
        response[0].competitions.push(competition);
      }
    });
  });
  return response;
}
