import React, { Component } from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./settleMatch.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { ArrayPLNames, settlementOptions } from "src/myEnv";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import { ValueForm } from "src/components/inputs/valueForm";
import { MySettledMatch } from "src/model/classes";
import { settleMatch } from "src/redux/actions/action-settle";
import { formatDayShort } from "src/utils/dateUtils";
import { getProfitLossGridsByMatch } from "src/redux/actions/action-plgrids";
import ErrorIcon from "@material-ui/icons/Error";
import { formatCurrency } from "src/utils/numberUtils";

type ownPropsType = {
  eventId: string;
  eventStartTime: string;
  homeCompetitorName: string;
  awayCompetitorName: string;
  closePopup: Function;
  firstOpenBetId: number;
  traderId: string;
};
type Props = ownPropsType &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  options: { [key: string]: string };
  profitLoss: number;
  showConfirmation: boolean;
  loadingHubActions: boolean;
};

const MAX_WAITING_TIME_FOR_HUB_ACTION_SCORES_SECONDS = 5;

class SettleMatch extends Component<Props, State> {
  protected loadingHubTimeout: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);
    this.state = {
      options: {},
      profitLoss: 0,
      showConfirmation: false,
      loadingHubActions: false, // old value was true // hubs connections disabled as per Lars request on 31/01/2022
    };
    this.props.getProfitLossGridsByMatch(
      this.props.eventId,
      false,
      this.props.traderId
    );
    this.loadingHubTimeout = setTimeout(() => {
      if (this.state.loadingHubActions === true) {
        this.setState({ loadingHubActions: false });
      }
    }, MAX_WAITING_TIME_FOR_HUB_ACTION_SCORES_SECONDS * 1000);
    // Subscribe to action hub, to get scores, to help fill in settle values
  }

  componentDidMount() {
    this.fillDefaultValues();
  }

  componentDidUpdate(prevProps: Props) {
  }

  componentWillUnmount() {
    // remove action hub event subscription
    // clear timeout
    clearTimeout(this.loadingHubTimeout);
  }

  hasValidScoresInEvent() {
    let validScores = false;
    try {
      validScores =
        this.props.betsOpenObject.event.ftGoalsHome !== null &&
        this.props.betsOpenObject.event.htGoalsHome !== null &&
        this.props.betsOpenObject.event.ftCornersAway !== null;
    } catch (error) {
      // do nothing
    }
    return validScores;
  }

  fillDefaultValues(): void {
    let newStateOptions = { ...this.state.options };
    let hasChangedValues: boolean = false;
    let newLoadingHubActions = this.state.loadingHubActions; // copy value
    if (this.hasValidScoresInEvent() === true) {
      //bet already settle atleast one time
      newStateOptions["ftGoalsHome"] = (this.props.betsOpenObject.event
        .ftGoalsHome as unknown) as string;
      newStateOptions["ftGoalsAway"] = (this.props.betsOpenObject.event
        .ftGoalsAway as unknown) as string;
      newStateOptions["htGoalsHome"] = (this.props.betsOpenObject.event
        .htCornersHome as unknown) as string;
      newStateOptions["htGoalsAway"] = (this.props.betsOpenObject.event
        .htGoalsAway as unknown) as string;
      newStateOptions["ftCornersHome"] = (this.props.betsOpenObject.event
        .ftCornersHome as unknown) as string;
      newStateOptions["ftCornersAway"] = (this.props.betsOpenObject.event
        .ftCornersAway as unknown) as string;
      newStateOptions["htCornersHome"] = (this.props.betsOpenObject.event
        .htCornersHome as unknown) as string;
      newStateOptions["htCornersAway"] = (this.props.betsOpenObject.event
        .htCornersAway as unknown) as string;
      newStateOptions["ftRedCardsHome"] = (this.props.betsOpenObject.event
        .ftRedCardsHome as unknown) as string;
      newStateOptions["ftRedCardsAway"] = (this.props.betsOpenObject.event
        .ftRedCardsAway as unknown) as string;
      newStateOptions["htRedCardsHome"] = (this.props.betsOpenObject.event
        .htRedCardsHome as unknown) as string;
      newStateOptions["htRedCardsAway"] = (this.props.betsOpenObject.event
        .htRedCardsAway as unknown) as string;
      hasChangedValues = true;
      newLoadingHubActions = false; // flag to stop fill values from hub actions
    } else {
      Object.keys(settlementOptions).forEach((key) => {
        if (settlementOptions[key].hasOwnProperty("defaultValue") === true) {
          newStateOptions["ft" + key + "Home"] = settlementOptions[key]
            .defaultValue as string;
          newStateOptions["ft" + key + "Away"] = settlementOptions[key]
            .defaultValue as string;
          newStateOptions["ht" + key + "Home"] = settlementOptions[key]
            .defaultValue as string;
          newStateOptions["ht" + key + "Away"] = settlementOptions[key]
            .defaultValue as string;
          hasChangedValues = true;
        }
      });
    }
    if (hasChangedValues) {
      this.setState({
        options: newStateOptions,
        loadingHubActions: newLoadingHubActions,
      });
    }
  }

  handleChangeValueFromChild = (
    newValue: string,
    optionName: string,
    key: string
  ) => {
    let newState = { ...this.state };
    if (optionName === "ht" + key + "Away") {
      if (newValue <= newState.options["ft" + key + "Away"]) {
        newState.options[optionName] = newValue;
      }
    } else if (optionName === "ht" + key + "Home") {
      if (newValue <= newState.options["ft" + key + "Home"]) {
        newState.options[optionName] = newValue;
      }
    } else if (optionName === "ft" + key + "Away") {
      if (newValue >= newState.options["ht" + key + "Away"]) {
        newState.options[optionName] = newValue;
      }
    } else if (optionName === "ft" + key + "Home") {
      if (newValue >= newState.options["ht" + key + "Home"]) {
        newState.options[optionName] = newValue;
      }
    }
    this.setState(newState);
  };

  calculateTotalProfit() {
    let profit: number = 0;
    ArrayPLNames.forEach((key) => {
      let homeScore = Number(this.state.options[key + "Home"]);
      let awayScore = Number(this.state.options[key + "Away"]);
      try {
        profit += this.props.plGridsInfo[key][homeScore][awayScore];
      } catch (error) {
        //Do Nothing
      }
    });
    return profit;
  }

  handleSettleMatch = () => {
    this.props.settleMatch(
      new MySettledMatch(
        this.props.eventId,
        Number(this.state.options.ftGoalsHome),
        Number(this.state.options.ftGoalsAway),
        Number(this.state.options.htGoalsHome),
        Number(this.state.options.htGoalsAway),
        Number(this.state.options.ftCornersHome),
        Number(this.state.options.ftCornersAway),
        Number(this.state.options.htCornersHome),
        Number(this.state.options.htCornersAway),
        Number(this.state.options.ftRedCardsHome),
        Number(this.state.options.ftRedCardsAway),
        Number(this.state.options.htRedCardsHome),
        Number(this.state.options.htRedCardsAway)
      )
    );
    this.props.closePopup();
  };

  handleSettleMatchConfirmation = (event: any) => {
    event.preventDefault();
    // show confirmation
    this.setState({ showConfirmation: true });
  };

  hideConfirmation() {
    this.setState({ showConfirmation: false });
  }

  renderOption(key: string) {
    const ft_home = "ft" + key + "Home";
    const ft_away = "ft" + key + "Away";
    const ht_home = "ht" + key + "Home";
    const ht_away = "ht" + key + "Away";
    return (
      <div className={styles.type} key={key}>
        <div className={styles.pair}>
          <ValueForm
            wrapperClassName={styles.inputContainer}
            key={ft_home}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, ft_home, key)
            }
            name=""
            step={settlementOptions[key].step as number}
            hideArrows={false}
            min={settlementOptions[key].min}
            value={
              typeof this.state.options[ft_home] === undefined
                ? "0"
                : this.state.options[ft_home]
            }
          ></ValueForm>
          <div className={styles.settleName}>
            {"Full Time ".concat(settlementOptions[key].name).toUpperCase()}
          </div>
          <ValueForm
            wrapperClassName={styles.inputContainer}
            key={ft_away}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, ft_away, key)
            }
            name=""
            step={settlementOptions[key].step as number}
            hideArrows={false}
            min={settlementOptions[key].min}
            value={
              typeof this.state.options[ft_away] === undefined
                ? "0"
                : this.state.options[ft_away]
            }
          ></ValueForm>
        </div>
        <div className={styles.pair}>
          <ValueForm
            wrapperClassName={styles.inputContainer}
            key={ht_home}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, ht_home, key)
            }
            name=""
            step={settlementOptions[key].step as number}
            hideArrows={false}
            min={settlementOptions[key].min}
            value={
              typeof this.state.options[ht_home] === undefined
                ? "0"
                : this.state.options[ht_home]
            }
          ></ValueForm>
          <div className={styles.settleName}>
            {"Half Time ".concat(settlementOptions[key].name).toUpperCase()}
          </div>
          <ValueForm
            wrapperClassName={styles.inputContainer}
            key={ht_away}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, ht_away, key)
            }
            name=""
            step={settlementOptions[key].step as number}
            hideArrows={false}
            min={settlementOptions[key].min}
            value={
              typeof this.state.options[ht_away] === undefined
                ? "0"
                : this.state.options[ht_away]
            }
          ></ValueForm>
        </div>
      </div>
    );
  }

  renderShowConfirmation() {
    const profitValue = this.calculateTotalProfit();
    return (
      <div className={styles.warningContainer}>
        <div className={styles.infoRow}>
          <ErrorIcon
            className={classnames(
              styles.icon,
              profitValue < 0 ? styles.loss : styles.profit
            )}
          />{" "}
          With above score, Total Profit/Loss for this event will be:
          <span
            className={classnames(
              styles.profitLoss,
              profitValue < 0 ? styles.loss : styles.profit
            )}
          >
            {profitValue > 0 ? "+" : ""}
            {formatCurrency(profitValue, 0)}
          </span>
          . Are you sure?
        </div>
        <div className={styles.btnsContainer}>
          <ButtonBase
            classes={{
              root: classnames(styles.saveBtn, styles.saveBtnActive),
            }}
            onClick={() => this.handleSettleMatch()}
          >
            <SaveIcon className={styles.icon} />
            Yes, Settle event
          </ButtonBase>
          <ButtonBase
            classes={{
              root: classnames(styles.btnCancel),
            }}
            onClick={() => this.hideConfirmation()}
          >
            No
          </ButtonBase>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <form
          noValidate
          autoComplete="off"
          onSubmit={this.handleSettleMatchConfirmation}
        >
          <div className={styles.formWrapper}>
            {this.state.loadingHubActions === true && (
              <div className={styles.loadingContainer}>
                <div className={"loader-circle"}></div>
                <div className={styles.loadingText}>
                  Loading scores from Hub Actions
                </div>
              </div>
            )}
            <div className={styles.title}>
              <span className={styles.main}>Settle event:</span>
              <span className={styles.subtitle}>
                {formatDayShort(new Date(this.props.eventStartTime))}{" "}
                {this.props.homeCompetitorName} {" vs "}
                {this.props.awayCompetitorName}
              </span>
            </div>
            {Object.keys(settlementOptions).map((option) => (
              <div key={option} className={styles.settleMatchContainer}>
                {this.renderOption(option)}
              </div>
            ))}
          </div>
          <div className={styles.bottomContainer}>
            {this.state.showConfirmation ? (
              this.renderShowConfirmation()
            ) : (
              <div className={styles.btnsContainer}>
                <ButtonBase
                  classes={{
                    root: classnames(styles.btnCancel),
                  }}
                  onClick={() => this.props.closePopup()}
                >
                  CANCEL
                </ButtonBase>
                <ButtonBase
                  classes={{
                    root: classnames(styles.saveBtn, styles.saveBtnActive),
                  }}
                  type="submit"
                >
                  <SaveIcon className={styles.icon} />
                  SAVE
                </ButtonBase>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (
  state: typeof INITIAL_STATE,
  ownProps: ownPropsType
) => {
  return {
    plGridsInfo: state.plGrids,
    betsInitialDay: state.bets.range.initialDay,
    betsEndDay: state.bets.range.endDay,
    betsOpenObject: state.bets.betsDatabase[ownProps.firstOpenBetId],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    settleMatch: (MySettledMatch: MySettledMatch) => {
      dispatch(settleMatch(MySettledMatch));
    },
    getProfitLossGridsByMatch: (
      eventId: string,
      isFundPage: boolean,
      traderId: string
    ) => {
      dispatch(getProfitLossGridsByMatch(eventId, isFundPage, traderId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettleMatch);
