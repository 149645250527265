import { now } from "lodash";
import moment from "moment";

/**
 * String date used on redux store, for state.events[day]
 * always in format YYYY-MM-DD
 */
export function getDayString(date: Date): string {
  const safeDate = new Date(date);
  const year = safeDate.getFullYear();
  let month = (safeDate.getMonth() + 1).toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  let day = safeDate.getDate().toString();
  if (day.length === 1) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
}

export function formatDay(date: Date): string {
  try {
    return date.toLocaleDateString();
  } catch (error) {
    if (typeof date === "string") {
      const dateObject = new Date(date);
      return dateObject.toLocaleDateString();
    } else {
      return "";
    }
  }
}

export function formatDayShort(date: Date): string {
  const userLocale = navigator.language || "en";
  const options = {
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString(userLocale, options);
}

export function formatTimeHourMinute(date: Date): string {
  const userLocale = navigator.language || "en";
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleTimeString(userLocale, options);
}
export function formatTimeHourMinuteSecond(date: Date): string {
  const userLocale = navigator.language || "en";
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleTimeString(userLocale, options);
}

export function formatDateAndTimeHourMinuteSecond(date: Date): string {
  const userLocale = navigator.language || "en";
  const options = {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleTimeString(userLocale, options);
}

export function formatPlayingTime(elapsedTimeMillis: number): string {
  let result = "";
  if (typeof elapsedTimeMillis === "number" && elapsedTimeMillis > 0) {
    result = Math.ceil(elapsedTimeMillis / 60000).toString();
  }
  return result;
}

export function fromNowOrNow(date: Date) {
  if (now() - date.getTime() < 7000) {
    // 6000 milliseconds
    return "just now";
  }
  return moment(date).fromNow(true) + " ago";
}
