import { Action, Reducer } from "redux";
import { InitialStateReports } from "./initial-state";
import { ReportsActions } from "../actions/action-reports";
import { ReportsReducer } from "src/model/myTypes";
import { formatReportsData } from "../mappers/mapper-margin";
import { UserActions } from "../actions/action-user";
import { fromArrayToObject } from "../mappers/mapper-utils";

const unloadedState: ReportsReducer = InitialStateReports;

export const reportsReducer: Reducer<ReportsReducer> = (
  state: ReportsReducer | undefined,
  incomingAction: Action
): ReportsReducer => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as ReportsActions | UserActions;
  switch (action.type) {
    case "IS_LOADING_REPORTS":
      return {
        ...state,
        isLoading: true,
      };
    case "GET_TRADERS":
      return {
        ...state,
        traders: action.payload,
      };

    case "GET_TRADERS_COMPETITION":
      const payload = fromArrayToObject(action.payload, "id");
      return {
        ...state,
        tradersWithCompetitions: { ...payload },
      };
    case "GET_REPORTS":
      let type = action.payload.type;
      if (type === 1) {
        let CalendarWithMargin = formatReportsData(action.payload.data);
        return {
          ...state,
          isLoading: false,
          calender: CalendarWithMargin,
        };
      } else {
        let betTypeWithMargin = formatReportsData(action.payload.data);
        return {
          ...state,
          isLoading: false,
          betType: betTypeWithMargin,
        };
      }
    case "CHANGE_DATE_REPORTS":
      return {
        ...state,
        range: {
          initialDay: action.payload.initialDay,
          endDay: action.payload.endDay,
        },
      };

    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
