// import { bankRollFund } from "src/model/myTypes";
import { BankRollFund } from "src/model/myTypes";
import { getDayString } from "./dateUtils";

export function getBankRollValueFromDayBefore(
  bankRollFund: BankRollFund,
  eventStartTime?: string
) {
  let dayBefore = undefined;
  let bankValueDayBefore = 0;
  if (typeof eventStartTime !== "undefined") {
    let tempDate = new Date(eventStartTime);
    tempDate.setDate(tempDate.getDate() - 1);
    dayBefore = getDayString(new Date(tempDate));
    if (
      typeof dayBefore !== "undefined" &&
      typeof bankRollFund[dayBefore] !== "undefined" &&
      typeof bankRollFund[dayBefore].value !== "undefined" &&
      bankRollFund[dayBefore].value !== null
    ) {
      bankValueDayBefore = bankRollFund[dayBefore].value;
    }
  }
  return bankValueDayBefore;
}
