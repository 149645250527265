import React, { Component } from "react";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./bets.module.css";
import { connect } from "react-redux";
import { MyBet } from "src/model/classes";
import { EventsWithBets, MyBetInterface } from "src/model/myTypes";
import { ButtonBase } from "@material-ui/core";
import { flagConverter } from "src/utils/flag-converter";
import classnames from "classnames";
import {
  formatDayShort,
  formatTimeHourMinute,
  getDayString,
} from "src/utils/dateUtils";
import { BetListStatus, BetStatus, MarketProvider } from "src/model/enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import BetInfo from "./betsInfo";
import { formatCurrency } from "src/utils/numberUtils";
import ProtectedResource from "src/components/user-permissions/protected-resource";

type ownPropsType = {
  betSelection: BetListStatus;
  isBotsPage: boolean;
  hideDeleted?: boolean;
  bookieFilter?: MarketProvider;
  traderFilter?: string;
  changeEventSettleId: Function;
  changePLGridEventId: Function;
};

type Props = ownPropsType &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = { expandedBet: number[]; betId: number };

class Bets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expandedBet: [],
      betId: 0,
    };
  }

  handleClickExpandBet(betId: number) {
    let isExpanded = this.isBetExpanded(betId);
    let newExpandedBets: number[] = [];
    if (isExpanded === true) {
      // remove id from array
      newExpandedBets = this.state.expandedBet.filter(
        (eachId: number) => eachId !== betId
      );
    } else {
      // add id in array
      newExpandedBets = [...this.state.expandedBet];
      newExpandedBets.push(betId);
    }
    // save new array in local state
    this.setState({ expandedBet: newExpandedBets });
  }

  isBetExpanded = (betId: number): boolean => {
    return this.state.expandedBet.indexOf(betId) !== -1;
  };

  handleStopRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // do not activate click bellow this click
  };

  getFirstOpenBetId = (betIds: number[]) => {
    //Finds given bet Id's from  betDatabasse
    //returns true if there is at least one with BetStatus "NoBetStatus = 0,Executable = 1,Placed = 2"
    let found: boolean = false;
    let openBetId: number = -1;
    betIds.some((id) => {
      if (this.props.betsDatabase.hasOwnProperty(id) === true) {
        const betInterfaceObject: MyBetInterface = {
          ...this.props.betsDatabase[id],
        };
        const bet: MyBet = new MyBet(betInterfaceObject);
        found = bet.isOpen();
        if (found === true) {
          // save betId
          openBetId = id;
        }
      }
      return found;
    });

    return openBetId;
  };

  renderBetInfo(arrayBets: MyBetInterface[], eventId: number) {
    if (this.state.expandedBet.indexOf(eventId) !== -1) {
      return (
        <BetInfo
          isFundPage={false}
          isBotsPage={this.props.isBotsPage}
          bets={arrayBets}
        />
      );
    } else {
      return null;
    }
  }

  renderBets = (eventWithBets: EventsWithBets) => {
    const firstBetId = eventWithBets.betIds[0]; // First BetId used to get event status and scores
    const hasBetsDatabase = this.props.betsDatabase.hasOwnProperty(firstBetId);
    if (hasBetsDatabase === false) {
      return null;
    } else {
      const isExpanded = this.isBetExpanded(eventWithBets.readId);
      const chosenBetDate = eventWithBets.startTime;
      let firstOpenBetId: number = this.getFirstOpenBetId(eventWithBets.betIds);
      const eventHasOpenBets: boolean = firstOpenBetId !== -1;
      if (eventHasOpenBets === false) {
        // set the first bet id, so that it can be re-settled an event without open bets
        firstOpenBetId = eventWithBets.betIds[0];
      }

      let arrayBetsFiltered: MyBetInterface[] = [];
      let stake = 0;
      let profit = 0;

      let betsDeleted = 0;
      let stakeDeleted = 0;

      // build arrayBetsFiltered (valid for regular "my bets page", and for "bot page")
      eventWithBets.betIds.forEach((betId) => {
        let include = true;
        // step1: check if bookie is filtered
        if (
          typeof this.props.bookieFilter !== "undefined" &&
          this.props.bookieFilter !== 0
        ) {
          include =
            this.props.betsDatabase[betId].bookie === this.props.bookieFilter;
        }
        // step2: check if trader/bot is filtered
        if (
          include === true &&
          typeof this.props.traderFilter !== "undefined" &&
          this.props.traderFilter !== ""
        ) {
          include =
            this.props.betsDatabase[betId].userId === this.props.traderFilter;
        }
        // step3: check if should hide deleted bets
        if (include === true && this.props.hideDeleted === true) {
          include = this.props.betsDatabase[betId].status !== BetStatus.Deleted;
        }
        // finally build filtered bets array
        if (include === true) {
          arrayBetsFiltered.push(this.props.betsDatabase[betId]);
          // sum totals
          stake += this.props.betsDatabase[betId].stake;
          profit +=
            typeof this.props.betsDatabase[betId].profit !== "undefined"
              ? (this.props.betsDatabase[betId].profit as number)
              : 0;

          // sum only "deleted" bets totals
          if (
            this.props.hideDeleted === false &&
            this.props.betsDatabase[betId].status === BetStatus.Deleted
          ) {
            betsDeleted += 1;
            stakeDeleted += this.props.betsDatabase[betId].stake;
          }
        }
      });

      return (
        <div
          key={eventWithBets.readId}
          className={classnames(
            styles.wrapperEvent,
            isExpanded && styles.expanded
          )}
        >
          <ButtonBase
            classes={{
              root: styles.eventLine,
            }}
            component={"div"}
            onClick={() => this.handleClickExpandBet(eventWithBets.readId)}
          >
            <div className={styles.date}>
              <span>{formatDayShort(new Date(eventWithBets.startTime))}</span>
              <span className={styles.grayText}>
                {formatTimeHourMinute(new Date(eventWithBets.startTime))}
              </span>
            </div>
            <div className={styles.region}>
              <div className={styles.flag}>
                {flagConverter(eventWithBets.regionAlpha2Code as string)}
              </div>
              <span className={styles.regionName}>
                {eventWithBets.regionName}
              </span>
            </div>
            <div className={styles.competitionName}>
              {eventWithBets.competitionName}
            </div>
            <div className={styles.eventName}>
              <span className={styles.home}>
                {eventWithBets.homeCompetitorName}
              </span>
              <span className={classnames(styles.spacer, styles.noBreak)}>
                {this.props.betsDatabase[eventWithBets.betIds[0]].event
                  .ftGoalsHome !== null
                  ? this.props.betsDatabase[eventWithBets.betIds[0]].event
                      .ftGoalsHome +
                    " - " +
                    this.props.betsDatabase[eventWithBets.betIds[0]].event
                      .ftGoalsAway
                  : "vs"}
              </span>
              <span className={styles.away}>
                {eventWithBets.awayCompetitorName}
              </span>
            </div>
            <div className={classnames(styles.betsNumber, styles.column)}>
              {arrayBetsFiltered.length - betsDeleted}
              {betsDeleted > 0 && (
                <span
                  className={classnames(styles.grayText, styles.withTitle)}
                  title={"Error bets"}
                >
                  {"(" + betsDeleted + ")"}
                </span>
              )}
            </div>
            {this.props.isBotsPage && (
              <div className={classnames(styles.stake, styles.column)}>
                <span>{formatCurrency(stake - stakeDeleted, 0)}</span>
                {betsDeleted > 0 && (
                  <span
                    className={classnames(styles.grayText, styles.withTitle)}
                    title={"Error bets"}
                  >
                    {"(" + formatCurrency(stakeDeleted, 0) + ")"}
                  </span>
                )}
              </div>
            )}
            {this.props.isBotsPage && (
              <div
                className={classnames(
                  styles.profit,
                  (profit as number) > 0 && styles.green,
                  (profit as number) < 0 && styles.red
                )}
              >
                {formatCurrency(profit)}
              </div>
            )}
            <div
              className={classnames(
                styles.wrapperButtons,
                this.props.isBotsPage && styles.isBotsPage
              )}
            >
              <ProtectedResource
                componentName={"Bets"}
                resourceKey={"settleButton"}
              >
                <div className={styles.settleBet}>
                  {!this.props.isBotsPage && (
                    <ButtonBase
                      classes={{
                        root: classnames(
                          styles.btnLink,
                          eventHasOpenBets === false && styles.secondary
                        ),
                      }}
                      onMouseDown={this.handleStopRipple}
                      onClick={(event) =>
                        this.props.changeEventSettleId(
                          eventWithBets.id,
                          chosenBetDate,
                          eventWithBets.homeCompetitorName,
                          eventWithBets.awayCompetitorName,
                          event,
                          firstOpenBetId
                        )
                      }
                    >
                      SETTLE
                    </ButtonBase>
                  )}
                </div>
              </ProtectedResource>
              <div
                className={classnames(
                  styles.plGrid,
                  this.props.isBotsPage && styles.isBotsPage
                )}
              >
                <ButtonBase
                  classes={{ root: styles.btnLink }}
                  onMouseDown={this.handleStopRipple}
                  onClick={(event) =>
                    this.props.changePLGridEventId(
                      eventWithBets.id,
                      chosenBetDate,
                      eventWithBets.homeCompetitorName,
                      eventWithBets.awayCompetitorName,
                      event,
                      this.props.betsDatabase[eventWithBets.betIds[0]]
                        .marketPeriodSelected,
                      this.props.betsDatabase[eventWithBets.betIds[0]]
                        .scoreTypeSelected,
                      this.props.betsDatabase[eventWithBets.betIds[0]].event
                    )
                  }
                >
                  PLGRID
                </ButtonBase>
              </div>
            </div>
            <div className={styles.expandIcon}>
              {isExpanded === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
          </ButtonBase>
          {this.renderBetInfo(arrayBetsFiltered, eventWithBets.readId)}
        </div>
      );
    }
  };

  sortByName(nameOne: string, nameTwo: string) {
    return nameOne.localeCompare(nameTwo);
  }
  sortById(idOne: number, idTwo: number) {
    return idOne - idTwo;
  }

  renderGroupBets() {
    let eventsWithBetsArray: EventsWithBets[] = [];
    if (this.props.betsFiltered.betIds.length > 0) {
      eventsWithBetsArray = Object.values(this.props.betsByEvent);
    } else {
      if (this.props.betSelection === BetListStatus.Open) {
        eventsWithBetsArray = Object.values(this.props.betsOpenByEvent);
      } else {
        eventsWithBetsArray = Object.values(this.props.betsByEvent);
      }
    }
    return eventsWithBetsArray
      .sort((a, b) => {
        const aDate = new Date(a.startTime);
        const bDate = new Date(b.startTime);
        const aDayString = getDayString(aDate);
        const bDayString = getDayString(bDate);
        if (aDayString === bDayString) {
          // both on the same Day
          // sort by regionName
          const sortByName: number = this.sortByName(
            a.regionName as string,
            b.regionName as string
          );
          if (sortByName === 0) {
            // sortByCompetitionID
            const sortByCompetitionReadId: number = this.sortById(
              a.competitionReadId,
              b.competitionReadId
            );
            if (sortByCompetitionReadId === 0) {
              //sort by match startTime
              return bDate.getTime() - aDate.getTime();
            } else {
              return sortByCompetitionReadId;
            }
          } else {
            return sortByName;
          }
        }
        return bDate.getTime() - aDate.getTime();
      })
      .map((eventsWithBets) => this.renderBets(eventsWithBets));
  }

  renderZeroBets() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>
          on events between {formatDayShort(new Date(this.props.initialDay))}{" "}
          and {formatDayShort(new Date(this.props.endDay))}
        </span>
      </div>
    );
  }

  renderLoadingBets() {
    return (
      <div className={styles.loadingParent}>
        <div className={styles.loadingContainer}>
          <div className={"loader-circle"}></div>
        </div>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((x) => {
          return (
            <div key={x} className={styles.betswrapper}>
              <div className={styles.wrapperEventLoading}></div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const hasBets =
      (typeof this.props.betsByEvent !== "undefined" &&
        Object.keys(this.props.betsByEvent).length > 0 &&
        typeof this.props.betsDatabase !== "undefined" &&
        Object.keys(this.props.betsDatabase).length > 0) ||
      this.props.betsFiltered.betIds.length > 0;
    return (
      <div className={styles.betswrapper}>
        <div className={styles.tableHeader}>
          <div className={styles.date}>Date</div>
          {/* <div className={styles.region}>Status</div> */}
          <div className={styles.region}>Region</div>
          <div className={styles.competitionName}>Competition</div>
          <div className={styles.eventName}>Match</div>
          <div className={styles.betsNumber}>Bets</div>
          {this.props.isBotsPage && <div className={styles.stake}>Stake</div>}
          {this.props.isBotsPage && <div className={styles.profit}>Profit</div>}
          <div
            className={classnames(
              styles.wrapperButtons,
              this.props.isBotsPage && styles.isBotsPage
            )}
          ></div>
          <div className={styles.expandIcon}></div>
        </div>
        {this.props.isLoadingBets
          ? this.renderLoadingBets()
          : hasBets
          ? this.renderGroupBets()
          : this.renderZeroBets()}
      </div>
    );
  }
}

const mapStateToProps = (
  state: typeof INITIAL_STATE,
  ownPropsType: ownPropsType
) => {
    return {
      betsByEvent: state.bets.betsByEvent,
      betsOpenByEvent: state.bets.betsOpenByEvent,
      betsDatabase: state.bets.betsDatabase,
      initialDay: state.bets.range.initialDay,
      endDay: state.bets.range.endDay,
      isLoadingBets: state.bets.isLoadingBets,
      betsFiltered: state.bets.betsFiltered,
    };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bets);
