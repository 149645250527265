import React, { Component } from "react";
import styles from "./MyDateRangePicker.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "src/css/react-datepicker-custom.css";
import { formatDay } from "src/utils/dateUtils";
import ButtonBase from "@material-ui/core/ButtonBase";
import classnames from "classnames";
import { Locale } from "date-fns";
import { getLocaleDateFnsObject } from "src/myEnv";
import Modal from "../pop-up/modal";

type Props = {
  defaultStartDate: Date;
  defaultEndDate: Date;
  changeDates: Function;
  rootClassName?: string;
};
type State = {
  startDate: Date | null;
  endDate: Date | null;
  showDatePicker: boolean;
};

export default class MyDateRangePicker extends Component<Props, State> {
  private dateFnsLocale: Locale; // used in react-datepicker property "locale"

  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: new Date(this.props.defaultStartDate),
      endDate: new Date(this.props.defaultEndDate),
      showDatePicker: false,
    };
    this.dateFnsLocale = getLocaleDateFnsObject();
  }

  handleSendDates = () => {
    if (
      (this.state.startDate !== null &&
        this.state.startDate.getTime() !==
          this.props.defaultStartDate.getTime()) ||
      (this.state.endDate !== null &&
        this.state.endDate.getTime() !== this.props.defaultEndDate.getTime())
    ) {
      this.props.changeDates(this.state.startDate, this.state.endDate);
    }
    this.setState({ showDatePicker: false });
  };

  handleCancel = () => {
    this.setState({
      startDate: new Date(this.props.defaultStartDate),
      endDate: new Date(this.props.defaultEndDate),
      showDatePicker: false,
    });
  };

  handleDateChangeStartDate = (date: any) => {
    const start = date;
    if (this.state.endDate !== null && start > this.state.endDate) {
      this.setState({ startDate: start, endDate: start });
    } else {
      this.setState({ startDate: start });
    }
  };

  handleDateChangeEndDate = (date: any) => {
    const end = date;
    if (this.state.startDate !== null && end < this.state.startDate) {
      this.setState({ startDate: end, endDate: end });
    } else {
      this.setState({ endDate: end });
    }
  };

  renderDatePickerInput = () => {
    const formatedDate: string =
      this.props.defaultStartDate === null ||
      typeof this.props.defaultStartDate === "undefined" ||
      this.props.defaultEndDate === null ||
      typeof this.props.defaultEndDate === "undefined"
        ? ""
        : formatDay(this.props.defaultStartDate as Date) +
          " - " +
          formatDay(this.props.defaultEndDate as Date);

    return (
      <ButtonBase
        classes={{
          root: classnames(styles.datePickerInput),
        }}
        onClick={() => this.setState({ showDatePicker: true })}
      >
        {formatedDate}
      </ButtonBase>
    );
  };

  render() {
    return (
      <div className={classnames(styles.wrapper, this.props.rootClassName)}>
        {this.renderDatePickerInput()}
        {this.state.showDatePicker && (
          <Modal
            handleClickOutside={this.handleSendDates}
            customStyles={styles.modalDatePickerRange}
          >
            <div className={classnames("datePicker-custom", styles.column)}>
              <div className={styles.title}>Date range selection</div>
              <div className={styles.calendarWrapper}>
                <div className={styles.calendarItem}>
                  <div className={styles.subtitle}>START DATE</div>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleDateChangeStartDate}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    locale={this.dateFnsLocale}
                    shouldCloseOnSelect={false}
                    inline
                  />
                </div>
                <div className={styles.calendarItem}>
                  <div className={styles.subtitle}>END DATE</div>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={this.handleDateChangeEndDate}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    locale={this.dateFnsLocale}
                    shouldCloseOnSelect={false}
                    inline
                  />
                </div>
              </div>
              <div className={styles.btnContainer}>
                <ButtonBase
                  classes={{
                    root: classnames(styles.btnCancel),
                  }}
                  onClick={this.handleCancel}
                >
                  CANCEL
                </ButtonBase>
                <ButtonBase
                  classes={{
                    root: classnames(styles.btn, styles.btnActive),
                  }}
                  type="submit"
                  onClick={this.handleSendDates}
                >
                  OK
                </ButtonBase>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
