import React, { Component } from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./addEventManual.module.css";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { flagConverter } from "src/utils/flag-converter";
import { CompetitionBet, Competitor } from "src/model/myTypes";
import { getInstanceBackend, getLongInstanceBackend } from "src/redux/actions/axios";
import { getDayString } from "src/utils/dateUtils";
import { EnumHelper } from "src/utils/enum-helper";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import { getEventsByDay } from "src/redux/actions/action-events";
import Modal from "src/components/pop-up/modal";
import AddCompetitorManual from "src/views/add_bets/addCompetitor/addCompetitorManual";

type OwnProps = {
  closePopup: Function;
  parentDate: Date;
};
type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type State = {
  isModified: boolean;
  competitions: CompetitionBet[];
  selectedCompetition: CompetitionBet | null;
  teams: Competitor[];
  selectedHomeTeam: Competitor | null;
  selectedAwayTeam: Competitor | null;
  selectedDateTime: Date;
  isOpenAddCompetitorPopup: boolean;
};

type NewEventManualDto = {
  startTime: Date; // "startTime": "2021-09-16T06:00:00Z",
  homeCompetitorId: string; // "homeCompetitorId": "2ed393c4-0c7a-43b3-abb9-6a60a1718759",
  awayCompetitorId: string; // "awayCompetitorId": "f7bf00b3-1929-43bf-92dd-f6184d86dbc2",
  competitionId: string; // "competitionId": "d1b5d225-0bf6-4298-8201-59ef2f3ebb42",
};

enum CompetitorCondition {
  Home = 1,
  Away = 2,
}

class AddEventManual extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModified: false,
      competitions: [],
      selectedCompetition: null,
      teams: [],
      selectedHomeTeam: null,
      selectedAwayTeam: null,
      selectedDateTime: new Date(this.getDefaultEventDateTime()),
      isOpenAddCompetitorPopup: false,
    };
    this.getAllCompetitions();
  }

  async getAllCompetitions() {
    const response = await getLongInstanceBackend()({
      method: "get",
      url: "/Competitions",
      params: { fetchedCount: 0, pagesize: 10000 },
    });
    const allCompetitions: CompetitionBet[] = response.data.data.sort((a: CompetitionBet, b: CompetitionBet) =>
      (a.region.name as string).localeCompare(b.region.name as string)
    );
    this.setState({ competitions: allCompetitions });
  }

  async getAllTeamsFromCompetition(competitionId: string) {
    const response = await getLongInstanceBackend()({
      method: "get",
      url: "/Competitors",
      params: {
        fetchedCount: 0,
        pagesize: 10000,
        competitionId: competitionId,
      },
    });
    const teamsFromCompetition: Competitor[] = response.data.data.sort((a: Competitor, b: Competitor) =>
      (a.name as string).localeCompare(b.name as string)
    );
    this.setState({ teams: teamsFromCompetition });
  }

  async getAllTeamsByName(name: string) {
    const response = await getLongInstanceBackend()({
      method: "get",
      url: "/Competitors",
      params: {
        fetchedCount: 0,
        pagesize: 50, // max 50 results (user will have to submit more complete name if needed)
        name: name,
      },
    });
    const teamsByName: Competitor[] = response.data.data.sort((a: Competitor, b: Competitor) =>
      (a.name as string).localeCompare(b.name as string)
    );
    this.setState({ teams: teamsByName });
  }

  handleSelectCompetition = (newCompetition: CompetitionBet | null) => {
    // may be null, if user clear the input
    if (newCompetition !== null && newCompetition.hasOwnProperty("id")) {
      this.getAllTeamsFromCompetition(newCompetition.id);
    }
    this.setState({ selectedCompetition: newCompetition });
  };

  handleSelectTeam = (newTeam: Competitor, condition: CompetitorCondition) => {
    switch (condition) {
      case CompetitorCondition.Home:
        this.setState({ selectedHomeTeam: newTeam });
        break;
      case CompetitorCondition.Away:
        this.setState({ selectedAwayTeam: newTeam });
        break;
      default:
        break;
    }
  };

  handleSearchTeamByNameDebounced = debounce((name: string, condition: CompetitorCondition) => {
    this.handleSearchTeamByName(name, condition);
  }, 250);

  handleSearchTeamByName = (name: string, condition: CompetitorCondition) => {
    let currentSelectedTeamName = "";
    switch (condition) {
      case CompetitorCondition.Home:
        if (this.state.selectedHomeTeam !== null && this.state.selectedHomeTeam.hasOwnProperty("name")) {
          currentSelectedTeamName = (this.state.selectedHomeTeam as Competitor).name as string;
        }
        break;
      case CompetitorCondition.Away:
        if (this.state.selectedAwayTeam !== null && this.state.selectedAwayTeam.hasOwnProperty("name")) {
          currentSelectedTeamName = (this.state.selectedAwayTeam as Competitor).name as string;
        }
        break;
      default:
        break;
    }
    if (name !== currentSelectedTeamName) {
      this.getAllTeamsByName(name);
    }
  };

  handleSelectDateTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDateTimeString = event.target.value;
    const newDateTime = new Date(newDateTimeString);
    this.setState({ selectedDateTime: newDateTime });
  };

  getDefaultEventDateTime(): string {
    return getDayString(this.props.parentDate) + "T19:00";
  }

  isValidForm(): boolean {
    return (
      this.state.selectedCompetition !== null &&
      this.state.selectedCompetition.id.length > 0 &&
      this.state.selectedHomeTeam !== null &&
      this.state.selectedHomeTeam.id.length > 0 &&
      this.state.selectedAwayTeam !== null &&
      this.state.selectedAwayTeam.id.length > 0 &&
      this.state.selectedHomeTeam.id !== this.state.selectedAwayTeam.id
    );
  }

  handleSave = () => {
    const newEventDto: NewEventManualDto = {
      startTime: this.state.selectedDateTime,
      homeCompetitorId: (this.state.selectedHomeTeam as Competitor).id,
      awayCompetitorId: (this.state.selectedAwayTeam as Competitor).id,
      competitionId: (this.state.selectedCompetition as CompetitionBet).id,
    };
    const request = getInstanceBackend()({
      method: "post",
      url: "/EventsManual",
      data: newEventDto,
    });
    request
      .then((response) => {
        toast.success("Event added successfully. Please find the new event on the list to add bets on it.");
        this.props.getEventsByDay(this.props.parentDate); // refresh the list after closing popup
        this.props.closePopup();
      })
      .catch((error) => {
        // do nothing
        console.log("error in add EventsManual", error);
        toast.error("Something went wrong when trying to add the new Event. Please try again, or contact us.");
      });
  };

  handleToggleAddCompetitorPopup = () => {
    this.setState({ isOpenAddCompetitorPopup: !this.state.isOpenAddCompetitorPopup });
  };

  renderAddCompetitorModal() {
    return (
      this.state.isOpenAddCompetitorPopup && (
        <Modal handleClickOutside={() => null}>
          <AddCompetitorManual closePopup={this.handleToggleAddCompetitorPopup} />
        </Modal>
      )
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span className={styles.main}>Add new event</span>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.addCompetition}>
            <p className={styles.label}>Competition</p>
            <div className={styles.inputAutoCompleteContainer}>
              <Autocomplete
                classes={{
                  root: styles.inputText,
                  listbox: styles.listbox,
                  groupLabel: styles.groupTitle,
                }}
                options={this.state.competitions}
                getOptionLabel={(option) => option.name as string}
                onChange={(event, newValue) => {
                  this.handleSelectCompetition(newValue as CompetitionBet);
                }}
                groupBy={(option) => option.region.name as string}
                renderOption={(option) => (
                  <React.Fragment>
                    <span className={styles.flag}>{flagConverter(option.region.alphaCode as string)}</span>
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: { underline: styles.inputProps },
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.row}>
            {EnumHelper.getNamesAndIds(CompetitorCondition).map((condition) => (
              <div key={condition.id} className={classnames(styles.width50, styles.addTeam)}>
                <p className={styles.label}>{condition.name} team</p>
                <div className={styles.inputAutoCompleteContainer}>
                  <Autocomplete
                    classes={{
                      root: styles.inputText,
                      listbox: styles.listbox,
                    }}
                    options={this.state.teams}
                    getOptionLabel={(option) => option.name as string}
                    onChange={(event, newValue) => {
                      this.handleSelectTeam(newValue as CompetitionBet, condition.id);
                    }}
                    onInputChange={(event, newInputValue) => {
                      this.handleSearchTeamByNameDebounced(newInputValue, condition.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          classes: { underline: styles.inputProps },
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className={classnames(styles.row, styles.alignRight)}>
            <p className={styles.textNote}>If you can't find the Team you're looking for:</p>
            <ButtonBase
              classes={{
                root: styles.btnAddCompetitor,
              }}
              onClick={this.handleToggleAddCompetitorPopup}
            >
              ADD NEW COMPETITOR
            </ButtonBase>
            {this.renderAddCompetitorModal()}
          </div>
          <div className={styles.eventDate}>
            <TextField
              label="Event start time"
              type="datetime-local"
              defaultValue={this.getDefaultEventDateTime()}
              onChange={this.handleSelectDateTime}
              InputLabelProps={{
                shrink: true,
                className: styles.label,
              }}
              InputProps={{
                classes: {
                  root: styles.datetimeInput,
                  underline: styles.underline,
                },
              }}
              fullWidth={true}
            />
          </div>
        </div>
        <div className={styles.btnCloseContainer}>
          <ButtonBase
            classes={{ root: classnames(styles.btn, styles.btnCancel) }}
            onClick={() => this.props.closePopup()}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            classes={{ root: classnames(styles.btn, styles.saveBtn), disabled: styles.isDisabled }}
            onClick={this.handleSave}
            disabled={this.isValidForm() === false}
          >
            <SaveIcon /> Save
          </ButtonBase>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE, ownProps: OwnProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventsByDay: (day: Date) => {
      dispatch(getEventsByDay(day));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEventManual);
