import {
  MarketPeriod,
  MarketProvider,
  MarketSelectionType,
  MarketType,
  ScoreType,
  TimeScanningOdds,
  TimeToWaitBookieOdds,
  TimeToWaitMatchBet,
} from "./model/enums";
import { ReportViewType } from "./model/enums";
import { RangeDates } from "./model/myTypes";
import * as locales from "date-fns/locale";

/**
 * CONSTANT options for the application
 *  import { options } from 'src/myEnv';
 */
export const options: { [key: string]: { name: string; id: number }[] } = {
  marketPeroid: [
    { name: "Full Time", id: 1 },
    { name: "Half Time", id: 2 },
  ],
  scoreType: [
    { name: "Goals", id: 11 },
    { name: "Corners", id: 12 },
    { name: "Bookings", id: 91 },
  ],
  Bookie: [
    { name: "KMail88", id: 51 },
    { name: "Pinnacle", id: 2 },
    { name: "BetISN", id: 3 },
    { name: "SingBet", id: 5 },
    { name: "Digeelva", id: 62 },
    { name: "Molly", id: 52 },
  ],
};

export const betslipOptions: {
  [key: string]: {
    name: string;
    type: string;
    defaultValue: string;
    step?: number;
    hideArrows: boolean;
    min?: string;
    max?: string;
    adornment?: boolean;
  };
} = {
  Bookie: {
    name: "Bookie",
    type: "SELECT",
    defaultValue: "51", // KMail88
    hideArrows: true,
  },
  Line: {
    name: "Line",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 0.25,
    hideArrows: false,
  },
  Price: {
    name: "Price",
    type: "INPUT-NUMBER",
    defaultValue: "",
    step: 0.001,
    hideArrows: true,
    min: "0",
  },
  Stake: {
    name: "Stake",
    type: "INPUT-NUMBER",
    defaultValue: "",
    step: 1,
    hideArrows: true,
    min: "0",
  },
  Value: {
    name: "Value",
    type: "INPUT-NUMBER",
    defaultValue: "",
    step: 1,
    hideArrows: true,
    min: "0",
    max: "100",
    adornment: true,
  },
};

export const marketBetslipOptions: {
  [key: string]: {
    name: string;
    type: string;
    defaultValue: string;
    step?: number;
    hideArrows: boolean;
    min?: string;
    max?: string;
    adornment?: boolean;
  };
} = {
  Bookie: {
    name: "Bookie",
    type: "SELECT",
    defaultValue: "51", // KMail88
    hideArrows: true,
  },
  Line: {
    name: "Line",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 0.25,
    hideArrows: false,
  },
  Price: {
    name: "Price",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 0.001,
    hideArrows: true,
    min: "1",
  },
  Value: {
    name: "Value",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 0.1,
    hideArrows: true,
    min: "-10000000",
    max: "100",
    adornment: true,
  },
  Stake: {
    name: "Stake",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 1,
    hideArrows: true,
    min: "0",
  },
};

export const settlementOptions: {
  [key: string]: {
    name: string;
    type: string;
    defaultValue: string;
    step?: number;
    hideArrows: boolean;
    min?: string;
    max?: string;
  };
} = {
  Goals: {
    name: "Goals",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 1,
    hideArrows: true,
    min: "0",
  },

  Corners: {
    name: "Corners",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 1,
    hideArrows: true,
    min: "0",
  },

  RedCards: {
    name: "Cards",
    type: "INPUT-NUMBER",
    defaultValue: "0",
    step: 1,
    hideArrows: true,
    min: "0",
  },
};

export const fundIndexOptions: {
  [key: string]: {
    name: string;
    type: string;
    defaultValue?: string;
    step?: number;
    hideArrows: boolean;
    min?: string;
    max?: string;
  };
} = {
  Bankroll: {
    name: "startBankroll",
    type: "INPUT-NUMBER",
    step: 1,
    hideArrows: true,
    min: "0",
  },

  Index: {
    name: "startIndex",
    type: "INPUT-NUMBER",
    step: 1,
    hideArrows: true,
    min: "0",
  },
};

export const optionsReports: {
  [key: string]: { name: string; id: number }[];
} = {
  marketPeroidReports: [
    { name: "All", id: 0 },
    { name: "Full Time", id: 1 },
    { name: "Half Time", id: 2 },
  ],
  reportViewType: [
    { name: "Calendar", id: ReportViewType.Calendar },
    { name: "Bet Type", id: ReportViewType.BetType },
  ],
  scoreTypeReports: [
    { name: "All", id: 0 },
    { name: "Goals", id: 11 },
    { name: "Corners", id: 12 },
    { name: "Bookings", id: 91 },
  ],
};

export const optionsEditBot: {
  [key: string]: { name: string; id: number }[];
} = {
  timeScanningOddsStart: [
    { name: "", id: TimeScanningOdds.WhenEventEnd },
    // { name: "48h before event start", id: TimeScanningOdds.BeforeEventStart48 }, // removed in 2021/02/16 because of the way odds are fetched in bookies (we fetch all odds from markets in the "today" - case ISN)
    { name: "24h before event start", id: TimeScanningOdds.BeforeEventStart24 },
    { name: "12h before event start", id: TimeScanningOdds.BeforeEventStart12 },
    { name: "6h before event start", id: TimeScanningOdds.BeforeEventStart6 },
    { name: "1h before event start", id: TimeScanningOdds.BeforeEventStart1 },
    { name: "On Event Start", id: TimeScanningOdds.OnEventStart },
  ],
  timeToWaitOtherBookiesOdds: [
    { name: "0 sec", id: TimeToWaitBookieOdds.Sec0 },
    { name: "5 sec", id: TimeToWaitBookieOdds.Sec5 },
    { name: "30 sec", id: TimeToWaitBookieOdds.Sec30 },
    { name: "1 min", id: TimeToWaitBookieOdds.Min1 },
  ],
  timeToWaitFillOrKill: [
    { name: "0 sec", id: TimeToWaitMatchBet.Sec0 },
    { name: "5 sec", id: TimeToWaitMatchBet.Sec20 },
    { name: "1 min", id: TimeToWaitMatchBet.Min1 },
    { name: "5 min", id: TimeToWaitMatchBet.Min5 },
    { name: "1 hour", id: TimeToWaitMatchBet.Hour1 },
    { name: "6 hours", id: TimeToWaitMatchBet.Hour6 },
    { name: "24 hours", id: TimeToWaitMatchBet.Hour24 },
  ],
};

export const optionsBookieMarkets: {
  [key: string]: { name: string; id: number }[];
} = {
  marketPeriod: [
    { name: "", id: MarketPeriod.None },
    { name: "Full Time", id: MarketPeriod.FullTime },
    { name: "First Half", id: MarketPeriod.FirstHalf },
  ],
  scoreType: [
    { name: "", id: ScoreType.NoScoreType },
    { name: "Goals", id: ScoreType.Goals },
    { name: "Corners", id: ScoreType.Corners },
    { name: "Bookings", id: ScoreType.Bookings },
  ],
  marketType: [
    { name: "", id: MarketType.NoMarketType },
    { name: "Asian Handicap", id: MarketType.AsianHandicap },
    { name: "Asian Over Under", id: MarketType.AsianOverUnder },
  ],
};

export const marketProvider: { name: string; id: number; uri: string }[] = [
  {
    name: "Pinnacle",
    id: MarketProvider.Pinnacle,
    uri: "https://api.ps3838.com/",
  },
  {
    name: "BetISN",
    id: MarketProvider.BetISN,
    uri: "http://www.isn55.com/membersite-api/api/",
  },
  {
    name: "Betfair",
    id: MarketProvider.Betfair,
    uri: "https://api.betfair.com/exchange/betting/rest/v1.0/",
  },
  { name: "KMail88", id: MarketProvider.KMail88, uri: "http://rfs.codeum.com" },
];

/** Push Apis */
export const PUSH_API_MAX_RECONNECTION_ATTEMPTS = 5;
export const PUSH_API_TIMEOUT_INTERVAL_BETWEEN_ATTEMPTS = 1500;

/** Default range dates */
export const getDefaultDatesPageMyBets = (): RangeDates => {
  // from -7 days ago until +7 days in the future
  const now = new Date();
  const range = {
    initialDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7),
  };
  return range;
};
export const getDefaultDatesPageFund = (): RangeDates => {
  // requested on slack, by Christopher, to be the previous 14th August
  const now = new Date();
  const currentYear14August = new Date(now.getFullYear(), 7, 14);
  let startDate = now;
  if (now.getTime() < currentYear14August.getTime()) {
    // set previous year 14th August
    startDate = new Date(now.getFullYear() - 1, 7, 14);
  } else {
    // set current year 14th August
    startDate = currentYear14August;
  }
  const range = {
    initialDate: startDate,
    endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
  };
  return range;
};
export const getDefaultDatesPageReports = (): RangeDates => {
  // requested on slack, by Mads, to be last week (monday-sunday), this is the last sunday minus 6 days
  const now = new Date();
  const lastSunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
  const range = {
    initialDate: new Date(lastSunday.getFullYear(), lastSunday.getMonth(), lastSunday.getDate() - 6),
    endDate: lastSunday,
  };
  return range;
};

/**
 * Selections by MarketGroup, i.e. MarketSelectionType for each MarketType
 *  import { getMarketSelectionTypeOfMarketType } from 'src/myEnv';
 */
const MARKET_SELECTIONS: { [key: number]: MarketSelectionType[] } = {
  [MarketType.OneXTwo as number]: [
    MarketSelectionType.Home,
    MarketSelectionType.Draw,
    MarketSelectionType.Away,
  ],
  [MarketType.AsianHandicap as number]: [
    MarketSelectionType.Home,
    MarketSelectionType.Away,
  ],
  [MarketType.AsianOverUnder as number]: [
    MarketSelectionType.Over,
    MarketSelectionType.Under,
  ],
};
export const getMarketSelectionTypeOfMarketType = (
  marketTypeId: number
): MarketSelectionType[] => {
  return MARKET_SELECTIONS[marketTypeId] || [];
};

/**
 * On 2-selection markets will return the "other" selection type id.
 * Will return 0 if not found.
 */
export const getOtherMarketSelectionType = (
  marketSelectionType: MarketSelectionType
): MarketSelectionType => {
  let otherMarketSelectionType = MarketSelectionType.NoMarketSelection;
  switch (marketSelectionType) {
    case MarketSelectionType.Home:
      otherMarketSelectionType = MarketSelectionType.Away;
      break;
    case MarketSelectionType.Away:
      otherMarketSelectionType = MarketSelectionType.Home;
      break;
    case MarketSelectionType.Over:
      otherMarketSelectionType = MarketSelectionType.Under;
      break;
    case MarketSelectionType.Under:
      otherMarketSelectionType = MarketSelectionType.Over;
      break;
    default:
      break;
  }
  return otherMarketSelectionType;
};

/**
 * CONSTANT options for the application
 *  import { homepageUri } from 'src/myEnv';
 */
export const homepageUri: string = `${window.location.protocol}//${window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/`;

export const getMarketGroupsIdsArray = (scoreTypeId: number): string[] => {
  return [
    MarketPeriod.FullTime +
    "|" +
    scoreTypeId +
    "|" +
    MarketType.AsianHandicap +
    "|0",
    MarketPeriod.FullTime +
    "|" +
    scoreTypeId +
    "|" +
    MarketType.AsianOverUnder +
    "|0",
    MarketPeriod.FirstHalf +
    "|" +
    scoreTypeId +
    "|" +
    MarketType.AsianHandicap +
    "|0",
    MarketPeriod.FirstHalf +
    "|" +
    scoreTypeId +
    "|" +
    MarketType.AsianOverUnder +
    "|0",
  ];
};

/**
 * Used in value viewer, to style the text color according to business rules:
 *  myValue < 8% => "whiteText"
 *  8% < myValue < 15% => "yellowText"
 *  15% < myValue => "greenText"
 * @param myValue
 * @returns string for css class
 */
export const getStyleColorMyValue = (myValue: number): string => {
  let styleColor = "whiteText";
  if (isNaN(Number(myValue)) === false) {
    if (myValue > 0.15) {
      styleColor = "greenText";
    } else if (myValue > 0.08) {
      styleColor = "yellowText";
    }
  }
  return styleColor;
};

/**
 * Method loop will run on refresh page, only once.
 * It will be ran because this file is imported already.
 *
 * Method "getLocaleDateFnsObject" for getting the locale object in "date-fns" module, according to the navigator.language code.
 *
 */
let localeObjectDateFns = locales.enGB; // default
try {
  const navigatorLanguageCode = window.navigator.language;
  let found = false;
  Object.values(locales).some((localeDateFns) => {
    if (localeDateFns.code === navigatorLanguageCode) {
      localeObjectDateFns = localeDateFns as Locale; // set user locale
      found = true;
    }
    return found;
  });
  if (found === false) {
    // try to find first part of navigator language code (split by '-')
    const shortNavigatorLanguageCode = navigatorLanguageCode.split("-")[0];
    Object.values(locales).some((localeDateFns) => {
      if (localeDateFns.code === shortNavigatorLanguageCode) {
        localeObjectDateFns = localeDateFns as Locale; // set user locale
        found = true;
      }
      return found;
    });
  }
} catch (error) {
  // do nothing
}
export function getLocaleDateFnsObject() {
  return localeObjectDateFns;
}

/** BotLogger API constants
 */

export const BOT_LOGS_INTERVAL_SECONDS_BETWEEN_ATTEMPTS = 5;
export const BOT_PLACEMENT_LOGS_INTERVAL_SECONDS_BETWEEN_ATTEMPTS = 4;
export const BOT_LOGS_FROM_LAST_MINUTES = 5;
export const BOT_PLACEMENT_LOGS_FROM_LAST_HOURS = 2;
export const BOT_LOGS_PAGE_SIZE = 500;

export const BOT_REBOOT_AWAIT_STOPPED_LOGS = 2;

export const ArrayPLNames = [
  "ftCorners",
  "ftGoals",
  "ftRedCards",
  "htCorners",
  "htGoals",
  "htRedCards",
];
