import React, { Component } from "react";
import styles from "./page404.module.css";

export default class page404 extends Component {
  render() {
    return (
      <div className={styles.paddingLeft}>
        <div className={styles.body}>
          <p>page404</p>
        </div>
      </div>
    );
  }
}
