import React, { Component } from "react";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./betHistory.module.css";
import { connect } from "react-redux";
import classnames from "classnames";
import { MarketPeriod, MarketProvider, MarketSelectionType, MarketType, ScoreType } from "src/model/enums";
import { flagConverter } from "src/utils/flag-converter";
import { formatDay, formatDayShort } from "src/utils/dateUtils";
import { MyBetInterface } from "src/model/myTypes";
import { getBetsDayHistory, removeBet } from "src/redux/actions/action-bets";
import { formatCurrency, formatNumber, formatPercentage } from "src/utils/numberUtils";
import { ButtonBase } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Modal from "src/components/pop-up/modal";
import DeleteConfirmation from "./deleteConfirmation";

type ownPropsType = {
  targetDate: Date;
};

type Props = ownPropsType & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type State = {
  eventInfoShowList: number[];
  betToDelete: number;
};

class BetHistory extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      eventInfoShowList: [],
      betToDelete: 0,
    };
    let targetDate = new Date(this.props.targetDate);
    this.props.getBetsDayHistory(targetDate, targetDate, this.props.traderSelected);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.targetDate !== this.props.targetDate || this.props.betsShouldUpdate === true) {
      let targetDate = new Date(this.props.targetDate);
      this.props.getBetsDayHistory(targetDate, targetDate, this.props.traderSelected);
    }
  }

  isFavorite = (competitionReadId: number) => {
    return Object.keys(this.props.favoritesCompetitions).indexOf(competitionReadId.toString()) !== -1;
  };

  sortByName(nameOne: string, nameTwo: string) {
    return nameOne.localeCompare(nameTwo);
  }
  sortById(idOne: number, idTwo: number) {
    return idOne - idTwo;
  }

  sortBetsByCompetition(bets: MyBetInterface[]) {
    let resultBets: MyBetInterface[] = [];
    resultBets = bets.sort((a, b) => {
      const aCountry = a.event.competition.region.name as string;
      const bCountry = b.event.competition.region.name as string;

      if (this.isFavorite(a.event.competition.readId) && this.isFavorite(b.event.competition.readId)) {
        // both competitions are favorite
        // sort by regionName
        const sortByName: number = this.sortByName(aCountry, bCountry);
        if (sortByName === 0) {
          // sortByID
          return this.sortById(a.event.competition.readId, b.event.competition.readId);
        } else {
          return sortByName;
        }
      } else if (this.isFavorite(a.event.competition.readId)) {
        //only competition A is favorite
        return -1;
      } else if (this.isFavorite(b.event.competition.readId)) {
        //only competition B is favorite
        return 1;
      } else {
        // neither is favorite

        // sort by regionName
        const sortByName: number = this.sortByName(aCountry, bCountry);
        if (sortByName === 0) {
          // sortByID
          return this.sortById(a.event.competition.readId, b.event.competition.readId);
        } else {
          return sortByName;
        }
      }
    });
    return resultBets;
  }

  handleChangeShownList = (eventId: number) => {
    let list = this.state.eventInfoShowList;
    if (list.includes(eventId)) {
      list = list.filter((id) => {
        return id !== eventId;
      });
    } else {
      list.push(eventId);
    }
    this.setState({
      eventInfoShowList: list,
    });
  };

  handleConfirmDeleteBet = (betId: number) => {
    this.setState({
      betToDelete: betId,
    });
  };

  handleDeleteBet = () => {
    let myBet = { ...this.props.betsDatabase[this.state.betToDelete] };
    this.props.removeBet(myBet.id as number);
    this.handleCancelDeleteBet();
  };

  handleCancelDeleteBet = () => {
    this.setState({
      betToDelete: 0,
    });
  };

  getBetsByDate() {
    let myBets: MyBetInterface[] = [];
    Object.values(this.props.betsByEvent).forEach((event) => {
      if (formatDay(new Date(event.startTime)) === formatDay(this.props.targetDate)) {
        event.betIds.forEach((betId) => {
          myBets.push(this.props.betsDatabase[betId]);
        });
      }
    });
    return this.sortBetsByCompetition(myBets);
  }

  getNumberOfBets(eventId: number) {
    let betsNumber: number = 0;
    Object.values(this.props.betsByEvent).forEach((event) => {
      if (formatDay(new Date(event.startTime)) === formatDay(this.props.targetDate) && event.readId === eventId) {
        betsNumber = betsNumber + event.betIds.length;
      }
    });
    return betsNumber;
  }

  renderBetsByEvent = (fullBetList: MyBetInterface[]) => {
    let currentEventId = -1;
    let showEventHeader = true;
    return fullBetList.map((bet) => {
      if (currentEventId === bet.event.readId) {
        showEventHeader = false;
      } else {
        showEventHeader = true;
        currentEventId = bet.event.readId;
      }

      const isOpenEventHeader = this.state.eventInfoShowList.includes(bet.event.readId as number);
      if (bet.status === 9) {
        return null;
      } else {
        return (
          <div key={bet.id} className={styles.eventLine}>
            {showEventHeader && (
              <ButtonBase
                onClick={() => this.handleChangeShownList(bet.event.readId)}
                classes={{
                  root: classnames(styles.eventHeader, isOpenEventHeader && styles.isOpen),
                }}
              >
                <div className={styles.competitionName}>
                  <span className={styles.spaceRight}>
                    {flagConverter(bet.event.competition.region.alphaCode as string)}
                  </span>
                  {bet.event.competition.name as string}
                </div>
                <div className={styles.competitors}>
                  {bet.event.homeCompetitor.name}{" "}
                  {this.props.betsDatabase[bet.id as number].event.ftGoalsHome !== null
                    ? this.props.betsDatabase[bet.id as number].event.ftGoalsHome +
                      " - " +
                      this.props.betsDatabase[bet.id as number].event.ftGoalsAway
                    : "vs"}{" "}
                  {bet.event.awayCompetitor.name}
                </div>
                <div className={styles.nBets}>{this.getNumberOfBets(bet.event.readId as number)}</div>
                <div className={styles.expandIcon}>{isOpenEventHeader ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
              </ButtonBase>
            )}
            {this.state.eventInfoShowList.indexOf(currentEventId) !== -1 && this.renderEventsInfo(bet)}
          </div>
        );
      }
    });
  };

  /**
   * Renders supplied bet details
   * @param bet
   */
  renderEventsInfo = (bet: MyBetInterface) => {
    return (
      <div className={styles.betLine}>
        <div className={styles.betLineRow}>
          <div>
            <span className={styles.marketPeriod}>
              {MarketPeriod[this.props.betsDatabase[bet.id as number].marketPeriodSelected]}
            </span>
            <span className={styles.betType}>
              {ScoreType[this.props.betsDatabase[bet.id as number].scoreTypeSelected]}
            </span>
          </div>
          <span className={styles.betHandicap}>
            {MarketType[this.props.betsDatabase[bet.id as number].marketTypeSelected] === "AsianHandicap" ? (
              <span>
                <span className={styles.betHandicapType}>Handicap</span>{" "}
                {MarketSelectionType[this.props.betsDatabase[bet.id as number].marketSelectionTypeSelected]}
              </span>
            ) : (
              <span>
                <span className={styles.betHandicapType}>Total</span>{" "}
                {MarketSelectionType[this.props.betsDatabase[bet.id as number].marketSelectionTypeSelected]}
              </span>
            )}
          </span>
          <span className={styles.marketProvider}>
            {MarketProvider[this.props.betsDatabase[bet.id as number].bookie]}
          </span>
          <ButtonBase
            onClick={() => this.handleConfirmDeleteBet(bet.id as number)}
            classes={{
              root: styles.deleteBtn,
            }}
          >
            delete
          </ButtonBase>
        </div>
        <div className={styles.betLineRow}>
          <div className={styles.betLineCell}>
            <span className={styles.line}>Line</span>
            <span className={styles.lineNumber}>{formatNumber(this.props.betsDatabase[bet.id as number].line, 2)}</span>
          </div>
          <div className={styles.betLineCell}>
            <span className={styles.price}>Price </span>
            <span className={styles.priceNumber}>
              {formatNumber(this.props.betsDatabase[bet.id as number].price, 3)}
            </span>
          </div>
          <div className={styles.betLineCell}>
            <span className={styles.stake}>Stake </span>
            <span className={styles.stakeNumber}>
              {formatCurrency(this.props.betsDatabase[bet.id as number].stake)}
            </span>
          </div>
          <div className={styles.betLineCell}>
            <span className={styles.value}>Value </span>
            <span className={styles.valueNumber}>
              {formatPercentage(this.props.betsDatabase[bet.id as number].value)}
            </span>
          </div>
          <div className={styles.betLineCell}>
            <span className={styles.value}>P/L </span>
            <span
              className={classnames(
                styles.valueNumber,
                (this.props.betsDatabase[bet.id as number].profit as number) > 0 && styles.green,
                (this.props.betsDatabase[bet.id as number].profit as number) < 0 && styles.red
              )}
            >
              {this.props.betsDatabase[bet.id as number].profit === null
                ? "-"
                : formatCurrency(this.props.betsDatabase[bet.id as number].profit as number)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  renderZeroBets() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>on events from {formatDayShort(this.props.targetDate)}</span>
      </div>
    );
  }

  render() {
    let traderName = "";
    this.props.traders.forEach((trader) => {
      if (trader.id === this.props.traderSelected) {
        traderName = trader.name;
      }
    });
    if (traderName === "ALL" || traderName === "") {
      traderName = "All";
    }
    const fullBetList: MyBetInterface[] = this.getBetsByDate();
    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <span className={styles.main}>Bet history</span>
          <span className={styles.subtitle}>{formatDay(this.props.targetDate)}</span>
          <span className={styles.subtitle}>Trader: {traderName}</span>
        </div>
        <div className={styles.eventsWrapper}>
          {fullBetList.length > 0 ? this.renderBetsByEvent(fullBetList) : this.renderZeroBets()}
        </div>
        {this.state.betToDelete !== 0 && (
          <Modal handleClickOutside={this.handleCancelDeleteBet}>
            <DeleteConfirmation removeBet={this.handleDeleteBet} closePopup={this.handleCancelDeleteBet} />
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsByEvent: state.bets.betsDayHistory.betsByEvent,
    betsDatabase: state.bets.betsDayHistory.betsDatabase,
    favoritesCompetitions: state.favoriteCompetitions,
    betsShouldUpdate: state.bets.betsShouldUpdate,
    traderSelected: state.settings.traderId,
    traders: state.reports.traders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBetsDayHistory: (initialDay: Date, endDay: Date, traderId: string) => {
      dispatch(getBetsDayHistory(initialDay, endDay, traderId));
    },
    removeBet: (id: number) => {
      dispatch(removeBet(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetHistory);
