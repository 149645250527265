import React, { Component } from "react";
import styles from "src/views/my_bets/bets.module.css";
import { connect } from "react-redux";
// import {  GetReports} from "src/redux/actions/action-reports";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { formatDayShort } from "src/utils/dateUtils";
// import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import { MyBet } from "src/model/classes";
import { ReportMarketPeriod, ReportScoreType } from "src/model/enums";
import { MyBetInterface } from "src/model/myTypes";
import { ButtonBase } from "@material-ui/core";
import { myBetsArrayToBetsByEvent } from "src/redux/mappers/mapper-bets";
import { flagConverter } from "src/utils/flag-converter";
import BetInfo from "src/views/my_bets/betsInfo";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Tooltip from "@material-ui/core/Tooltip";
// import FundIndexChart from "./fundIndexChart";

type OwnProps = {
  MarketPeriodSelected: ReportMarketPeriod;
  ScoreTypeSelected: ReportScoreType;
  traderId: string;
  regionReadId: number;
  competitionId: string;
  fundEndDate: Date;
};

type Props = OwnProps & {
  changePLGridEventId: Function;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  expandedBet: number[];
};

const initialLocalState: State = {
  expandedBet: [],
};

class BetsFund extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialLocalState };
  }

  isBetExpanded = (betId: number): boolean => {
    return this.state.expandedBet.indexOf(betId) !== -1;
  };

  handleStopRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // do not activate click bellow this click
  };

  handleClickExpandBet(betId: number) {
    let isExpanded = this.isBetExpanded(betId);
    let newExpandedBets: number[] = [];
    if (isExpanded === true) {
      // remove id from array
      newExpandedBets = this.state.expandedBet.filter(
        (eachId: number) => eachId !== betId
      );
    } else {
      // add id in array
      newExpandedBets = [...this.state.expandedBet];
      newExpandedBets.push(betId);
    }
    // save new array in local state
    this.setState({ expandedBet: newExpandedBets });
  }

  renderBetInfo(betIds: number[], eventId: number, startTime: string) {
    let arrayBets: MyBetInterface[] = [];
    if (this.state.expandedBet.indexOf(eventId) !== -1) {
      for (let i: number = 0; i < betIds.length; i++) {
        arrayBets.push(this.props.betsDatabase[betIds[i]]);
      }
      return (
        <BetInfo
          isFundPage={true}
          isBotsPage={false}
          bets={arrayBets}
          eventStartTime={startTime}
        />
      );
    } else {
      return null;
    }
  }

  renderfilteredEvents() {
    let betsToShow: MyBetInterface[] = [];
    let eventsCountSettleBets: { [eventReadId: number]: number } = {};
    Object.values(this.props.betsDatabase).forEach((bet) => {
      let includeBet = true;
      //step 1: check filter traderId
      if (includeBet === true && this.props.traderId !== "") {
        includeBet = this.props.traderId === bet.userId;
      }
      //step 2: check filter regionID
      if (includeBet === true && this.props.regionReadId !== 0) {
        includeBet = this.props.regionReadId === bet.event.competition.regionId;
      }
      //step 3: check filter competition
      if (includeBet === true && this.props.competitionId !== "") {
        includeBet = this.props.competitionId === bet.event.competition.id;
      }
      //step 4: check filter MarketPeriod
      if (includeBet === true && this.props.MarketPeriodSelected !== 0) {
        includeBet =
          Number(this.props.MarketPeriodSelected) === bet.marketPeriodSelected;
      }
      //step 5: check filter ScoreType
      if (includeBet === true && this.props.ScoreTypeSelected !== 0) {
        includeBet =
          Number(this.props.ScoreTypeSelected) === bet.scoreTypeSelected;
      }
      if (includeBet === true) {
        //check if bet is open
        const isbetOpen = new MyBet(bet).isOpen();
        if (isbetOpen === false) {
          // counter to show on event line header
          if (eventsCountSettleBets.hasOwnProperty(bet.event.readId) === true) {
            eventsCountSettleBets[bet.event.readId] =
              eventsCountSettleBets[bet.event.readId] + 1;
          } else {
            eventsCountSettleBets[bet.event.readId] = 1;
          }
        }
        betsToShow.push(bet);
      }
    });
    if (betsToShow.length > 0) {
      // has bets
      const eventsWithBets = myBetsArrayToBetsByEvent(betsToShow);
      return Object.values(eventsWithBets)
        .sort((a, b) => {
          return (
            new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
          );
        })
        .map((eventWithBets) => {
          const isExpanded = this.isBetExpanded(eventWithBets.readId);
          const chosenBetDate = eventWithBets.startTime;
          return (
            <div
              key={eventWithBets.readId}
              className={classnames(
                styles.wrapperEvent,
                isExpanded && styles.expanded
              )}
            >
              <ButtonBase
                classes={{
                  root: styles.eventLine,
                }}
                component={"div"}
                onClick={() => this.handleClickExpandBet(eventWithBets.readId)}
              >
                <div className={styles.date}>
                  <span>
                    {formatDayShort(new Date(eventWithBets.startTime))}
                  </span>
                </div>
                <div className={styles.region}>
                  <div className={styles.flag}>
                    {flagConverter(eventWithBets.regionAlpha2Code as string)}
                  </div>
                  <span className={styles.regionName}>
                    {eventWithBets.regionName}
                  </span>
                </div>
                <div className={styles.competitionName}>
                  {eventWithBets.competitionName}
                </div>
                <div className={styles.eventName}>
                  <span className={styles.home}>
                    {eventWithBets.homeCompetitorName}
                  </span>
                  <span className={styles.spacer}>
                    {this.props.betsDatabase[eventWithBets.betIds[0]].event
                      .ftGoalsHome !== null
                      ? this.props.betsDatabase[eventWithBets.betIds[0]].event
                          .ftGoalsHome +
                        " - " +
                        this.props.betsDatabase[eventWithBets.betIds[0]].event
                          .ftGoalsAway
                      : "vs"}
                  </span>
                  <span className={styles.away}>
                    {eventWithBets.awayCompetitorName}
                  </span>
                </div>
                <div className={styles.betsNumber}>
                  <Tooltip
                    arrow={true}
                    title={"Settled bets / Total bets"}
                    aria-label="settledBetsCounter"
                  >
                    <span>
                      {eventsCountSettleBets[eventWithBets.readId] || 0}
                      {" / "}
                      {eventWithBets.betIds.length}
                    </span>
                  </Tooltip>
                </div>
                <div className={styles.wrapperButtons}>
                  <div className={styles.plGrid}>
                    <ButtonBase
                      classes={{ root: styles.btnLink }}
                      onMouseDown={this.handleStopRipple}
                      onClick={(event) =>
                        this.props.changePLGridEventId(
                          eventWithBets.id,
                          chosenBetDate,
                          eventWithBets.homeCompetitorName,
                          eventWithBets.awayCompetitorName,
                          event,
                          this.props.betsDatabase[eventWithBets.betIds[0]].event
                        )
                      }
                    >
                      PLGRID
                    </ButtonBase>
                  </div>
                </div>
                <div className={styles.expandIcon}>
                  {isExpanded === true ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
              </ButtonBase>
              {this.renderBetInfo(
                eventWithBets.betIds,
                eventWithBets.readId,
                eventWithBets.startTime
              )}
            </div>
          );
        });
    } else {
      return this.renderZeroBets();
    }
  }

  renderZeroBets() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>
          on events between {formatDayShort(new Date(this.props.initialDay))}{" "}
          and {formatDayShort(new Date(this.props.fundEndDate))}
        </span>
      </div>
    );
  }

  renderLoadingBets() {
    return (
      <div className={styles.loadingParent}>
        <div className={styles.loadingContainer}>
          <div className={"loader-circle"}></div>
        </div>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((x) => {
          return (
            <div key={x} className={styles.betswrapper}>
              <div className={styles.wrapperEventLoading}></div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.betswrapper}>
        <div className={styles.tableHeader}>
          <div className={styles.date}>Date</div>
          <div className={styles.region}>Region</div>
          <div className={styles.competitionName}>Competition</div>
          <div className={styles.eventName}>Match</div>
          <div className={styles.betsNumber}>Bets</div>
          <div className={styles.wrapperButtons}></div>
          <div className={styles.expandIcon}></div>
        </div>
        {this.props.isLoadingBets === true
          ? this.renderLoadingBets()
          : this.renderfilteredEvents()}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    initialDay: state.fund.range.initialDay,
    betsDatabase: state.bets.betsDatabase,
    isLoadingBets: state.bets.isLoadingBets,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // getReports: (reportObject: ReportsObject) => {
    //   dispatch(GetReports(reportObject));
    // },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetsFund);
