import * as React from "react";
// import styles from "./Layout.module.css";
import Header from "./Header";

export default (props: { children?: React.ReactNode }) => (
  <React.Fragment>
    <Header />
    <div>{props.children}</div>
  </React.Fragment>
);
