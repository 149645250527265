import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./main-stats-sidebar.module.css";
import classnames from "classnames";
import {
  formatNumber,
  formatPercentage,
  formatCurrency,
} from "src/utils/numberUtils";
import { FormControl } from "@material-ui/core";
import classes from "./main-stats-sidebar.module.css";
import { StatsOverviewChildren, StatsOverviewKeys } from "src/model/myTypes";
import { optionItem, SelectForm } from "src/components/inputs/selectForm";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";

const OPTIONS_STATS: optionItem[] = [
  { id: "overall", name: "Overall" },
  { id: "thisYear", name: "This year" },
  { id: "lastMonth", name: "Last month" },
  { id: "thisMonth", name: "This month" },
  { id: "lastWeek", name: "Last week" },
  { id: "thisWeek", name: "This week" },
  { id: "yesterday", name: "Yesterday" },
  { id: "today", name: "Today" },
];

type OwnProps = {
  tableRows: { id: string; name: string }[];
};
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  selectedValue: keyof StatsOverviewChildren;
};

class MainStatsSidebar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedValue: "thisYear",
    };
  }

  printValue(value: number, tableRowKey: string) {
    switch (tableRowKey) {
      case "stake":
        return formatCurrency(value, 0);
      case "profitLoss":
        return formatCurrency(value, 0);
      case "margin":
        return formatPercentage(value, 2);
      case "bets":
        return formatNumber(value, 0);

      default:
        break;
    }
  }

  handleSelectChange = (newValue: string) => {
    this.setState({
      selectedValue: newValue as keyof StatsOverviewChildren,
    });
  };

  render() {
    return (
      <div className={styles.centerItems}>
        <FormControl className={classes.formControl}>
          <SelectForm
            changeValue={(value: string) => this.handleSelectChange(value)}
            label={null}
            selectedOptionId={this.state.selectedValue}
            optionsArray={OPTIONS_STATS}
            wrapperClassName={styles.dropdownContainer}
            rootClassName={styles.dropdown}
          />
        </FormControl>
        <div className={styles.tableRowsGroup}>
          {this.props.tableRows.map((line) => {
            const lineKey = line.id as StatsOverviewKeys;
            const data = { ...this.props.statsOverviewObject[lineKey] };
            return (
              <div className={styles.statsRight} key={line.id}>
                <span className={styles.name}>{line.name}</span>
                <span className={classnames(styles.icon, styles[line.id])} />
                <span className={styles.value}>
                  {this.printValue(data[this.state.selectedValue], line.id)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsChart: state.charts.betsChart,
    profitLossChart: state.charts.profitLossChart,
    statsOverviewObject: state.statsOverview,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MainStatsSidebar);
