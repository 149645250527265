import axios from "axios";
import { User } from "oidc-client";
import store from "src/redux/store";

const getToken = (): string => {
  let token: string = "";
  try {
    const tokenType: string = (store.getState().oidc.user as User).token_type;
    token = tokenType + " " + (store.getState().oidc.user as User).access_token;
  } catch (error) {
    // do nothing
  }
  return token;
};

export const getInstanceBackend = () => {
  const token = getToken();
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
};

export const getLongInstanceBackend = () => {
  const token = getToken();
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
};
