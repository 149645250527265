import { MyCompetitionBase, MyEvent } from "src/model/myTypes";

export default function mapperToEventBet(competition: MyCompetitionBase, event: MyEvent)
{
    let EventBet =
    {
        id: event.id,
        readId: event.readId,
        startTime: event.startTime,
        status: event.status,
        homeCompetitor:{
            id: event.homeCompetitorId,
            name: event.homeCompetitorName,
            readId: event.homeCompetitorReadId
        },
        awayCompetitor:{
            id: event.awayCompetitorId,
            name: event.awayCompetitorName,
            readId: event.awayCompetitorReadId
        },
        competition:{
            sport: competition.sport,
            id: competition.competitionId, 
            readId: competition.competitionReadId,
            name: competition.competitionName,
            type: competition.competitionType,
            region:{
                id: competition.regionReadId,
                name: competition.regionName,
                alphaCode: competition.regionAlpha2Code
            }
        }
    }

    return EventBet;
}