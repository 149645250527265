import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./betslip.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { betslipOptions, options } from "src/myEnv";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import { MyCompetitionBase, MyEvent } from "src/model/myTypes";
import { placeBet } from "src/redux/actions/action-bets";
import { MyBet } from "../../../model/classes";
import { ValueForm } from "src/components/inputs/valueForm";
import { SelectForm } from "src/components/inputs/selectForm";
import { toast } from "react-toastify";
import mapperToEventBet from "src/utils/eventBet";

type ownPropsType = {
  marketPeriod: number;
  scoreType: number;
  marketType: number;
  marketSelectionType: number;
  competitionBase: MyCompetitionBase;
  event: MyEvent;
};
type Props = ownPropsType & ReturnType<typeof mapDispatchToProps>;
type State = {
  options: { [key: string]: string };
  isReady: boolean;
};

class Betslip extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      options: {},
      isReady: false,
    };
  }

  componentDidMount() {
    this.fillDefaultValues();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.marketType !== this.props.marketType) {
      this.fillDefaultValues();
    }
  }

  fillDefaultValues(): void {
    let newStateOptions = { ...this.state.options };
    var hasChangedValues: boolean = false;
    Object.values(betslipOptions).forEach((option) => {
      if (option.hasOwnProperty("defaultValue") === true) {
        newStateOptions[option.name] = option.defaultValue as string;
        hasChangedValues = true;
      }
    });
    if (hasChangedValues) {
      this.setState({ options: newStateOptions, isReady: true });
    }
  }

  handleChangeValueFromChild = (newValue: string, optionName: string) => {
    let newState = { ...this.state };
    newState.options[optionName] = newValue;
    this.setState(newState);
  };

  handlePlaceBet = (event: any) => {
    event.preventDefault();
    let price = Number(this.state.options.Price);
    let value = Number(this.state.options.Value);
    let stake = Number(this.state.options.Stake);
    if (price === 0 || stake === 0) {
      toast.error("fill all the inputs");
    } else {
      let Event = mapperToEventBet(
        this.props.competitionBase,
        this.props.event
      );
      const betInterfaceObject = {
        marketPeriodSelected: this.props.marketPeriod,
        scoreTypeSelected: this.props.scoreType,
        marketTypeSelected: this.props.marketType,
        marketSelectionTypeSelected: this.props.marketSelectionType,
        bookie: Number(this.state.options.Bookie),
        line: Number(this.state.options.Line),
        price: price,
        stake: stake,
        value: value,
        placedDate: new Date(),
        event: Event,
        //  competition: this.props.competitionBase,
      };
      this.props.placeBet(new MyBet(betInterfaceObject));
    }
  };

  isFormValid = () => {
    if (
      this.state.options.Bookie !== "" &&
      this.state.options.Line !== "" &&
      this.state.options.Price !== "" &&
      this.state.options.Stake !== "" &&
      this.state.options.Value !== ""
    ) {
      if (
        Number(this.state.options.Price) > 0 &&
        Number(this.state.options.Stake) > 0
      ) {
        return true;
      }
    }
    return false;
  };

  renderOption(betslipOption: typeof betslipOptions.values) {
    switch (betslipOption.type) {
      case "SELECT":
        const optionsValues = options[betslipOption.name] || [];
        return (
          <SelectForm
            key={betslipOption.name}
            wrapperClassName={styles.customValueForm}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, betslipOption.name)
            }
            label={betslipOption.name}
            selectedOptionId={this.state.options[betslipOption.name] || 0}
            optionsArray={optionsValues}
          ></SelectForm>
        );

      case "INPUT-NUMBER":
        return this.props.marketType === 1 && betslipOption.name === "Line" ? (
          <ValueForm
            key={betslipOption.name}
            wrapperClassName={styles.customValueForm}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, betslipOption.name)
            }
            name={betslipOption.name}
            step={betslipOption.step as number}
            hideArrows={betslipOption.hideArrows}
            max={betslipOption.max}
            value={
              typeof this.state.options[betslipOption.name] === undefined
                ? ""
                : this.state.options[betslipOption.name]
            }
          ></ValueForm>
        ) : (
          <ValueForm
            key={betslipOption.name}
            wrapperClassName={styles.customValueForm}
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, betslipOption.name)
            }
            name={betslipOption.name}
            step={betslipOption.step as number}
            hideArrows={betslipOption.hideArrows}
            min={"0"}
            max={betslipOption.max}
            adornment={betslipOption.adornment}
            value={
              typeof this.state.options[betslipOption.name] === undefined
                ? ""
                : this.state.options[betslipOption.name]
            }
          ></ValueForm>
        );
      default:
        break;
    }
  }

  render() {
    return this.state.isReady === false ? null : (
      <form noValidate autoComplete="off" onSubmit={this.handlePlaceBet}>
        <div className={styles.wrapper}>
          <div className={styles.optionsWrapper}>
            {Object.values(betslipOptions).map((option) =>
              this.renderOption(option)
            )}
          </div>
          <div className={styles.btnSaveContainer}>
            <ButtonBase
              classes={{
                root: classnames(
                  styles.saveBtn,
                  this.isFormValid() === true && styles.saveBtnActive
                ),
              }}
              disabled={!this.isFormValid()}
              type="submit"
            >
              <SaveIcon />
              Save
            </ButtonBase>
          </div>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    placeBet: (MyBet: MyBet) => {
      dispatch(placeBet(MyBet));
    },
  };
};

export default connect(null, mapDispatchToProps)(Betslip);
