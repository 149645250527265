import React, { Component } from "react";
import classnames from "classnames";
import styles from "./competitionEvents.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import "react-datepicker/dist/react-datepicker.css";
import { MyCompetitionBase, MyEvent } from "src/model/myTypes";
import { formatDay, formatTimeHourMinute } from "src/utils/dateUtils";
import { ButtonBase } from "@material-ui/core";
import EventMarkets from "./eventMarkets";
import { EventStatus } from "../../../model/enums";

type Props = {
  competitionReadId: number;
  eventsArray: MyEvent[];
  competitionBase: MyCompetitionBase;
};

type State = { eventIdOpen: number; competitionReadId: number };

class CompetitionEvents extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      eventIdOpen: -1, // no event is open
      competitionReadId: this.props.competitionReadId,
    };
  }

  handleShowMarkets = (eventReadId: number) => {
    let newId: number = -1;
    if (this.state.eventIdOpen !== eventReadId) {
      // was not selected this id, should choose this one
      newId = eventReadId;
    }
    this.setState({
      eventIdOpen: newId,
    });
  };

  renderEvents() {
    return this.props.eventsArray.map((event: MyEvent) => {
      return (
        <div key={event.readId} className={styles.wrapper}>
          {/* {event.id} -- {event.homeCompetitorName} vs {event.awayCompetitorName} */}
          <ButtonBase
            className={classnames(
              styles.eventsWrapper,
              event.readId === this.state.eventIdOpen && styles.eventActive
            )}
            onClick={() => this.handleShowMarkets(event.readId)}
          >
            <div className={styles.date}>
              <span>{formatDay(new Date(event.startTime))}</span>
            </div>
            <div className={styles.hour}>
              <span>{formatTimeHourMinute(new Date(event.startTime))}</span>
            </div>
            <div className={styles.state}>
              <span
                className={classnames(
                  EventStatus[event.status] === "InProgress"
                    ? styles.green
                    : styles.red
                )}
              >
                {EventStatus[event.status] === "Scheduled"
                  ? ""
                  : EventStatus[event.status]}
              </span>
            </div>
            <div key={event.readId} className={styles.event}>
              <span className={styles.home}>{event.homeCompetitorName}</span>
              <span className={styles.spacer}>vs</span>
              <span className={styles.away}>{event.awayCompetitorName}</span>
            </div>
            <div className={styles.expandIcon}>
              {event.readId === this.state.eventIdOpen ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </div>
          </ButtonBase>
          {this.state.eventIdOpen === event.readId && (
            <EventMarkets
              event={event}
              competitionBase={this.props.competitionBase}
            />
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <div className={styles.competitionEvents}>{this.renderEvents()}</div>
    );
  }
}

export default CompetitionEvents;
