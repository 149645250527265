import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router";
import { connect } from "react-redux";
import Layout from "./components/Layout";
import Login from "./views/login/login";
import LayoutDashboard from "./components/LayoutDashboard";
import Main from "./views/main/main";
import Addbets from "./views/add_bets/addbets";
import Mybets from "./views/my_bets/mybets";
import Reports from "./views/reports/reports";
import FundIndex from "./views/fund_index/fundIndex";
import Page404 from "./views/page_404/page404";
import LoginCallbackPage from "./components/loginCallback";
import SignoutPage from "./components/logoutCallback";
import LoginSilentRenew from "./components/loginSilentRenew";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userManager, isUserLoggedIn } from "src/utils/userManager";
import { doLogout } from "src/redux/actions/action-user";
import { INITIAL_STATE } from "./redux/reducers/initial-state";
import ProtectedRoute from "src/components/user-permissions/protected-route";

const RouteMain = (isLoggedIn: boolean) => {
  if (isLoggedIn && window.location.pathname === "/") {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <Layout>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/signin-oidc" component={LoginCallbackPage} />
          <Route exact path="/logout" component={SignoutPage} />
          <Route exact path="/silent-renew" component={LoginSilentRenew} />
          <Route path="/*" render={() => <Redirect to="/" />} />
        </Switch>
      </Layout>
    );
  }
};

const RouteDashboard = (props: any) => {
  if (props.isLoggedIn) {
    return (
      <LayoutDashboard pathname={props.location.pathname}>
        <Switch>
          <Route
            exact
            path="/dashboard/"
            render={() => <Redirect to="/dashboard/bets-accounting" />}
          />
          <Route
            exact
            path="/dashboard/bets-accounting"
            render={() => <ProtectedRoute children={<Main />} />}
          />
          <Route
            path="/dashboard/bets-accounting/addbets"
            render={() => <ProtectedRoute children={<Addbets />} />}
          />
          <Route
            path="/dashboard/bets-accounting/mybets"
            render={() => <ProtectedRoute children={<Mybets />} />}
          />
          <Route
            path="/dashboard/bets-accounting/reports"
            render={() => <ProtectedRoute children={<Reports />} />}
          />
          <Route
            path="/dashboard/bets-accounting/fund-index"
            render={() => <ProtectedRoute children={<FundIndex />} />}
          />

          <Route path="/dashboard/*" component={Page404} />
        </Switch>
      </LayoutDashboard>
    );
  } else {
    return <Redirect to="/" />;
  }
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class App extends Component<Props> {
  constructor(props: Props) {
    super(props);
    userManager.events.addUserSignedOut(() => {
      // this event is fired if user signs out the open id provider, on another window
      // this is to ensure the logout is made
      userManager.removeUser(); // removes the user data from sessionStorage
      this.props.doLogout(); // resets all reducers to initial state
    });
  }

  render() {
    const isLoggedIn = isUserLoggedIn(this.props.openIdUser);
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <Switch>
          <Route
            path="/dashboard/"
            render={(props) => (
              <RouteDashboard {...props} isLoggedIn={isLoggedIn} />
            )}
          />
          <Route path="/" render={() => RouteMain(isLoggedIn)} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    openIdUser: state.oidc.user,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    doLogout: () => {
      dispatch(doLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
