import "./css/style-variables.css";
import "./css/main.css";
import "./css/font-sizes.css";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { userManager } from "src/utils/userManager";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import App from "./App";
import * as serviceWorker from "./registerServiceWorker";

const baseUrl = document
  .getElementsByTagName("base")[0]
  .getAttribute("href") as string;

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
