import React, { Component } from "react";
import { buildRoles, UserRoles } from "./check-permissions";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { ResourcePermission } from "./check-permissions";
import { toast } from "react-toastify";
import { doLogout } from "src/redux/actions/action-user";
import { userManager } from "src/utils/userManager";
import { homepageUri } from "src/myEnv";

const ROUTE_PATHNAME_PERMISSIONS: ResourcePermission = {
  "/dashboard/bets-accounting": [UserRoles.Admin, UserRoles.Accountant],
  "/dashboard/bets-accounting/addbets": [UserRoles.Admin],
  "/dashboard/bets-accounting/mybets": [UserRoles.Admin, UserRoles.Accountant],
  "/dashboard/bets-accounting/reports": [UserRoles.Admin, UserRoles.Accountant],
  "/dashboard/bets-accounting/fund-index": [UserRoles.Admin, UserRoles.Visitor],
  "/dashboard/value-viewer": [UserRoles.Admin],
  "/dashboard/value-viewer/events": [UserRoles.Admin],
  "/dashboard/bets-bot": [UserRoles.Admin],
  "/dashboard/bets-bot/bets": [UserRoles.Admin],
  "/dashboard/bets-bot/odds-viewer": [UserRoles.Admin],
  "/dashboard/bets-bot/settings": [UserRoles.Admin],
};

type ownProps = {
  children?: React.ReactNode;
};
type Props = ownProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ProtectedRoute extends Component<Props> {
  handleLogout = () => {
    this.props.doLogout();
  };

  render() {
    let allow = false;
    let roles: UserRoles[] = [];
    let pathname = window.location.pathname.match(/.*[^/:\d*]/) || [
      window.location.pathname,
    ];
    if (ROUTE_PATHNAME_PERMISSIONS.hasOwnProperty(pathname[0]) === false) {
      // route is not defined on permissions variable, should allow
      allow = true;
    }
    if (
      allow === false &&
      typeof this.props.user !== "undefined" &&
      this.props.user.hasOwnProperty("profile")
    ) {
      // build roles array
      roles = buildRoles(this.props.user.profile.roles);
      // check roles: if one of the roles has permission, should allow
      roles.some((role) => {
        const hasPermission = ROUTE_PATHNAME_PERMISSIONS[pathname[0]].includes(
          role
        );
        if (hasPermission === true) {
          allow = true;
          return true;
        } else {
          return false;
        }
      });
    }

    if (allow === true) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    } else {
      const defaultUrl = _findDefaultUrl(roles);
      if (defaultUrl === "") {
        // Logoff if there is no access for user roles
        userManager.revokeAccessToken().then(() => {
          const info =
            roles.length === 0
              ? "No user roles detected"
              : "Roles detected:" + roles.toString();
          toast.error(
            "You were automatically logged out due to lack of permissions. " +
              info
          );
          this.props.doLogout(); // resets all reducers to initial state
          return <Redirect to={"/"}></Redirect>;
        });
        return null;
      } else {
        return <Redirect to={defaultUrl}></Redirect>;
      }
    }
  }
}

function _findDefaultUrl(roles: UserRoles[]) {
  let url = "";
  roles.forEach((role) => {
    Object.entries(ROUTE_PATHNAME_PERMISSIONS).some((permissionRoles) => {
      if (permissionRoles[1].includes(role)) {
        url = permissionRoles[0];
        return true;
      }
      return false;
    });
  });
  return url;
}

function mapStateToProps(state: typeof INITIAL_STATE) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    doLogout: () => {
      dispatch(doLogout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
