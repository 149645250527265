import { MyPLGridsInfo } from "src/model/myTypes";
import { getInstanceBackend } from "./axios";

type getProfitLossGridsByMatchAction = {
  type: "GET_PROFIT_LOSS_GRIDS_BY_MATCH";
  gridInfoPayload: MyPLGridsInfo;
};

export type KnownAction = getProfitLossGridsByMatchAction; // | action1 | action2 | ...

export function getProfitLossGridsByMatch(
  eventId: string,
  isFundPage: boolean,
  traderId: string,
  bookieFilter?: number[],
  isBotsPage?: boolean
) {
  return function action(dispatch: any) {
    // Being fund-index page or my-bets page, shows open and settled bets
    //  Executable = 1, Placed = 2, Winner = 3, Loser = 4, Void = 5
    const betStatus =
      "&betStatus=1&betStatus=2&betStatus=3&betStatus=4&betStatus=5";
    let bookies = "";
    if (typeof bookieFilter !== "undefined") {
      bookieFilter.forEach((bookieId) => {
        if (bookieId !== 0) {
          bookies += "&bookie=" + bookieId;
        }
      });
    }
    let onlyBots = "";
    if (typeof onlyBots !== "undefined" && isBotsPage === true) {
      onlyBots = "&OnlyBot=true";
    }
    const request = getInstanceBackend()({
      method: "get",
      url:
        "/Grids?eventId=" +
        eventId +
        "&traderId=" +
        traderId +
        betStatus +
        bookies +
        onlyBots,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_PROFIT_LOSS_GRIDS_BY_MATCH",
          gridInfoPayload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getProfitLossGridsByMatch", error);
      });
  };
}
