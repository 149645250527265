import { MyCompetitionData, MyEvent } from "src/model/myTypes";
import { EventDto } from "src/model/types";
import { getDayString } from "src/utils/dateUtils";

export default function eventDtoToMyCompetitionsByDay(
  eventsDTO: EventDto[],
  isFirst: boolean,
  stateEventsByDay: {
    [day: string]: MyCompetitionData;
  }
): {
  [day: string]: MyCompetitionData;
} {
  let result = { ...stateEventsByDay }; // copy everything

  eventsDTO.forEach((EventDto) => {
    const eachCompetitionReadId = EventDto.competitionReadId;
    // create myEvent
    let myEvent: MyEvent = {
      id: EventDto.id,
      readId: EventDto.readId,
      startTime: EventDto.startTime,
      status: EventDto.status,
      homeCompetitorId: EventDto.homeCompetitorId,
      homeCompetitorReadId: EventDto.homeCompetitorReadId,
      homeCompetitorName: EventDto.homeCompetitorName,
      awayCompetitorId: EventDto.awayCompetitorId,
      awayCompetitorReadId: EventDto.awayCompetitorReadId,
      awayCompetitorName: EventDto.awayCompetitorName,
    };
    // ensure current day exists
    const eventDayString = getDayString(new Date(EventDto.startTime));
    if (result.hasOwnProperty(eventDayString) === false) {
      result = {
        ...result,
        [eventDayString]: {},
      };
    }

    // ensure current competition exists
    if (result[eventDayString].hasOwnProperty(eachCompetitionReadId) === false) {
      let myCompetition: MyCompetitionData = {
        [eachCompetitionReadId]: {
          sport: EventDto.sport,
          competitionId: EventDto.competitionId,
          competitionReadId: EventDto.competitionReadId,
          competitionName: EventDto.competitionName,
          competitionType: EventDto.competitionType,
          regionReadId: Number(EventDto.regionReadId),
          regionName: EventDto.regionName,
          regionAlpha2Code: EventDto.regionAlpha2Code,
          events: [],
          eventIds: [],
        },
      };
      if (
        typeof result[eventDayString] !== "undefined" &&
        result[eventDayString].hasOwnProperty(eachCompetitionReadId) &&
        isFirst === false
      ) {
        // Copies everything
        myCompetition = {
          [eachCompetitionReadId]: {
            ...result[eventDayString][eachCompetitionReadId],
          },
        };
      }
      result[eventDayString] = {
        ...result[eventDayString],
        ...myCompetition,
      };
    }
    // only IF not already there that eventId
    if (result[eventDayString][eachCompetitionReadId].eventIds.indexOf(myEvent.id) === -1) {
      // add event to current competition
      result[eventDayString][eachCompetitionReadId].events.push(myEvent);
      // add eventIds list to current competition
      result[eventDayString][eachCompetitionReadId].eventIds.push(myEvent.id);
    }
  });
  return result;
}
