import React, { Component } from "react";
import styles from "./Header.module.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import { connect } from "react-redux";
import { doLogout } from "src/redux/actions/action-user";
import { userManager } from "src/utils/userManager";
import { homepageUri } from "src/myEnv";
import { Link } from "react-router-dom";
import DehazeIcon from "@material-ui/icons/Dehaze";
import CloseIcon from "@material-ui/icons/Close";
import { handleSidebar } from "src/redux/actions/action-settings";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";

type ownProps = { showLogout?: boolean };
type Props = ownProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class Header extends Component<Props> {
  handleDoLogout = () => {
    userManager.signoutRedirect({
      post_logout_redirect_uri: homepageUri,
    }); // trigger a redirect of the current window to the end session endpoint
    this.props.doLogout(); // resets all reducers to initial state
  };

  render() {
    return (
      <div className={styles.header}>
        {this.props.showLogout === true && (
          <ButtonBase
            component={"div"}
            className={styles.sidebarBtn}
            onClick={this.props.handleSidebar}
          >
            {this.props.isSidebarOpen ? <CloseIcon /> : <DehazeIcon />}
          </ButtonBase>
        )}
        <div className={styles.logoContainer}>
          <img
            className={styles.icon}
            src="img/cyborgsports-trading-icon_transparent.png"
            alt="Icon"
          />
        </div>
        <div className={styles.centerContainer}>
          <img
            className={styles.logo}
            src="img/cyborgsports-trading-logo.svg"
            alt="Logo"
          />
          {this.props.showLogout === true && (
            <ButtonBase
              classes={{ root: styles.logout }}
              component={Link}
              to={"/logout"}
              onClick={this.handleDoLogout}
            >
              Logout
            </ButtonBase>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    isSidebarOpen: state.settings.isSidebarOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSidebar: () => {
      dispatch(handleSidebar());
    },
    doLogout: () => {
      dispatch(doLogout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
