import { getInstanceBackend } from "./axios";
import { MyBetInterface, RangeDates } from "src/model/myTypes";
import { toast } from "react-toastify";
import { User } from "oidc-client";
import { UserState } from "redux-oidc";
import { defaultPagination, Pagination } from "./action-events";
import { createGroupedArray } from "src/utils/arrayUtils";

type PlaceBetAction = {
  type: "PLACE_BET";
  payload: MyBetInterface;
};

type RemoveBetAction = {
  type: "REMOVE_BET";
  payload: MyBetInterface;
};

type GetBetAction = {
  type: "GET_BETS";
  payload: { bets: MyBetInterface[]; initialDay: Date; endDay: Date };
};
type GetFilteredBetAction = {
  type: "GET_FILTERED_BETS";
  payload: { bets: MyBetInterface[] };
};

type GetBetsDayHistoryAction = {
  type: "GET_BETS_DAY_HISTORY";
  payload: { bets: MyBetInterface[]; day: Date };
};

type ChangeMyBetsRangeDatesAction = {
  type: "CHANGE_BETS_RANGE_DATES";
  payload: RangeDates;
};

type IsLoadingBetsAction = {
  type: "IS_LOADING_BETS";
};

type AddFilteredBetsAction = {
  type: "ADD_FILTERED_BETS";
  payload: { betIds: number[]; title: string };
};

type ClearFilteredBetsAction = {
  type: "CLEAR_FILTERED_BETS";
};

export type BetActions =
  | PlaceBetAction
  | RemoveBetAction
  | GetBetAction
  | GetBetsDayHistoryAction
  | ChangeMyBetsRangeDatesAction
  | IsLoadingBetsAction
  | ClearFilteredBetsAction
  | AddFilteredBetsAction
  | GetFilteredBetAction; // | action1 | action2 | ...

export function placeBet(Mybet: MyBetInterface) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "post",
      url: "/Bets",
      data: { ...Mybet },
    });
    return request
      .then((response) => {
        toast.success("Bet placed successfully.");
        dispatch({
          type: "PLACE_BET",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in placeBet", error);
        toast.error(
          "Something went wrong when trying to place the Bet. Please try again, or contact us."
        );
      });
  };
}

export function removeBet(id: Number) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "delete",
      url: "/bets?id=" + id,
    });
    return request
      .then((response) => {
        toast.info("Bet removed successfully.");
        dispatch({
          type: "REMOVE_BET",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in removeBet", error);
      });
  };
}

export function getBets(
  initialDay: Date,
  endDay: Date,
  traderId: string,
  onlyBot: boolean | null,
  pagination?: Pagination
) {
  return function action(dispatch: any) {
    dispatch(isLoadingBets());
    // ensure all hours of end day is included in response
    let safeEndDay = new Date(endDay);
    safeEndDay.setHours(23, 59, 59, 999);
    const safeInitialDay = new Date(initialDay);

    // let safeOnlyBot = onlyBot === null ? "null" : onlyBot;

    if (typeof pagination === "undefined") {
      pagination = defaultPagination;
    }
    dispatch(
      fetchByPageBets(
        safeInitialDay,
        safeEndDay,
        traderId,
        pagination.pageSize, // Api returns requested number of entries
        pagination.fetchedCount, // Api starts at requested position
        onlyBot,
        dispatch
      )
    );
  };
}

const fetchByPageBets = function (
  initialDay: Date,
  safeEndDay: Date,
  traderId: string,
  pageSize: number,
  fetchedCount: number,
  onlyBot: boolean | null,
  dispatch: any,
  data?: string[]
) {
  let responseData: string[] = [];
  if (typeof data !== "undefined" && data !== null) {
    responseData = data;
  }

  return function action(dispatch: any) {
    getInstanceBackend()({
      method: "get",
      url: "/bets",
      params: {
        initialDay: initialDay.toISOString(),
        endDay: safeEndDay.toISOString(),
        traderId: traderId,
        PageSize: pageSize,
        FetchedCount: fetchedCount,
        onlyBot: onlyBot,
      },
      // "/bets?initialDay=" +
      // initialDay.toISOString() +
      // "&endDay=" +
      // safeEndDay.toISOString() +
      // "&traderId=" +
      // traderId.toString() +
      // "&PageSize=" +
      // pageSize +
      // "&FetchedCount=" +
      // fetchedCount
      // // "&onlyBot=" +
      // // onlyBot
    })
      .then((response) => {
        responseData = responseData.concat(response.data.data);
        if (response.data.data.length === pageSize) {
          fetchedCount = fetchedCount + pageSize;
          dispatch(
            fetchByPageBets(
              initialDay,
              safeEndDay,
              traderId,
              pageSize,
              fetchedCount,
              onlyBot,
              dispatch,
              responseData
            )
          );
        } else {
          dispatch({
            type: "GET_BETS",
            payload: {
              bets: responseData,
              initialDay: initialDay,
              endDay: safeEndDay,
              isFinished: true,
            },
          });
          // if (needStatus) {
          //   dispatch(getEvents(initialDay, safeEndDay));
          // }
        }
      })
      .catch((error) => {
        // do nothing
        console.log("error in GetBets", error);
      });
  };
};

export function getBetsDayHistory(
  initialDay: Date,
  endDay: Date,
  traderId: string,
  pagination?: Pagination
) {
  return function action(dispatch: any, getState: Function) {
    if (typeof traderId === "undefined") {
      try {
        const stateOidc: UserState = getState().oidc;
        const userId = (stateOidc.user as User).profile.sub;
        if (typeof userId === "string" && userId.length > 0) {
          traderId = userId;
        }
      } catch (error) {
        traderId = "-1"; //Invalid traderId because no user is logged in
      }
    }

    // ensure all hours of end day is included in response
    let safeInitialDay = new Date(initialDay);
    safeInitialDay.setHours(0, 0, 0, 0);
    let safeEndDay = new Date(endDay);
    safeEndDay.setHours(23, 59, 59, 999);

    if (typeof pagination === "undefined") {
      pagination = defaultPagination;
    }
    dispatch(
      fetchByPageBetsDayHistory(
        safeInitialDay,
        safeEndDay,
        traderId,
        pagination.pageSize, // Api returns requested number of entries
        pagination.fetchedCount, // Api starts at requested position
        dispatch
      )
    );
  };
}

const fetchByPageBetsDayHistory = function (
  safeInitialDay: Date,
  safeEndDay: Date,
  traderId: string,
  pageSize: number,
  fetchedCount: number,
  dispatch: any,
  data?: string[]
) {
  let responseData: string[] = [];
  if (typeof data !== "undefined" && data !== null) {
    responseData = data;
  }
  return function action(dispatch: any) {
    getInstanceBackend()({
      method: "get",
      url:
        "/bets?initialDay=" +
        safeInitialDay.toISOString() +
        "&endDay=" +
        safeEndDay.toISOString() +
        "&traderId=" +
        traderId.toString() +
        "&PageSize=" +
        pageSize +
        "&FetchedCount=" +
        fetchedCount,
    })
      .then((response) => {
        responseData = responseData.concat(response.data.data);
        if (response.data.data.length === pageSize) {
          fetchedCount = fetchedCount + pageSize;
          dispatch(
            fetchByPageBetsDayHistory(
              safeInitialDay,
              safeEndDay,
              traderId,
              pageSize,
              fetchedCount,
              dispatch,
              responseData
            )
          );
        } else {
          dispatch({
            type: "GET_BETS_DAY_HISTORY",
            payload: {
              bets: responseData,
              day: safeInitialDay,
            },
          });
        }
      })
      .catch((error) => {
        // do nothing
        console.log("error in GetBetsDayHistory", error);
      });
  };
};

export function getFilteredBets(betIds: number[]) {
  return function action(dispatch: any) {
    dispatch(isLoadingBets());
    const arraySize = 1000;
    const groupedBetIds = createGroupedArray(betIds, arraySize);
    const maximumCalls = groupedBetIds.length - 1;
    let index = 0;
    dispatch(getFilteredBetsPage(groupedBetIds, maximumCalls, index));
  };
}

function getFilteredBetsPage(
  groupedBetIds: number[][],
  maximumCalls: number,
  callNumber: number,
  data?: string[]
) {
  let responseData: string[] = [];
  if (typeof data !== "undefined" && data !== null) {
    responseData = data;
  }
  return function action(dispatch: any) {
    getInstanceBackend()({
      method: "post",
      url: "/bets/get",
      data: {
        arrayBetIds: groupedBetIds[callNumber],
      },
    })
      .then((response) => {
        responseData = responseData.concat(response.data.data);
        if (maximumCalls === callNumber) {
          dispatch({
            type: "GET_FILTERED_BETS",
            payload: {
              bets: responseData,
            },
          });
        } else {
          dispatch(
            getFilteredBetsPage(
              groupedBetIds,
              maximumCalls,
              callNumber + 1,
              responseData
            )
          );
        }
      })
      .catch((error) => {
        // do nothing
        console.log("error in GetFilteredBets", error);
      });
  };
}

export function changeMyBetsDates(range: RangeDates) {
  return {
    type: "CHANGE_BETS_RANGE_DATES",
    payload: range,
  };
}
export function isLoadingBets() {
  return {
    type: "IS_LOADING_BETS",
  };
}

export function addBetsFiltered(betIds: number[], title: string) {
  return {
    type: "ADD_FILTERED_BETS",
    payload: { betIds: betIds, title: title },
  };
}
export function clearBetsFiltered() {
  return {
    type: "CLEAR_FILTERED_BETS",
  };
}
