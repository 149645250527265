import { Action, Reducer } from "redux";
import { InitialStatsChart } from "./initial-state";
import { KnownAction } from "../actions/action-main";
import { StatsChartReducer } from "src/model/myTypes";
import { StatsChartType } from "src/model/enums";
import { formatChartDataByDayBeforeToday } from "../mappers/mapper-charts";
import { UserActions } from "../actions/action-user";

const unloadedState: StatsChartReducer = InitialStatsChart;

export const reducer: Reducer<StatsChartReducer> = (
  state: StatsChartReducer | undefined,
  incomingAction: Action
): StatsChartReducer => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as KnownAction | UserActions;
  switch (action.type) {
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
    case "GET_STATS_CHART":
      let chartType = action.payload.type;
      if (chartType === StatsChartType.BetsChart) {
        const betsChartData = formatChartDataByDayBeforeToday(
          action.payload.data,
          action.payload.numberOfDays
        );
        return {
          ...state,
          betsChart: betsChartData,
        };
      } else if (chartType === StatsChartType.ProfitLossChart) {
        const profitLossChartData = formatChartDataByDayBeforeToday(
          action.payload.data,
          action.payload.numberOfDays
        );
        return {
          ...state,
          profitLossChart: profitLossChartData,
        };
      } else {
        return { ...state };
      }
  }
  return state;
};
