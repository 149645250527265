type doLogoutAction = {
  type: "DO_LOGOUT";
};

export type UserActions = doLogoutAction; // | action1 | action2 | ...

export function doLogout() {
  localStorage.setItem("shouldClearCache", "true");
  return {
    type: "DO_LOGOUT",
  };
}
