/**
 * Method to transform an array into an object.
 * Example:
 *  array = [{a:1,b:11,c:111}, {a:2,b:22,c:222}, {a:3,b:3,c:333}]
 *  objectKey = 'a'
 *
 *  resultObject = { 1:{a:1,b:11,c:111}, 2:{a:2,b:22,c:222}, 3:{a:3,b:3,c:333}] }
 *
 * @param {Array} array
 * @param {String} objectKey
 */
export function fromArrayToObject(array: any[], objectKey: string) {
  let newObject = {};

  array.forEach((element) => {
    newObject = {
      ...newObject,
      [element[objectKey]]: element,
    };
  });

  return newObject;
}
