import { Action, Reducer } from "redux";
import { InitialStatefavoriteCompetitions } from "./initial-state";

import { MyFavoriteCompetitions } from "src/model/myTypes";
import { FavoriteAction } from "../actions/action-competitions";
import { fromArrayToObject } from "../mappers/mapper-utils";
import { UserActions } from "../actions/action-user";

const unloadedState: MyFavoriteCompetitions = InitialStatefavoriteCompetitions;

export const reducer: Reducer<MyFavoriteCompetitions> = (
  state: MyFavoriteCompetitions | undefined,
  incomingAction: Action
): MyFavoriteCompetitions => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as FavoriteAction | UserActions;

  switch (action.type) {
    case "FAVORITE_COMPETITION":
      const payload = fromArrayToObject(action.payload, "readId");
      return {
        ...payload,
      };
    case "ADD_FAVORITE_COMPETITION":
      return {
        ...state,
        [action.payload.readId]: action.payload,
      };
    case "REMOVE_FAVORITE_COMPETITION":
      const competitionIdToRemove: number = action.payload;
      let trimmedFavoriteCompetitions = { ...state };
      delete trimmedFavoriteCompetitions[competitionIdToRemove];
      return {
        ...trimmedFavoriteCompetitions,
      };
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
