import React, { Component } from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./competitions.module.css";
import CompetitionLine from "src/views/add_bets/eventsList/competitionLine";
import { MarketPeriod, ModelType, ScoreType } from "src/model/enums";
import classnames from "classnames";
import { formatDay } from "src/utils/dateUtils";
import { ButtonBase } from "@material-ui/core";
import Modal from "src/components/pop-up/modal";
import AddEventManual from "src/views/add_bets/addEvent/addEventManual";

type OwnProps = {
  dayString: string;
  targetDate: Date;
  isValueViewer: boolean;
  isBotOddsViewer?: boolean;
  modelTypeId?: ModelType; // needed only if isValueViewer===true
  scoreTypeId?: ScoreType; // needed only if isValueViewer===true
  marketPeriodId?: MarketPeriod; // needed only if isValueViewer===true
  callbackExpandCollapseCompetition?: (modelTypeId: number, eventIdsArray: string[], willBeExpanded: boolean) => void;
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type State = { isOpenAddEventPopup: boolean };

class Competitions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenAddEventPopup: false,
    };
  }

  isFavorite(competitionReadId: number): boolean {
    return Object.keys(this.props.favoritesCompetitions).indexOf(competitionReadId.toString()) !== -1;
  }

  sortByName(nameOne: string, nameTwo: string) {
    return nameOne.localeCompare(nameTwo);
  }
  sortById(idOne: number, idTwo: number) {
    return idOne - idTwo;
  }

  handleToggleAddEventPopup = () => {
    this.setState({ isOpenAddEventPopup: !this.state.isOpenAddEventPopup });
  };

  renderCompetitions() {
    return Object.values(this.props.eventsOfDay)
      .sort((a, b) => {
        const aCountry = a.regionName as string;
        const bCountry = b.regionName as string;

        if (this.isFavorite(a.competitionReadId) && this.isFavorite(b.competitionReadId)) {
          // both competitions are favorite
          // sort by regionName
          const sortByName: number = this.sortByName(aCountry, bCountry);
          if (sortByName === 0) {
            // sortByID
            return this.sortById(a.competitionReadId, b.competitionReadId);
          } else {
            return sortByName;
          }
        } else if (this.isFavorite(a.competitionReadId)) {
          //only competition A is favorite
          return -1;
        } else if (this.isFavorite(b.competitionReadId)) {
          //only competition B is favorite
          return 1;
        } else {
          // neither is favorite

          // sort by regionName
          const sortByName: number = this.sortByName(aCountry, bCountry);
          if (sortByName === 0) {
            // sortByID
            return this.sortById(a.competitionReadId, b.competitionReadId);
          } else {
            return sortByName;
          }
        }
      })
      .map((competition) => {
        return (
          <CompetitionLine
            key={this.props.dayString + "_" + this.props.modelTypeId + "_" + competition.competitionId}
            competition={competition}
            isFavorite={this.isFavorite(competition.competitionReadId)}
            isValueViewer={this.props.isValueViewer}
            isBotOddsViewer={this.props.isBotOddsViewer ? true : false}
            modelTypeId={this.props.modelTypeId as ModelType}
            scoreTypeId={this.props.scoreTypeId as ScoreType}
            marketPeriodId={this.props.marketPeriodId as MarketPeriod}
            callbackExpandCollapseCompetition={this.props.callbackExpandCollapseCompetition}
          />
        );
      });
  }

  renderAddEvent() {
    return (
      <div className={styles.addContainer}>
        <ButtonBase
          classes={{
            root: classnames(styles.btn, styles.btnActive),
          }}
          onClick={this.handleToggleAddEventPopup}
        >
          ADD NEW EVENT
        </ButtonBase>
        {this.renderAddEventModal()}
      </div>
    );
  }

  renderAddEventModal() {
    return (
      this.state.isOpenAddEventPopup && (
        <Modal handleClickOutside={() => null}>
          <AddEventManual closePopup={this.handleToggleAddEventPopup} parentDate={this.props.targetDate} />
        </Modal>
      )
    );
  }

  renderZeroEvents() {
    return (
      <div className={styles.zeroEventsContainer}>
        <p className={classnames(styles.text, styles.big)}>No events found</p>
        <span className={styles.text}>for the day {formatDay(this.props.targetDate)}</span>
      </div>
    );
  }

  renderLoading(isOverlay: boolean) {
    return (
      <div>
        <div className={styles.loadingContainer}>
          <div className={"loader-circle"}></div>
        </div>
        {isOverlay === false &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => {
            return (
              <div key={x} className={styles.wrapper}>
                <div className={styles.competitionWrapper}></div>
              </div>
            );
          })}
      </div>
    );
  }

  render() {
    const hasCompetitions =
      typeof this.props.eventsOfDay !== "undefined" && Object.keys(this.props.eventsOfDay).length > 0;

    return (
      <div className={styles.competitions}>
        <div className={styles.loadingParent}>
          {this.props.isLoadingEvents === true && hasCompetitions === true
            ? this.renderLoading(true)
            : this.props.isLoadingEvents === true && hasCompetitions === false
            ? this.renderLoading(false)
            : this.props.isLoadingEvents === false && hasCompetitions === true
            ? this.renderCompetitions()
            : this.renderZeroEvents()}
        </div>
        {this.renderAddEvent()}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE, ownProps: OwnProps) => {
  return {
    eventsOfDay: state.events.list[ownProps.dayString],
    isLoadingEvents: state.events.isLoadingEvents,
    favoritesCompetitions: state.favoriteCompetitions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Competitions);
