import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { reducer as ReducerEvents } from "./reducers/reducer-events";
import { reducer as ReducerBets } from "./reducers/reducer-bets";
import { reducer as ReducerCompetitions } from "./reducers/reducer-competitions";
import { reducer as statsReducer } from "./reducers/reducer-statsOverview";
import { reducer as chartsReducer } from "./reducers/reducer-charts";
import { fundReducer } from "./reducers/reducer-fund";
import { reducer as betsOverviewReducer } from "./reducers/reducer-betsOverview";
import { reducer as settingsReducer } from "./reducers/reducer-settings";
import { INITIAL_STATE } from "./reducers/initial-state";
import { loadState, saveState } from "./storage/local-storage";
import merge from "lodash/merge";
import throttle from "lodash/throttle";
import { userManager } from "src/utils/userManager";
import { loadUser, reducer as oidcReducer } from "redux-oidc";
import { reducer as plGridsReducer } from "./reducers/reducer-plgrids";
import { reportsReducer } from "./reducers/reducer-reports";

const reducers = combineReducers({
  events: ReducerEvents,
  bets: ReducerBets,
  favoriteCompetitions: ReducerCompetitions,
  oidc: oidcReducer,
  plGrids: plGridsReducer,
  statsOverview: statsReducer,
  reports: reportsReducer,
  charts: chartsReducer,
  fund: fundReducer,
  betsOverview: betsOverviewReducer,
  settings: settingsReducer,
});

let shouldClearCache = localStorage.getItem("shouldClearCache");
let persistedState = { ...INITIAL_STATE };

if (shouldClearCache === "true") {
  clearLocalStorage();
  localStorage.removeItem("shouldClearCache");
} else {
  persistedState = merge(persistedState, { ...loadState() });
}

const enhancers = [];
const windowIfDefined = typeof window === "undefined" ? null : (window as any);
if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

const store = createStore(
  reducers,
  persistedState,
  compose(
    applyMiddleware(
      thunk,
    ),
    ...enhancers
  )
);
// Loads potentially existing user data into the redux store, thus eliminating a new authentication roundtrip to the authentication server when a tab is closed or a new tab is opened.
loadUser(store, userManager);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

export function clearLocalStorage() {
  localStorage.removeItem("state");
}

export default store;
