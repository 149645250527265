import {
  EventStatus,
  SportType,
  CompetitionType,
} from "src/model/enums";
import {
  CompetitionBet,
  Competitor,
  EventBet,
  MyCompetition,
  MyCompetitionBase,
  MyEvent,
  Region,
  TradersCompetition,
} from "./myTypes";

export const defaultMyEvent: MyEvent = {
  id: "",
  readId: 0,
  startTime: "",
  status: EventStatus.NoStatus,
  homeCompetitorId: "",
  homeCompetitorReadId: 0,
  homeCompetitorName: "",
  awayCompetitorId: "",
  awayCompetitorReadId: 0,
  awayCompetitorName: "",
};

export const defaultMyCompetitionBase: MyCompetitionBase = {
  sport: SportType.NoSport,
  competitionId: "",
  competitionReadId: 0,
  competitionName: "",
  competitionType: CompetitionType.None,
  regionReadId: 0,
  regionName: "",
  regionAlpha2Code: "",
};

export const defaultMyCompetition: MyCompetition = {
  sport: SportType.NoSport,
  competitionId: "",
  competitionReadId: 0,
  competitionName: "",
  competitionType: CompetitionType.None,
  regionReadId: 0,
  regionName: "",
  regionAlpha2Code: "",
  events: [],
  eventIds: [],
};

const defaultCompetitor: Competitor = {
  id: "",
  readId: 0,
  name: "",
};

const defaultRegion: Region = {
  id: 0,
};

const defaultCompetitionBet: CompetitionBet = {
  sport: SportType.Football,
  id: "",
  readId: 0,
  type: CompetitionType.None,
  region: defaultRegion,
};


export const defaultEventBet: EventBet = {
  id: "",
  readId: 0,
  startTime: "",
  status: EventStatus.NoStatus,
  homeCompetitor: defaultCompetitor,
  awayCompetitor: defaultCompetitor,
  competition: defaultCompetitionBet,
};

export const defaultTraderCompetition: TradersCompetition = {
  id: "",
  name: "ALL",
  competitions: [],
};
