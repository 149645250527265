import { createUserManager } from "redux-oidc";
import { WebStorageStateStore, InMemoryWebStorage } from "oidc-client";

const hasLocalStorage =
  "localStorage" in window && window.localStorage !== null;

const userManagerConfig = {
  authority: process.env.REACT_APP_OPEN_ID_AUTHORITY,
  client_id: process.env.REACT_APP_OPEN_ID_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""
    }/signin-oidc`,
  response_type: "code",
  scope: process.env.REACT_APP_OPEN_ID_SCOPE,
  userStore: hasLocalStorage
    ? new WebStorageStateStore({ store: window.localStorage })
    : new WebStorageStateStore({ store: new InMemoryWebStorage() }),
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname
    }${window.location.port ? `:${window.location.port}` : ""}/silent-renew`,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export const userManager = createUserManager(userManagerConfig);

export function isUserLoggedIn(user) {
  let isLoggedIn = false;
  if (
    typeof user !== "undefined" &&
    user !== null &&
    user.hasOwnProperty("expires_at")
  ) {
    const expirationDate = new Date(
      user.expires_at * 1000
    );
    const now = new Date();
    isLoggedIn = expirationDate > now;
  }
  return isLoggedIn;
}
