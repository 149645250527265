import React, { Component } from "react";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./betsByCompetition.module.css";
import { connect } from "react-redux";
import { flagConverter } from "src/utils/flag-converter";
import { formatDay, formatDayShort } from "src/utils/dateUtils";
import classnames from "classnames";
import { Switch } from "@material-ui/core";

type ownProps = {
  isSticky: boolean;
  handleIsSticky: () => void;
};

type Props = ownProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {};

class BetsByCompetition extends Component<Props, State> {
  sortByName(nameOne: string, nameTwo: string) {
    return nameOne.localeCompare(nameTwo);
  }
  sortById(idOne: number, idTwo: number) {
    return idOne - idTwo;
  }

  renderCompetitions() {
    return Object.values(this.props.betsCompetition)
      .sort((a, b) => {
        const aBetNumber = a.betIds.length;
        const bBetNumber = b.betIds.length;

        const aCompetitionName = a.competitionName;
        const bCompetitionName = b.competitionName;

        if (aBetNumber === bBetNumber) {
          //same betsNumber
          //sort by competitionName
          const sortByCompetitionName: number = this.sortByName(
            aCompetitionName as string,
            bCompetitionName as string
          );
          if (sortByCompetitionName === 0) {
            // sortByID
            return this.sortById(a.competitionReadId, b.competitionReadId);
          } else {
            return sortByCompetitionName;
          }
        } else {
          return bBetNumber - aBetNumber;
        }
      })

      .map((competition) => {
        return (
          <div
            key={competition.competitionReadId}
            className={styles.betsByCompetitionWrapper}
          >
            <span className={styles.flag}>
              {flagConverter(competition.regionAlpha2Code as string)}
            </span>
            <span className={styles.competitionName}>
              {competition.competitionName}
            </span>
            <span className={styles.betsLength}>
              {competition.betIds.length}
            </span>
          </div>
        );
      });
  }

  renderZeroBets() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>
          on events between {formatDayShort(new Date(this.props.initialDay))}{" "}
          and {formatDayShort(new Date(this.props.endDay))}
        </span>
      </div>
    );
  }

  render() {
    const hasBetsFiltered = this.props.betsFiltered.betIds.length > 0;
    const hasBets =
      typeof this.props.betsCompetition !== "undefined" &&
      Object.keys(this.props.betsCompetition).length > 0;
    let traderName = "";
    this.props.traders.forEach((trader) => {
      if (trader.id === this.props.traderSelected) {
        traderName = trader.name;
      }
    });
    if (traderName === "ALL" || traderName === "") {
      traderName = "All";
    }
    return (
      <div className={styles.betsByCompetition}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span className={styles.main}>Bets by competition</span>
            <span className={styles.subtitle}>
              {hasBetsFiltered ? (
                this.props.betsFiltered.tittle
              ) : (
                <span>
                  {formatDay(new Date(this.props.initialDay))}-
                  {formatDay(new Date(this.props.endDay))}
                </span>
              )}
            </span>
            <span className={styles.subtitle}>Trader: {traderName}</span>
          </div>

          <div className={styles.stickyContainer}>
            <span
              className={classnames(
                styles.stickyText,
                !this.props.isSticky && styles.hide
              )}
            >
              Sticky
            </span>
            <Switch
              classes={{
                root: styles.root,
                switchBase: styles.switchBase,
                thumb: styles.thumb,
                track: styles.track,
                checked: styles.checked,
              }}
              className={styles.switch}
              checked={this.props.isSticky}
              onChange={this.props.handleIsSticky}
              name="isDisabled"
            />
          </div>
        </div>
        {hasBets === true ? this.renderCompetitions() : this.renderZeroBets()}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsCompetition: state.bets.betsByCompetition,
    initialDay: state.bets.range.initialDay,
    endDay: state.bets.range.endDay,
    traderSelected: state.settings.traderId,
    traders: state.reports.traders,
    betsFiltered: state.bets.betsFiltered,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetsByCompetition);
