import React, { Component } from "react";
import styles from "./mybets.module.css";
import classnames from "classnames";
import MyDateRangePicker from "src/components/date-picker/MyDateRangePicker";
import SettleMatch from "../my_bets/settleMatch";
import Modal from "src/components/pop-up/modal";
import Bets from "./bets";
import { ButtonBase, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import {
  changeMyBetsDates,
  clearBetsFiltered,
  getBets,
  getFilteredBets,
} from "src/redux/actions/action-bets";
import PLGrid from "src/views/my_bets/plgrid";
import BetsByCompetition from "./betsByCompetition";
import { BetListStatus, MarketPeriod, ScoreType } from "src/model/enums";
import { getDefaultDatesPageMyBets } from "src/myEnv";
import {
  EventBet,
  EventsWithBets,
  MyBetInterface,
  RangeDates,
} from "src/model/myTypes";
import { getTraders } from "src/redux/actions/action-reports";
import { changeTrader } from "src/redux/actions/action-settings";
import { optionItem, SelectForm } from "src/components/inputs/selectForm";
import { defaultEventBet } from "src/model/myTypesDefault";
import { MyBet } from "src/model/classes";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  eventIdSettle: string;
  eventIdGrid: string;
  startTime: string;
  homeCompetitor: string;
  awayCompetitor: string;
  betSelection: BetListStatus;
  firstOpenBetId: number;
  marketPeriod: MarketPeriod;
  scoreType: ScoreType;
  traderId: string;
  showAutoSettle: boolean;
  showAutoSettleConfirmation: boolean;
  isSticky: boolean;
  eventBet: EventBet;
};

class Mybets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      eventIdSettle: "",
      eventIdGrid: "",
      startTime: "",
      homeCompetitor: "",
      awayCompetitor: "",
      eventBet: defaultEventBet,
      betSelection: BetListStatus.Open,
      firstOpenBetId: -1,
      marketPeriod: MarketPeriod.FullTime,
      scoreType: ScoreType.Goals,
      traderId: "",
      showAutoSettle: false,
      showAutoSettleConfirmation: false,
      isSticky: false,
    };
    this.handleBetsByDates();
    this.props.getTraders();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      (prevProps.betsShouldUpdate !== this.props.betsShouldUpdate &&
        this.props.betsShouldUpdate === true) ||
      prevProps.betsRange !== this.props.betsRange ||
      prevProps.traderSelected !== this.props.traderSelected
    ) {
      this.handleBetsByDates();
    }
  }

  handleIsSticky = () => {
    this.setState((prevState) => ({ isSticky: !prevState.isSticky }));
  };

  handleBetsByDates = () => {
    if (this.props.betsFiltered.betIds.length > 0) {
      this.props.getFilteredBets(this.props.betsFiltered.betIds);
    } else {
      if (this.props.betsRange.isDirty) {
        const defaultRangeDates = getDefaultDatesPageMyBets();
        this.props.getBets(
          defaultRangeDates.initialDate,
          defaultRangeDates.endDate,
          this.props.traderSelected
        );
        this.props.changeMyBetsDates(defaultRangeDates);
      } else {
        this.props.getBets(
          new Date(this.props.betsRange.initialDay),
          new Date(this.props.betsRange.endDay),
          this.props.traderSelected
        );
      }
    }
  };

  handleChangeDates = (newStartDate: Date, newEndDate: Date) => {
    const rangeDates: RangeDates = {
      initialDate: newStartDate,
      endDate: newEndDate,
    };
    this.props.changeMyBetsDates(rangeDates);
  };

  handleChangeSettleEventId = (
    eventIdSettle: string,
    startTime: string,
    homeCompetitor: string,
    awayCompetitor: string,
    event: Event,
    firstOpenBetId: number
  ) => {
    event.stopPropagation(); // do not activate click bellow this click
    this.setState({
      eventIdSettle: eventIdSettle,
      startTime: startTime,
      homeCompetitor: homeCompetitor,
      awayCompetitor: awayCompetitor,
      firstOpenBetId: firstOpenBetId,
    });
  };

  handleResetSettleEventId = () => {
    this.setState({
      eventIdSettle: "",
    });
  };

  handleChangePLGridEventId = (
    eventIdGrid: string,
    startTime: string,
    homeCompetitor: string,
    awayCompetitor: string,
    event: Event,
    marketPeriod: MarketPeriod,
    scoreType: ScoreType,
    eventBet: EventBet
  ) => {
    event.stopPropagation(); // do not activate click bellow this click
    this.setState({
      eventIdGrid: eventIdGrid,
      startTime: startTime,
      homeCompetitor: homeCompetitor,
      awayCompetitor: awayCompetitor,
      marketPeriod: marketPeriod,
      scoreType: scoreType,
      eventBet: eventBet,
    });
  };

  handleResetPLGridEventId = () => {
    this.setState({
      eventIdGrid: "",
    });
  };

  handleSelectBetStatus(status: number) {
    this.setState({ betSelection: status });
  }

  handleAutoSettle = () => {
    this.setState({ showAutoSettle: true });
  };

  handleResetAutoSettle = () => {
    this.setState({ showAutoSettle: false });
  };

  handleAutoSettleConfirmation = () => {
    this.setState({ showAutoSettleConfirmation: true });
  };

  handleCancelAutoSettle = () => {
    this.setState({ showAutoSettleConfirmation: false });
  };

  sortAlphabeticallyWithIdZeroFirst = (a: optionItem, b: optionItem) => {
    if (a.id === 0 || a.id === "") {
      return -1;
    } else if (b.id === 0 || b.id === "") {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  };

  handleSelectTrader(traderId: string): void {
    this.setState({ traderId: traderId });
    this.props.changeTrader(traderId);
  }

  getFirstOpenBetId = (betIds: number[]) => {
    //Finds given bet Id's from  betDatabasse
    //returns true if there is at least one with BetStatus "NoBetStatus = 0,Executable = 1,Placed = 2"
    let found: boolean = false;
    let openBetId: number = -1;
    betIds.some((id) => {
      if (this.props.betsDatabase.hasOwnProperty(id) === true) {
        const betInterfaceObject: MyBetInterface = {
          ...this.props.betsDatabase[id],
        };
        const bet: MyBet = new MyBet(betInterfaceObject);
        found = bet.isOpen();
        if (found === true) {
          // save betId
          openBetId = id;
        }
      }
      return found;
    });

    return openBetId;
  };

  hasOpenBet(event: EventsWithBets) {
    let firstOpenBetId: number = this.getFirstOpenBetId(event.betIds);
    const eventHasOpenBets: boolean = firstOpenBetId !== -1;

    return eventHasOpenBets;
  }

  handleResetFilters = () => {
    this.props.clearBetsFiltered();
    // this.handleBetsByDates();
  };

  renderBtns() {
    return (
      <div className={styles.btnStatusWrapper}>
        <ButtonBase
          classes={{
            root: classnames(
              styles.btnStatus,
              BetListStatus.All === this.state.betSelection &&
                styles.btnStatusActive
            ),
          }}
          onClick={() => this.handleSelectBetStatus(BetListStatus.All)}
        >
          ALL BETS
        </ButtonBase>
        <ButtonBase
          classes={{
            root: classnames(
              styles.btnStatus,
              BetListStatus.Open === this.state.betSelection &&
                styles.btnStatusActive
            ),
          }}
          onClick={() => this.handleSelectBetStatus(BetListStatus.Open)}
        >
          OPEN
        </ButtonBase>
      </div>
    );
  }

  renderTrader() {
    let optionsTraders: optionItem[] = [];
    let uniqueTraders: { [key: string]: boolean } = {};
    optionsTraders.push({ id: "", name: "ALL" });
    this.props.traders.forEach((trader) => {
      // if (trader.name === "ALL") {
      //   trader.id = "";
      // }
      optionsTraders.push({ id: trader.id, name: trader.name });
      uniqueTraders[trader.id] = true;
    });
    // ensure has default item (id = 0)
    if (uniqueTraders.hasOwnProperty(0) === false) {
      // optionsTraders.push({ id: "", name: "ALL" });
    }
    // sort traders a-z
    optionsTraders.sort((a, b) => this.sortAlphabeticallyWithIdZeroFirst(a, b));

    return (
      <div className={styles.traderContainer}>
        <SelectForm
          wrapperClassName={styles.noSpaceLeft}
          changeValue={(value: string) => this.handleSelectTrader(value)}
          label={"Trader"}
          selectedOptionId={this.props.traderSelected}
          optionsArray={optionsTraders}
        ></SelectForm>
      </div>
    );
  }

  render() {
    const hasBetsFiltered = this.props.betsFiltered.betIds.length > 0;
    let eventsToSettle = 0;
    if (
      this.props.events !== undefined &&
      this.props.events !== null &&
      Object.keys(this.props.betsDatabase).length !== 0
    ) {
      eventsToSettle = Object.values(this.props.events).filter((event) => {
        try {
          return (
            this.hasOpenBet(event) ||
            this.props.betsDatabase[event.betIds[0]].event.ftGoalsHome ===
              null ||
            this.props.betsDatabase[event.betIds[0]].event.ftGoalsHome ===
              undefined ||
            this.props.betsDatabase[event.betIds[0]].event.ftGoalsAway ===
              null ||
            this.props.betsDatabase[event.betIds[0]].event.ftGoalsAway ===
              undefined
          );
        } catch (error) {
          return false;
        }
      }).length;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.mainContent}>
          {hasBetsFiltered ? (
            <div className={styles.filteredBetsContainer}>
              <div className={styles.spaceRight}>
                <p className={styles.label}>Bets filtered from:</p>
                <TextField
                  value={this.props.betsFiltered.tittle}
                  className={styles.inputText}
                  disabled
                  InputProps={{
                    classes: { underline: styles.inputProps },
                  }}
                />
              </div>
              <div className={styles.halfwidth}>
                <p className={styles.label}>Number of Bets</p>
                <TextField
                  value={this.props.betsFiltered.betIds.length}
                  className={styles.inputText}
                  disabled
                  InputProps={{
                    classes: { underline: styles.inputProps },
                  }}
                />
              </div>
              <ButtonBase
                classes={{ root: styles.resetBtn }}
                onClick={this.handleResetFilters}
              >
                Reset filters
              </ButtonBase>
            </div>
          ) : (
            <div className={styles.filtersContainer}>
              {this.renderTrader()}
              <div className={styles.dateSelection}>
                <MyDateRangePicker
                  defaultStartDate={new Date(this.props.betsRange.initialDay)}
                  defaultEndDate={new Date(this.props.betsRange.endDay)}
                  changeDates={this.handleChangeDates}
                  rootClassName={styles.dateRangePicker}
                />
              </div>
              {this.renderBtns()}
            </div>
          )}
          <Bets
            isBotsPage={false}
            betSelection={this.state.betSelection}
            changeEventSettleId={this.handleChangeSettleEventId}
            changePLGridEventId={this.handleChangePLGridEventId}
          />
          {this.state.eventIdSettle !== "" && (
            <Modal handleClickOutside={this.handleResetSettleEventId}>
              <SettleMatch
                firstOpenBetId={this.state.firstOpenBetId}
                eventId={this.state.eventIdSettle}
                eventStartTime={this.state.startTime}
                homeCompetitorName={this.state.homeCompetitor}
                awayCompetitorName={this.state.awayCompetitor}
                traderId={this.state.traderId}
                closePopup={this.handleResetSettleEventId}
              />
            </Modal>
          )}
          {this.state.eventIdGrid !== "" && (
            <Modal handleClickOutside={this.handleResetPLGridEventId}>
              <PLGrid
                eventId={this.state.eventIdGrid}
                closePopup={this.handleResetPLGridEventId}
                eventStartTime={this.state.startTime}
                homeCompetitorName={this.state.homeCompetitor}
                awayCompetitorName={this.state.awayCompetitor}
                scoreTypeSelected={this.state.scoreType}
                marketPeriodSelected={this.state.marketPeriod}
                traderId={this.state.traderId}
                eventBet={this.state.eventBet}
                isFund={false}
              />
            </Modal>
          )}
        </div>
        <div
          className={classnames(
            styles.sideRight,
            this.state.isSticky && styles.isSticky
          )}
        >
          <BetsByCompetition
            isSticky={this.state.isSticky}
            handleIsSticky={this.handleIsSticky}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betsShouldUpdate: state.bets.betsShouldUpdate,
    betsRange: state.bets.range,
    traderSelected: state.settings.traderId,
    traders: state.reports.traders,
    events: state.bets.betsByEvent,
    betsDatabase: state.bets.betsDatabase,
    betsFiltered: state.bets.betsFiltered,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBets: (initialDay: Date, endDay: Date, traderId: string) => {
      dispatch(getBets(initialDay, endDay, traderId, null));
    },
    changeMyBetsDates: (range: RangeDates) => {
      dispatch(changeMyBetsDates(range));
    },
    getTraders: () => {
      dispatch(getTraders());
    },
    changeTrader: (traderId: string) => {
      dispatch(changeTrader(traderId));
    },
    clearBetsFiltered: () => {
      dispatch(clearBetsFiltered());
    },
    getFilteredBets: (betIds: number[]) => {
      dispatch(getFilteredBets(betIds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mybets);
