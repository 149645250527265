type handleSidebarAction = {
  type: "HANDLE_SIDEBAR";
};

type closeSidebarAction = {
  type: "CLOSE_SIDEBAR";
};
type changeTraderAction = {
  type: "CHANGE_TRADER";
  payload: string;
};

export type SettingsActions =
  | handleSidebarAction
  | closeSidebarAction
  | changeTraderAction;

export function handleSidebar() {
  return {
    type: "HANDLE_SIDEBAR",
  };
}

export function closeSidebar() {
  return {
    type: "CLOSE_SIDEBAR",
  };
}
export function changeTrader(traderId: string) {
  return {
    type: "CHANGE_TRADER",
    payload: traderId,
  };
}
