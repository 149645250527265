import { MyBet } from "src/model/classes";
import { CompetitionsWithBets, EventsWithBets, MyBetInterface } from "src/model/myTypes";
import { EventDto } from "src/model/types";

type listBetsByEvent = {
  [eventReadId: number]: EventsWithBets;
};
type listBetsByCompetition = {
  [competitionReadId: number]: CompetitionsWithBets;
};

export function myBetToBetsByEvent(stateBetsByEvent: listBetsByEvent, newBet: MyBetInterface): listBetsByEvent {
  const newBetId: number = newBet.id as number;
  let newBetIdsArray: number[] = [];
  let newStateBetsByEvent = { ...stateBetsByEvent };
  // check if this event already has bets
  const eventReadId: number = newBet.event.readId;
  if (newStateBetsByEvent.hasOwnProperty(eventReadId) === true) {
    // case 1: event is already here, should ensure betId is added
    if (newStateBetsByEvent[eventReadId].betIds.indexOf(newBetId) === -1) {
      newBetIdsArray = newStateBetsByEvent[eventReadId].betIds;
      newBetIdsArray.push(newBetId);
      // save
      newStateBetsByEvent[eventReadId] = {
        ...newStateBetsByEvent[eventReadId],
        betIds: newBetIdsArray,
      };
    }
  } else {
    // case 2: event is not here
    const newEventDto = {
      id: newBet.event.id,
      readId: newBet.event.readId,
      startTime: newBet.event.startTime,
      status: newBet.event.status,
      sport: newBet.event.competition.sport,
      homeCompetitorId: newBet.event.homeCompetitor.id,
      homeCompetitorReadId: newBet.event.homeCompetitor.readId,
      homeCompetitorName: newBet.event.homeCompetitor.name,
      awayCompetitorId: newBet.event.awayCompetitor.id,
      awayCompetitorReadId: newBet.event.awayCompetitor.readId,
      awayCompetitorName: newBet.event.awayCompetitor.name,
      competitionId: newBet.event.competition.id,
      competitionReadId: newBet.event.competition.readId, //
      competitionName: newBet.event.competition.name,
      competitionType: newBet.event.competition.type,
      regionReadId: (newBet.event.competition.region.id as unknown) as string,
      regionName: newBet.event.competition.region.name,
      regionAlpha2Code: newBet.event.competition.region.alphaCode,
    };
    //save
    newStateBetsByEvent = {
      ...newStateBetsByEvent,
      [newBet.event.readId]: {
        ...newEventDto,
        betIds: [newBetId],
      },
    };
  }
  return newStateBetsByEvent;
}

export function myBetsArrayToBetsByEvent(newBetsArray: MyBetInterface[]): listBetsByEvent {
  let newStateBetsByEvent = {};
  newBetsArray.forEach((bet) => {
    newStateBetsByEvent = myBetToBetsByEvent(newStateBetsByEvent, bet);
  });
  return newStateBetsByEvent;
}

export function myBetsArrayToBetsOpenByEvent(newBetsArray: MyBetInterface[]): listBetsByEvent {
  let newStateBetsByEvent = {};
  newBetsArray
    .filter((bet) => {
      const betInstance: MyBet = new MyBet(bet);
      return betInstance.isOpen();
    })
    .forEach((bet) => {
      newStateBetsByEvent = myBetToBetsByEvent(newStateBetsByEvent, bet);
    });
  return newStateBetsByEvent;
}

export function myBetToBetsByCompetition(
  stateBetsByCompetition: listBetsByCompetition,
  newBet: MyBetInterface
): listBetsByCompetition {
  const newBetId: number = newBet.id as number;
  let newBetIdsArray: number[] = [];
  let newStateBetsByCompetition = { ...stateBetsByCompetition };
  // check if this competition already has bets
  const competitionReadId: number = newBet.event.competition.readId;
  if (newStateBetsByCompetition.hasOwnProperty(competitionReadId) === true) {
    // case 1: competition is already here, should ensure betId is added
    if (newStateBetsByCompetition[competitionReadId].betIds.indexOf(newBetId) === -1) {
      newBetIdsArray = newStateBetsByCompetition[competitionReadId].betIds;
      newBetIdsArray.push(newBetId);
      // save
      newStateBetsByCompetition[competitionReadId] = {
        ...newStateBetsByCompetition[competitionReadId],
        betIds: newBetIdsArray,
      };
    }
  } else {
    // case 2: competition is not here
    const newCompetitionBase = {
      sport: newBet.event.competition.sport,
      competitionId: newBet.event.competition.id,
      competitionReadId: newBet.event.competition.readId,
      competitionName: newBet.event.competition.name,
      competitionType: newBet.event.competition.type,
      regionReadId: newBet.event.competition.region.id,
      regionName: newBet.event.competition.region.name,
      regionAlpha2Code: newBet.event.competition.region.alphaCode,
    };
    //save
    newStateBetsByCompetition = {
      ...newStateBetsByCompetition,
      [newBet.event.competition.readId]: {
        ...newCompetitionBase,
        betIds: [newBetId],
      },
    };
  }
  return newStateBetsByCompetition;
}

export function myBetsArrayToBetsByCompetition(newBetsArray: MyBetInterface[]): listBetsByCompetition {
  let newStateBetsByCompetition = {};
  newBetsArray.forEach((bet) => {
    newStateBetsByCompetition = myBetToBetsByCompetition(newStateBetsByCompetition, bet);
  });
  return newStateBetsByCompetition;
}

type betsDatabase = {
  [id: number]: MyBetInterface;
};

export function adaptedOBetsWithStatus(eventDto: EventDto[], betsDatabase: betsDatabase): betsDatabase {
  Object.values(betsDatabase).forEach((bet) => {
    eventDto.forEach((eventDto) => {
      if (eventDto.id === bet.event.id) {
        bet.event.status = eventDto.status;
      }
    });
  });
  return betsDatabase;
}
