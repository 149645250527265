import { getInstanceBackend } from "./axios";
import { CompetitionBet } from "src/model/myTypes";
import { toast } from "react-toastify";

type AddFavoriteCompetition = {
  type: "ADD_FAVORITE_COMPETITION";
  payload: CompetitionBet;
};

type RemoveFavoriteCompetition = {
  type: "REMOVE_FAVORITE_COMPETITION";
  payload: number;
};

type FavoriteCompetition = {
  type: "FAVORITE_COMPETITION";
  payload: CompetitionBet[];
};

export type FavoriteAction =
  | FavoriteCompetition
  | RemoveFavoriteCompetition
  | AddFavoriteCompetition;

export function getFavoriteCompetition() {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/favorites",
    });
    return request
      .then((response) => {
        dispatch({
          type: "FAVORITE_COMPETITION",
          payload: response.data,
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getFavoriteCompetition", error);
      });
  };
}

export function addFavoriteCompetition(competition: CompetitionBet) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "post",
      url: "/favorites",
      data: {
        competition: { ...competition },
      },
    });
    return request
      .then((response) => {
        if (
          typeof (response.data.competitionId !== "undifined") &&
          response.data.competitionId === competition.id
        ) {
          toast.success("Add to favorite Competition");
          dispatch({
            type: "ADD_FAVORITE_COMPETITION",
            payload: competition,
          });
        } else {
          addFavoriteCompetitionFail();
        }
      })
      .catch((error) => {
        addFavoriteCompetitionFail(error);
      });
  };
}

export function removeFavoriteCompetition(competition: CompetitionBet) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "delete",
      url: "/favorites?competitionId=" + competition.id,
    });
    return request
      .then((response) => {
        if (
          typeof (response.data.competitionId !== "undifined") &&
          response.data.competitionId === competition.id
        ) {
          toast.info("Removed from Favorite Competition");
          dispatch({
            type: "REMOVE_FAVORITE_COMPETITION",
            payload: competition.readId,
          });
        } else {
          removeFavoriteCompetitionFail();
        }
      })
      .catch((error) => {
        removeFavoriteCompetitionFail(error);
      });
  };
}

function removeFavoriteCompetitionFail(error?: Error) {
  toast.error("Failed to remove favorite");
  if (typeof error !== undefined) {
    console.log("error in removeFavoriteCompetition", error);
  }
}

function addFavoriteCompetitionFail(error?: Error) {
  toast.error(
    "Failed to add favorite competition. Please check your internet connection. If the error persists, please contact us."
  );
  if (typeof error !== undefined) {
    console.log("error in addFavoriteCompetition", error);
  }
}
