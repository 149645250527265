import React, { Component } from "react";
import styles from "./listEvents.module.css";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { getEventsOverView } from "src/redux/actions/action-bets-overview";
import classnames from "classnames";
import { ButtonBase } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import OverviewListEventBets from "./listEventBets";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "src/utils/numberUtils";

type OwnProps = {
  startDate: Date | null;
  endDate: Date | null;
  competitionId: string;
  traderId: string;
};
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = { expandedEventId: string };

class OverviewListEvents extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expandedEventId: "",
    };
    this.props.getEventsOverView(
      this.props.startDate as Date,
      this.props.endDate as Date,
      this.props.competitionId,
      this.props.traderId
    );
  }

  handleClickExpandBet(eventId: string) {
    let isExpanded = this.isBetExpanded(eventId);
    if (isExpanded === true) {
      eventId = "";
    }
    this.setState({ expandedEventId: eventId });
  }

  isBetExpanded = (eventId: string): boolean => {
    return eventId !== "" && this.state.expandedEventId === eventId;
  };

  renderEvents() {
    return this.props.eventsOverview
      .sort((a, b) => {
        return b.profit - a.profit;
      })
      .map((event) => {
        const isExpanded = this.isBetExpanded(event.id);
        return (
          <div
            key={event.readId}
            className={classnames(
              styles.wrapperEvent,
              isExpanded && styles.expanded
            )}
          >
            <ButtonBase
              classes={{
                root: styles.eventLine,
              }}
              component={"div"}
              onClick={() => this.handleClickExpandBet(event.id)}
            >
              <div className={styles.event}>
                <span className={styles.home}>{event.homeCompetitor.name}</span>
                <span className={styles.spacer}>
                  {event.ftGoalsHome !== null
                    ? event.ftGoalsHome + " - " + event.ftGoalsAway
                    : "vs"}
                </span>
                <span className={styles.away}>{event.awayCompetitor.name}</span>
              </div>
              <div className={styles.eventStake}>
                {formatCurrency(event.stake)}
              </div>
              <div
                className={classnames(
                  styles.eventProfit,
                  event.profit > 0 && styles.green,
                  event.profit < 0 && styles.red
                )}
              >
                {formatCurrency(event.profit)}
              </div>
              <div
                className={classnames(
                  styles.eventMargin,
                  event.margin > 0 && styles.green,
                  event.margin < 0 && styles.red
                )}
              >
                {formatPercentage(event.margin * 100, 2)}
              </div>
              <div className={styles.eventBets}>{formatNumber(event.bets)}</div>
              <div className={styles.expandIcon}>
                {isExpanded === true ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </ButtonBase>
            {isExpanded === true && (
              <OverviewListEventBets
                eventId={event.id}
                traderId={this.props.traderId}
              />
            )}
          </div>
        );
      });
  }

  render() {
    const isReady: boolean =
      this.props.startDate !== null && this.props.endDate !== null;
    return isReady ? <div>{this.renderEvents()}</div> : null;
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    eventsOverview: state.betsOverview.events,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventsOverView: (
      starDay: Date,
      endDay: Date,
      competitionId: string,
      traderId: string
    ) => {
      dispatch(getEventsOverView(starDay, endDay, competitionId, traderId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverviewListEvents);
