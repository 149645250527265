import { MyBetInterface, MySettledMatchInterface, EventBet } from "./myTypes";
import {
  BetStatus,
  MarketPeriod,
  MarketProvider,
  MarketSelectionType,
  MarketType,
  ReportMarketPeriod,
  ReportScoreType,
  ScoreType,
  StatsChartType,
} from "./enums";

export class MyBet implements MyBetInterface {
  id: number;
  bookieBetErrorCode?: string | null
  bookieBetId?: number | null;
  bookieBetProfit?: number | null; 
  bookieBetTextStatus?: string | null;
  bookieMatcheStake?: number | null;
  bookieMatchedOdd?: number | null;
  marketPeriodSelected: MarketPeriod;
  scoreTypeSelected: ScoreType;
  marketTypeSelected: MarketType;
  marketSelectionTypeSelected: MarketSelectionType;
  bookie: MarketProvider;
  line: number;
  price: number;
  stake: number;
  value: number;
  placedDate: Date;
  settledDate?: Date;
  status: BetStatus;
  profit?: number;
  userId?: string;
  event: EventBet;
  // competition: MyCompetitionBase;

  constructor(params: MyBetInterface) {
    let {
      bookieBetErrorCode,
      bookieBetId,
      bookieBetProfit, 
      bookieBetTextStatus,
      bookieMatcheStake,
      bookieMatchedOdd,
      marketPeriodSelected,
      scoreTypeSelected,
      marketTypeSelected,
      marketSelectionTypeSelected,
      bookie,
      line,
      price,
      stake,
      value,
      placedDate,
      event,
      userId,
      status = BetStatus.Executable,
      id = 0,
      settledDate,
      profit,
    } = params;

    
    this.bookieBetErrorCode = bookieBetErrorCode;
    this.bookieBetId = bookieBetId;
    this.bookieBetProfit = bookieBetProfit; 
    this.bookieBetTextStatus = bookieBetTextStatus;
    this.bookieMatcheStake = bookieMatcheStake;
    this.bookieMatchedOdd = bookieMatchedOdd;
    this.marketPeriodSelected = marketPeriodSelected;
    this.scoreTypeSelected = scoreTypeSelected;
    this.marketTypeSelected = marketTypeSelected;
    this.marketSelectionTypeSelected = marketSelectionTypeSelected;
    this.bookie = Number(bookie);
    this.line = Number(line);
    this.price = Number(price);
    this.status = status;
    this.stake = Number(stake);
    this.value = Number(value);
    this.placedDate = placedDate;
    this.event = event;
    this.id = id;
    this.settledDate = settledDate;
    this.userId = userId;
    this.profit = profit;
  }

  isOpen() {
    //Finds given bet Id's from  betDatabasse
    //returns true if there is at least one with BetStatus "NoBetStatus = 0,Executable = 1,Placed = 2"
    let response: boolean = false;
    if (
      this.status === BetStatus.NoBetStatus ||
      this.status === BetStatus.Executable ||
      this.status === BetStatus.Placed
    ) {
      response = true;

      return response;
    }
    return response;
  }
}

export class MySettledMatch implements MySettledMatchInterface {
  id: string;
  ftGoalsHome: number;
  ftGoalsAway: number;
  htGoalsHome?: number;
  htGoalsAway?: number;
  ftCornersHome?: number;
  ftCornersAway?: number;
  htCornersHome?: number;
  htCornersAway?: number;
  ftRedCardsHome?: number;
  ftRedCardsAway?: number;
  htRedCardsHome?: number;
  htRedCardsAway?: number;

  constructor(
    id: string,
    ftGoalsHome: number,
    ftGoalsAway: number,
    htGoalsHome?: number,
    htGoalsAway?: number,
    ftCornersHome?: number,
    ftCornersAway?: number,
    htCornersHome?: number,
    htCornersAway?: number,
    ftRedCardsHome?: number,
    ftRedCardsAway?: number,
    htRedCardsHome?: number,
    htRedCardsAway?: number
  ) {
    this.id = id;
    this.ftGoalsHome = ftGoalsHome;
    this.ftGoalsAway = ftGoalsAway;
    this.htGoalsHome = htGoalsHome;
    this.htGoalsAway = htGoalsAway;
    this.ftCornersHome = ftCornersHome;
    this.ftCornersAway = ftCornersAway;
    this.htCornersHome = htCornersHome;
    this.htCornersAway = htCornersAway;
    this.ftRedCardsHome = ftRedCardsHome;
    this.ftRedCardsAway = ftRedCardsAway;
    this.htRedCardsHome = htRedCardsHome;
    this.htRedCardsAway = htRedCardsAway;
  }
}

export class ReportsObject {
  traderId: string;
  regionId: number;
  competitionId: string;
  initDay: Date;
  endDay: Date;
  marketPeriod: number;
  scoreType: number;
  type: number;
  bookie?: number;

  constructor(
    traderId: string,
    regionId: number,
    competitionId: string,
    initDay: Date,
    endDay: Date,
    marketPeriod: number,
    scoreType: number,
    type: number,
    bookie: number
  ) {
    this.traderId = traderId;
    this.regionId = regionId;
    this.competitionId = competitionId;
    this.initDay = initDay;
    this.endDay = endDay;
    this.marketPeriod = marketPeriod;
    this.scoreType = scoreType;
    this.type = type;
    this.bookie = bookie;
  }
}

interface IStatsChartParameters {
  initDay: Date;
  endDay: Date;
  type: StatsChartType;
  traderId?: string;
  regionId?: number;
  competitionId?: string;
  marketPeriod?: ReportMarketPeriod;
  scoreType?: ReportScoreType;
}

export class StatsChartParameters {
  initDay: Date;
  endDay: Date;
  type: StatsChartType;
  traderId: string;
  regionId: number;
  competitionId: string;
  marketPeriod: ReportMarketPeriod;
  scoreType: ReportScoreType;

  constructor(params: IStatsChartParameters) {
    let {
      initDay,
      endDay,
      type,
      traderId = "",
      regionId = 0,
      competitionId = "",
      marketPeriod = ReportMarketPeriod.All,
      scoreType = ReportScoreType.All,
    } = params;

    this.initDay = initDay;
    this.endDay = endDay;
    this.type = type;
    this.traderId = traderId;
    this.regionId = regionId;
    this.competitionId = competitionId;
    this.marketPeriod = marketPeriod;
    this.scoreType = scoreType;
  }
}
