import { getInstanceBackend } from "./axios";
import { EventDto } from "src/model/types";
import { toast } from "react-toastify";
import { formatDay } from "src/utils/dateUtils";

type IsLoadingEventsAction = {
  type: "IS_LOADING_EVENTS";
};
type IsLoadingEventsFinishedAction = {
  type: "IS_LOADING_EVENTS_FINISHED";
};
type GetEventsByDayAction = {
  type: "GET_EVENTS_BY_DAY";
  payload: {
    response: EventDto[];
    inputDay: Date;
    isFirst: boolean;
    isFinished: boolean;
  };
};
export type GetEventsStatusAction = {
  type: "GET_EVENTS_STATUS";
  payload: {
    response: EventDto[];
    isFirst: boolean;
    isFinished: boolean;
  };
};

export type KnownAction = IsLoadingEventsAction | IsLoadingEventsFinishedAction | GetEventsByDayAction; // | action1 | action2 | ...

export type Pagination = {
  pageSize: number;
  fetchedCount: number;
};

export const defaultPagination: Pagination = {
  pageSize: 200, //Number of demanded elements per request.
  fetchedCount: 0, //Starting position of requested list.
};

export function getEventsByDay(day: Date, pagination?: Pagination) {
  return function action(dispatch: any) {
    dispatch(isLoadingEvents());
    if (typeof pagination === "undefined") {
      pagination = defaultPagination;
    }
    fetchbyPageEventsByDay(day, pagination.pageSize, pagination.fetchedCount, dispatch);
  };
}

const fetchbyPageEventsByDay = function (day: Date, pageSize: number, fetchedCount: number, dispatch: any) {
  let safeInitialDay = new Date(day);
  safeInitialDay.setHours(0, 0, 0, 0);
  let safeEndDay = new Date(day);
  safeEndDay.setHours(23, 59, 59, 999);
  getInstanceBackend()({
    method: "get",
    url:
      "/matches?fromDate=" +
      safeInitialDay.toISOString() +
      "&toDate=" +
      safeEndDay.toISOString() +
      "&PageSize=" +
      pageSize +
      "&FetchedCount=" +
      fetchedCount,
  })
    .then((response) => {
      let isFirst = fetchedCount === 0;
      let isFinished = true;
      if (isValidGetEventsResponseData(response, safeInitialDay, safeEndDay) === false) {
        dispatch(isLoadingEventsFinished());
        return; // stop
      }

      // step 1: check if need extra requests (if need pagination)
      if (response.data.data.length === pageSize) {
        isFinished = false;
        fetchedCount = fetchedCount + pageSize;
        fetchbyPageEventsByDay(day, pageSize, fetchedCount, dispatch);
      }

      // step 2: dispatch received data
      dispatch({
        type: "GET_EVENTS_BY_DAY",
        payload: {
          response: response.data.data,
          inputDay: day,
          isFirst: isFirst,
          isFinished: isFinished,
        },
      });
    })
    .catch((error) => {
      // do nothing
      console.log("error in GetEventsByDay", error);
      toast.error("Something went wrong when trying to get Events list.");
      dispatch(isLoadingEventsFinished());
    });
  return {};
};

export function getEvents(fromDate: Date, toDate: Date) {
  return function action(dispatch: any) {
    // ensure all hours of end day is included in response
    const pagination = defaultPagination;
    fetchbyPageEvents(fromDate, toDate, pagination.pageSize, pagination.fetchedCount, dispatch);
  };
}

const fetchbyPageEvents = function (
  fromDate: Date,
  toDate: Date,
  pageSize: number,
  fetchedCount: number,
  dispatch: any
) {
  let safeInitialDay = new Date(fromDate);
  safeInitialDay.setHours(0, 0, 0, 0);
  let safeEndDay = new Date(toDate);
  safeEndDay.setHours(23, 59, 59, 999);
  getInstanceBackend()({
    method: "get",
    url:
      "/matches?fromDate=" +
      safeInitialDay.toISOString() +
      "&toDate=" +
      safeEndDay.toISOString() +
      "&PageSize=" +
      pageSize +
      "&FetchedCount=" +
      fetchedCount,
  })
    .then((response) => {
      let isFirst = fetchedCount === 0;
      let isFinished = true;
      if (isValidGetEventsResponseData(response, safeInitialDay, safeEndDay) === false) {
        return; // stop
      }

      // step 1: check if need extra requests (if need pagination)
      if (response.data.data.length === pageSize) {
        isFinished = false;
        fetchedCount = fetchedCount + pageSize;
        fetchbyPageEvents(fromDate, toDate, pageSize, fetchedCount, dispatch);
      }

      // step 2: dispatch received data
      dispatch({
        type: "GET_EVENTS_STATUS",
        payload: {
          response: response.data.data,
          // inputDay: day,
          isFirst: isFirst,
          isFinished: isFinished,
        },
      });
    })
    .catch((error) => {
      // do nothing
      console.log("error in GetEventsByDay", error);
      toast.error(
        "Something went wrong when trying to get Events from " +
          safeInitialDay.toISOString() +
          " to " +
          safeEndDay.toISOString()
      );
    });
  return {};
};

function isLoadingEvents() {
  return {
    type: "IS_LOADING_EVENTS",
  };
}

function isLoadingEventsFinished() {
  return {
    type: "IS_LOADING_EVENTS_FINISHED",
  };
}

export function isValidGetEventsResponseData(response: any, safeInitialDay: Date, safeEndDay: Date): boolean {
  // step 0: check if has "data" array (protection introduced in 05/08/2022)
  if (!response.data.data) {
    const startDayString = formatDay(safeInitialDay);
    const endDayString = formatDay(safeEndDay);
    let dateInformation = "";
    if (startDayString === endDayString) {
      dateInformation = "in " + startDayString;
    } else {
      dateInformation = "from date " + startDayString + " to " + endDayString;
    }
    toast.warning("No events found " + dateInformation + ".");
    return false;
  } else {
    return true;
  }
}
