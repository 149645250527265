import { StatsChartReceived, StatsOverview } from "src/model/myTypes";
import { StatsChartType } from "src/model/enums";
import { getInstanceBackend } from "./axios";
import { Dispatch } from "redux";

type getStatsOverview = {
  type: "GET_STATS_OVERVIEW";
  payload: StatsOverview;
};

type getStatsChart = {
  type: "GET_STATS_CHART";
  payload: { data: StatsChartReceived[]; numberOfDays: number; type: number };
};

export type KnownAction = getStatsOverview | getStatsChart; // | action1 | action2 | ...

export function getBetAccountingMainStats(traderId: string) {
  // first call StatsOverview, and only when get the response, dispatch the call of two StatsChart (type 1 and 2)
  // this is to avoid backend duplication of users creation (on first time login, via open id)
  return function action(dispatch: Dispatch<any>) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/StatsOverview?traderId=" + traderId,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_STATS_OVERVIEW",
          payload: response.data,
        });
        // call the 2 types of StatsChart for homepage
        dispatch(getStatsChart(StatsChartType.BetsChart, traderId));
        dispatch(getStatsChart(StatsChartType.ProfitLossChart, traderId));
      })
      .catch((error) => {
        // do nothing
        console.log("error in getStatsOverview", error);
      });
  };
}

function getStatsChart(type: StatsChartType, traderId: string = "") {
  // set default dates for calling StatsChart: 30 days before today
  const now = new Date();
  // ensure all hours of end day is included in response
  let safeEndDay = new Date(now);
  safeEndDay.setHours(23, 59, 59, 999);
  const numberOfDays = 30;
  const initDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - numberOfDays
  );
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url:
        "/StatsChart?initDay=" +
        initDay.toISOString() +
        "&endDay=" +
        safeEndDay.toISOString() +
        "&type=" +
        type +
        "&traderId=" +
        traderId,
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_STATS_CHART",
          payload: {
            data: response.data,
            numberOfDays: numberOfDays,
            type: type,
          },
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getProfitLossGridsByMatch", error);
      });
  };
}
