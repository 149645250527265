import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import styles from "./MainSidebar.module.css";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ExploreIcon from "@material-ui/icons/Explore";
import AndroidIcon from "@material-ui/icons/Android";
import ButtonBase from "@material-ui/core/ButtonBase";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import classnames from "classnames";
import { connect } from "react-redux";
import { handleSidebar, closeSidebar } from "src/redux/actions/action-settings";
import ProtectedResource from "src/components/user-permissions/protected-resource";

type SidebarItem = {
  link: string;
  isDisabled: boolean;
  iconRef: React.ReactNode;
  name: string;
  key?: string;
};

const ITEMS: SidebarItem[] = [
  {
    link: "/dashboard/bets-accounting",
    isDisabled: false,
    iconRef: <ShowChartIcon />,
    name: "Bets Accounting",
    key: "betsAccounting",
  },
  {
    link: "/dashboard/value-viewer",
    isDisabled: false,
    iconRef: <ExploreIcon />,
    name: "Value Viewer",
    key: "valueViewer",
  },
  {
    link: "/dashboard/bets-bot",
    isDisabled: false,
    iconRef: <AndroidIcon />,
    name: "Bets Bot",
    key: "betsBot",
  },
];

type Props = RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class MainSidebar extends Component<Props> {
  render() {
    return (
      <div
        className={classnames(
          styles.mainSidebar,
          this.props.isSidebarOpen && styles.active
        )}
      >
        <div
          className={styles.overlay}
          onClick={this.props.handleSidebar}
        ></div>
        <div className={styles.stickyItems}>
          {ITEMS.map((item, index) => (
            <ProtectedResource
              componentName={"MainSidebar"}
              resourceKey={item.key as string}
              key={index}
            >
              <ButtonBase
                key={index}
                classes={{ root: styles.item }}
                onClick={this.props.closeSidebar}
                disabled={item.isDisabled}
                component={NavLink}
                // exact
                to={item.link}
                activeClassName={styles.isActive}
              >
                {item.iconRef}
                <div className={styles.name}>{item.name}</div>
              </ButtonBase>
            </ProtectedResource>
          ))}
        </div>
        <div className={styles.secondarySidebar}></div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    isSidebarOpen: state.settings.isSidebarOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleSidebar: () => {
      dispatch(handleSidebar());
    },
    closeSidebar: () => {
      dispatch(closeSidebar());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainSidebar)
);
