import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./competitionLine.module.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  CompetitionBet,
  MyCompetition,
  MyCompetitionBase,
  ValueViewerEvent,
} from "src/model/myTypes";
import CompetitionEvents from "./competitionEvents";
import { flagConverter } from "src/utils/flag-converter";
import {
  addFavoriteCompetition,
  removeFavoriteCompetition,
} from "src/redux/actions/action-competitions";
import { MarketPeriod, ModelType, ScoreType } from "src/model/enums";

type ownPropsType = {
  competition: MyCompetition;
  isFavorite: boolean;
  isValueViewer: boolean;
  isBotOddsViewer?: boolean;
  modelTypeId?: ModelType; // needed only if isValueViewer===true
  scoreTypeId?: ScoreType; // needed only if isValueViewer===true
  marketPeriodId?: MarketPeriod; // needed only if isValueViewer===true
  callbackExpandCollapseCompetition?: (
    modelTypeId: number,
    eventIdsArray: string[],
    willBeExpanded: boolean
  ) => void;
};

type Props = ownPropsType &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  isExpanded: boolean;
};

class CompetitionLine extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // initially set expanded/collapsed according to if is favorite competition or not
      isExpanded: this.props.isFavorite,
    };
  }

  componentDidMount() {
    if (this.state.isExpanded === true) {
      // only if first time is expanded
      // handle callback expand/collapse competition
      this.handleCallbackExpandCollapseCompetition(
        Number(this.props.modelTypeId),
        this.props.competition.eventIds,
        true
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isFavorite !== this.props.isFavorite) {
      // sync local state isExpanded
      this.handleClickExpandCompetition(this.props.isFavorite);
    }
  }

  componentWillUnmount() {
    if (this.state.isExpanded === true) {
      // only if was expanded
      // handle callback expand/collapse competition
      this.handleCallbackExpandCollapseCompetition(
        Number(this.props.modelTypeId),
        this.props.competition.eventIds,
        false
      );
    }
  }

  handleStopRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation(); // do not activate click bellow this click
  };

  handleClickFavorite = (
    competition: CompetitionBet,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation(); // do not activate click bellow this click
    if (this.props.isFavorite === true) {
      this.props.removeFavoriteCompetition(competition);
    } else {
      this.props.addFavoriteCompetition(competition);
    }
  };

  handleClickExpandCompetition = (
    forceNewIsExpanded?: boolean,
    forceModelTypeId?: number
  ) => {
    let nextIsExpanded = !this.state.isExpanded;
    if (typeof forceNewIsExpanded !== "undefined") {
      nextIsExpanded = forceNewIsExpanded;
    }
    let modelTypeId = Number(this.props.modelTypeId);
    if (typeof forceModelTypeId !== "undefined") {
      modelTypeId = forceModelTypeId;
    }
    // handle callback expand/collapse competiton
    this.handleCallbackExpandCollapseCompetition(
      Number(modelTypeId),
      this.props.competition.eventIds,
      nextIsExpanded
    );
    // change expand/collapse
    this.setState({
      isExpanded: nextIsExpanded,
    });
  };

  handleCallbackExpandCollapseCompetition(
    modelTypeId: number,
    eventIdsArray: string[],
    willBeExpanded: boolean
  ) {
    if (this.props.callbackExpandCollapseCompetition !== undefined) {
      this.props.callbackExpandCollapseCompetition(
        modelTypeId,
        eventIdsArray,
        willBeExpanded
      );
    }
  }

  renderEvents(competition: MyCompetition, competitionBase: MyCompetitionBase) {
    if (this.state.isExpanded) {
      // case 2: is other view (example: bets-accounting > add bets)
      return (
        <CompetitionEvents
          competitionReadId={competition.competitionReadId}
          eventsArray={competition.events}
          competitionBase={competitionBase}
        />
      );
    } else return null;
  }

  render() {
    let competition = this.props.competition;
    const competitionBet: CompetitionBet = {
      id: competition.competitionId,
      name: competition.competitionName,
      readId: competition.competitionReadId,
      type: competition.competitionType,
      sport: competition.sport,
      regionId: competition.regionReadId,
      region: {
        id: competition.regionReadId,
        name: competition.regionName,
        alphaCode: competition.regionAlpha2Code,
      },
    };
    const competitionBase: MyCompetitionBase = {
      sport: competition.sport,
      competitionId: competition.competitionId,
      competitionReadId: competition.competitionReadId,
      competitionName: competition.competitionName,
      competitionType: competition.competitionType,
      regionReadId: competition.regionReadId,
      regionName: competition.regionName,
      regionAlpha2Code: competition.regionAlpha2Code,
    };

    return (
      <div
        key={competition.competitionReadId}
        className={styles.wrapperCompetition}
      >
        <ButtonBase
          classes={{
            root: classnames(
              styles.competition,
              this.state.isExpanded && styles.expanded,
              this.props.activeCompetition === competition.competitionReadId && styles.isSticky
            ),
          }}
          component={"div"}
          onClick={() => this.handleClickExpandCompetition()}
        >
          <div className={styles.region}>
            <div className={styles.flag}>
              {flagConverter(competition.regionAlpha2Code as string)}
            </div>
            {competition.regionName}
          </div>
          <ButtonBase
            classes={{ root: styles.favoriteIcon }}
            onMouseDown={this.handleStopRipple}
            onClick={(event) => this.handleClickFavorite(competitionBet, event)}
          >
            {this.props.isFavorite ? <StarIcon /> : <StarBorderIcon />}
          </ButtonBase>
          <div className={styles.competitionName}>
            {competition.competitionName}
          </div>
          <div className={styles.expandIcon}>
            {this.state.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </ButtonBase>
        {this.renderEvents(competition, competitionBase)}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE, ownProps: ownPropsType) => {
  return {
    activeCompetition: -1
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addFavoriteCompetition: (competition: CompetitionBet) => {
      dispatch(addFavoriteCompetition(competition));
    },
    removeFavoriteCompetition: (competition: CompetitionBet) => {
      dispatch(removeFavoriteCompetition(competition));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionLine);
