import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./dailyTable.module.css";
import classnames from "classnames";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { formatDay } from "src/utils/dateUtils";
import { formatNumber, formatPercentage } from "src/utils/numberUtils";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class DailyTable extends Component<Props> {
  renderLines() {
    return this.props.fundChangeTable.map((each, i) => {
      const styleProfitLoss =
        each.profitLoss < 0
          ? styles.red
          : each.profitLoss > 0
          ? styles.green
          : "";
      const styleCumulative =
        each.profitLossCumulative < 0
          ? styles.red
          : each.profitLossCumulative > 0
          ? styles.green
          : "";

      return (
        <div key={i} className={styles.tableLine}>
          <div className={classnames(styles.cell, styles.left)}>
            {formatDay(new Date(each.day))}
          </div>
          <div className={classnames(styles.cell, styleProfitLoss)}>
            {formatPercentage(
              ((each.profitLoss as number) / Math.abs(each.bankRoll)) * 100,
              2
            )}
          </div>
          <div className={classnames(styles.cell, styleCumulative)}>
            {formatPercentage(
              ((each.profitLossCumulative as number) /
                Math.abs(each.bankRoll)) *
                100,
              2
            )}
          </div>
          <div className={styles.cell}>{formatNumber(each.bankRoll, 0)}</div>
          <div className={styles.cell}>{formatNumber(each.index, 1)}</div>
        </div>
      );
    });
  }

  renderLoading() {
    return (
      <div className={styles.loadingParent}>
        <div className={styles.loadingContainer}>
          <div className={"loader-circle"}></div>
        </div>
      </div>
    );
  }

  render() {
    const hasData =
      typeof this.props.fundChangeTable !== "undefined" &&
      this.props.fundChangeTable.length > 0;

    return (
      <div className={styles.wrapperTable}>
        <div className={styles.tableHead}>
          <div className={classnames(styles.cell, styles.left)}>Date</div>
          <div className={styles.cell}>Profit/Loss</div>
          <div className={styles.cell}>Cumulative</div>
          <div className={styles.cell}>Bankroll</div>
          <div className={styles.cell}>Index</div>
        </div>
        {hasData ? this.renderLines() : this.renderLoading()}
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    fundChangeTable: state.fund.fundChangeTable,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyTable);
