import { EnumHelper } from "src/utils/enum-helper";

export type ResourcePermission = { [key: string]: UserRoles[] };

export enum UserRoles {
  Admin = 1,
  Visitor = 2,
  Accountant = 3,
}

export function buildRoles(roles: string | string[]): UserRoles[] {
  let roleList: UserRoles[] = [];
  if (typeof roles === "string") {
    // convert role to enumerator
    roleList.push(EnumHelper.getIdFromName(UserRoles, roles));
  } else if (Array.isArray(roles) === true) {
    // convert all roles to enumerator
    (roles as []).forEach((roleString: string) => {
      roleList.push(EnumHelper.getIdFromName(UserRoles, roleString));
    });
  }
  return roleList;
}
