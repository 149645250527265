import React, { Component } from "react";
import { buildRoles, UserRoles } from "./check-permissions";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { ResourcePermission } from "./check-permissions";

// grouped by component name
const RESOURCE_PERMISSIONS: { [componentName: string]: ResourcePermission } = {
  FundIndex: {
    startIndex: [UserRoles.Admin],
    startBankroll: [UserRoles.Admin],
  },
  BetInfo: {
    columnTrader: [UserRoles.Admin],
    columnBookie: [UserRoles.Admin],
    columnValue: [UserRoles.Admin],
  },
  TraderRegionCompetition: {
    traderFilter: [UserRoles.Admin],
  },
  MainTabs: {
    betsAccounting_main: [UserRoles.Admin, UserRoles.Accountant],
    betsAccounting_addBets: [UserRoles.Admin],
    betsAccounting_myBets: [UserRoles.Admin, UserRoles.Accountant],
    betsAccounting_reports: [UserRoles.Admin, UserRoles.Accountant],
    betsAccounting_fund: [UserRoles.Admin, UserRoles.Visitor],
    valueViewer_byCompetitions: [UserRoles.Admin],
    betsBot_bots: [UserRoles.Admin],
    betsBot_bets: [UserRoles.Admin],
    betsBot_oddsViewer: [UserRoles.Admin],
    betsBot_settings: [UserRoles.Admin],
  },
  MainSidebar: {
    betsAccounting: [UserRoles.Admin, UserRoles.Visitor, UserRoles.Accountant],
    valueViewer: [UserRoles.Admin],
    betsBot: [UserRoles.Admin],
  },
  MyBets: {
    autoSettleButton: [UserRoles.Admin],
  },
  Bets: {
    settleButton: [UserRoles.Admin],
  },
};

type ownProps = {
  componentName: string;
  resourceKey: string;
  children?: React.ReactNode;
};
type Props = ownProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

class ProtectedResource extends Component<Props> {
  render() {
    let allow = false;
    if (
      RESOURCE_PERMISSIONS.hasOwnProperty(this.props.componentName) === false
    ) {
      // component name is not defined on permissions variable, should allow
      allow = true;
    } else {
      if (
        RESOURCE_PERMISSIONS[this.props.componentName].hasOwnProperty(
          this.props.resourceKey
        ) === false
      ) {
        // resource name is not defined on permissions variable, should allow
        allow = true;
      }
    }

    if (
      allow === false &&
      typeof this.props.user !== "undefined" &&
      this.props.user.hasOwnProperty("profile")
    ) {
      // if user does not have "roles" property, should allow everything
      if (this.props.user.profile.hasOwnProperty("roles") === false) {
        allow = true;
      } else {
        // build roles array
        const roles = buildRoles(this.props.user.profile.roles);
        // check roles: if one of the roles has permission, should allow
        roles.some((role) => {
          const hasPermission = RESOURCE_PERMISSIONS[this.props.componentName][
            this.props.resourceKey
          ].includes(role);
          if (hasPermission === true) {
            allow = true;
            return true;
          } else {
            return false;
          }
        });
      }
    }

    if (allow === true) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    } else {
      return null;
    }
  }
}

function mapStateToProps(state: typeof INITIAL_STATE) {
  return {
    user: state.oidc.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedResource);
