export function arraysMatch(
  arr1: Array<string | number>,
  arr2: Array<string | number>
): boolean {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
}

/**
 * source/credits http://www.frontcoded.com/splitting-javascript-array-into-chunks.html
 * usage example:
 * let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
 * const groupedArr = createGroupedArray(arr, 4);
 * result: "[[1,2,3,4],[5,6,7,8],[9,10,11,12],[13,14]]"
 *
 * @param {*} arr
 * @param {*} chunkSize
 */
export const createGroupedArray = (arr: Array<any>, chunkSize: number) => {
  let groups = [];
  let i = 0;
  for (i = 0; i < arr.length; i += chunkSize) {
    groups.push(arr.slice(i, i + chunkSize));
  }
  return groups;
};
