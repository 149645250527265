import { Action, Reducer } from "redux";
import { InitialStatsOverview } from "./initial-state";
import { KnownAction } from "../actions/action-main";
import { StatsOverview } from "src/model/myTypes";
import { formatStatsOverview } from "../mappers/mapper-margin";
import { UserActions } from "../actions/action-user";

const unloadedState: StatsOverview = InitialStatsOverview;

export const reducer: Reducer<StatsOverview> = (
  state: StatsOverview | undefined,
  incomingAction: Action
): StatsOverview => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as KnownAction | UserActions;
  switch (action.type) {
    case "GET_STATS_OVERVIEW":
      const statsWithMargin = formatStatsOverview(action.payload);
      return {
        ...statsWithMargin,
      };
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
