import { StatsOverview, ViewTypeReports } from "src/model/myTypes";

export function formatStatsOverview(
  statsOverview: StatsOverview
): StatsOverview {
  let statsWithMargin: StatsOverview = {
    stake: statsOverview.stake,
    profitLoss: statsOverview.profitLoss,
    margin: {
      today: (statsOverview.profitLoss.today / statsOverview.stake.today) * 100,
      yesterday:
        (statsOverview.profitLoss.yesterday / statsOverview.stake.yesterday) *
        100,
      thisWeek:
        (statsOverview.profitLoss.thisWeek / statsOverview.stake.thisWeek) *
        100,
      lastWeek:
        (statsOverview.profitLoss.lastWeek / statsOverview.stake.lastWeek) *
        100,
      thisMonth:
        (statsOverview.profitLoss.thisMonth / statsOverview.stake.thisMonth) *
        100,
      lastMonth:
        (statsOverview.profitLoss.lastMonth / statsOverview.stake.lastMonth) *
        100,
      thisYear:
        (statsOverview.profitLoss.thisYear / statsOverview.stake.thisYear) *
        100,
      overall:
        (statsOverview.profitLoss.overall / statsOverview.stake.overall) * 100,
    },
    bets: statsOverview.bets,
    configs: statsOverview.configs,
  };

  return statsWithMargin;
}

export function formatReportsData(reportsData: ViewTypeReports[]) {
  let reportDataWithMargin = reportsData;

  for (let index = 0; index < reportsData.length; index++) {
    reportDataWithMargin[index] = {
      name: reportsData[index].name,
      stake: reportsData[index].stake,
      profitLoss: reportsData[index].profitLoss,
      margin: (reportsData[index].profitLoss / reportsData[index].stake) * 100,
      bets: reportsData[index].bets,
      betIds: reportsData[index].betIds,
    };
  }
  return reportDataWithMargin;
}
