import React, { Component } from "react";
import styles from "./traderRegionCompetition.module.css";
import { connect } from "react-redux";
import classnames from "classnames";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { SelectForm, optionItem } from "src/components/inputs/selectForm";
import { CompetitionBet } from "src/model/myTypes";
import {
  getTraders,
  getTradersCompetition,
} from "src/redux/actions/action-reports";
import ProtectedResource from "../../../components/user-permissions/protected-resource";

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    handleChangeValueSelected: Function;
    selectedTraderId: string;
    selectedRegionReadId: number;
    selectedCompetitionReadId: string;
    wrapperClassName?: string;
  };
type State = {
  optionsCompetitions: optionItem[];
  optionsRegion: optionItem[];
};

const InitialState: State = {
  optionsCompetitions: [{ id: "", name: "ALL" }],
  optionsRegion: [{ id: 0, name: "ALL" }],
};

type optionsValuesTrader = {
  optionsTraders: optionItem[];
};

class TraderRegionCompetition extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...InitialState };
    this.props.getTraders();
  }

  componentDidMount() {
    this.props.getTradersCompetition("");
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.tradersWithCompetitions !== this.props.tradersWithCompetitions
    ) {
      this.getOptionsValuesCompetitionsRegion(this.props.selectedTraderId);
    }
    if (prevProps.selectedRegionReadId !== this.props.selectedRegionReadId) {
      this.getFilteredOptionsValuesCompetition();
    }
  }

  handleChangeValueTrader = (newValue: string, key: string) => {
    this.props.handleChangeValueSelected(newValue, key);
    this.props.getTradersCompetition(
      key === "traderId" && newValue === "ALL" ? "" : newValue
    );
  };

  handleChangeValueCompetitionsRegion = (newValue: string, key: string) => {
    this.props.handleChangeValueSelected(newValue, key);
  };

  sortAlphabeticallyWithIdZeroFirst = (a: optionItem, b: optionItem) => {
    if (a.id === 0 || a.id === "" || a.id === "ALL") {
      return -1;
    } else if (b.id === 0 || b.id === "" || b.id === "ALL") {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  };

  getOptionsValuesTrader(): optionsValuesTrader {
    // Step 1: build option values for dropdown "trader"
    let optionsTraders: optionItem[] = [];
    let uniqueTraders: { [key: string]: boolean } = {};
    optionsTraders.push({ id: "", name: "ALL" });
    this.props.traders.forEach((trader) => {
      optionsTraders.push({ id: trader.id, name: trader.name });
      uniqueTraders[trader.id] = true;
    });
    // ensure has default item (id = 0)
    // if (uniqueTraders.hasOwnProperty(0) === false) {
    //   // optionsTraders.push({ id: "", name: "ALL" });
    // }
    // sort traders a-z
    optionsTraders.sort((a, b) => this.sortAlphabeticallyWithIdZeroFirst(a, b));
    return {
      optionsTraders: optionsTraders,
    };
  }

  getOptionsValuesCompetitionsRegion(traderId: string) {
    let optionsRegions: optionItem[] = []; // default item
    let optionsCompetitions: optionItem[] = []; // default item
    if (traderId === "") {
      traderId = "ALL";
    }
    if (typeof this.props.tradersWithCompetitions[traderId] === "undefined") {
      optionsCompetitions = [{ id: "", name: "ALL" }];
      optionsRegions = [{ id: 0, name: "ALL" }];
    } else {
      let possibleCompetitions: CompetitionBet[] = [];
      possibleCompetitions = this.props.tradersWithCompetitions[traderId]
        .competitions;
      if (possibleCompetitions.length === 0) {
        optionsCompetitions = [{ id: "", name: "ALL" }];
        optionsRegions = [{ id: 0, name: "ALL" }];
      } else {
        // Step 2.1: with relevant competitions, build option values for dropdown "region"
        optionsRegions = [{ id: 0, name: "ALL" }]; // default item
        let uniqueRegions: { [key: number]: boolean } = { 0: true };
        optionsCompetitions = [{ id: "", name: "ALL" }]; // default item
        possibleCompetitions.forEach((competition) => {
          // add region, if not already
          if (uniqueRegions.hasOwnProperty(competition.region.id) === false) {
            uniqueRegions[competition.region.id] = true;
            optionsRegions.push({
              id: competition.region.id,
              name: competition.region.name as string,
            });
          }
        });
        // sort regions a-z
        optionsRegions.sort((a, b) =>
          this.sortAlphabeticallyWithIdZeroFirst(a, b)
        );
        // sort competitions a-z
        optionsCompetitions.sort((a, b) =>
          this.sortAlphabeticallyWithIdZeroFirst(a, b)
        );
      }
    }

    let newState = { ...this.state };
    newState.optionsCompetitions = optionsCompetitions;
    newState.optionsRegion = optionsRegions;
    this.setState(newState);
  }

  getFilteredOptionsValuesCompetition() {
    let uniqueCompetitions: { [key: number]: boolean } = { 0: true };
    let optionsCompetitions: optionItem[] = []; // default item
    optionsCompetitions = [{ id: "", name: "ALL" }]; // default item
    let traderId = this.props.selectedTraderId;
    if (traderId === "") traderId = "ALL";
    if (typeof this.props.tradersWithCompetitions[traderId] === "undefined") {
      optionsCompetitions = [{ id: "", name: "ALL" }];
    } else {
      let possibleCompetitions: CompetitionBet[] = [];

      possibleCompetitions = this.props.tradersWithCompetitions[traderId]
        .competitions;
      // Step 2.3: with relevant competitions, build option values for dropdown "competition"
      possibleCompetitions.forEach((competition) => {
        if (
          this.props.selectedRegionReadId === 0 ||
          this.props.selectedRegionReadId === competition.region.id
        ) {
          // add competition, if not already
          if (uniqueCompetitions.hasOwnProperty(competition.readId) === false) {
            uniqueCompetitions[competition.readId] = true;
            optionsCompetitions.push({
              id: competition.id,
              name: competition.name as string,
            });
          }
        }
      });
    }
    let newState = { ...this.state };
    newState.optionsCompetitions = optionsCompetitions;
    this.setState(newState);
  }

  render() {
    const { optionsTraders } = this.getOptionsValuesTrader();
    return (
      <div className={classnames(styles.wrapper, this.props.wrapperClassName)}>
        <ProtectedResource
          componentName={"TraderRegionCompetition"}
          resourceKey={"traderFilter"}
        >
          <SelectForm
            wrapperClassName={styles.noSpaceLeft}
            changeValue={(value: string) =>
              this.handleChangeValueTrader(value, "traderId")
            }
            label={"Trader"}
            selectedOptionId={this.props.selectedTraderId}
            optionsArray={optionsTraders}
          />
        </ProtectedResource>
        <SelectForm
          wrapperClassName={styles.noSpaceLeft}
          changeValue={(value: string) =>
            this.handleChangeValueCompetitionsRegion(value, "regionReadId")
          }
          label={"Region"}
          selectedOptionId={this.props.selectedRegionReadId}
          optionsArray={this.state.optionsRegion}
        />
        <SelectForm
          wrapperClassName={styles.noSpaceLeft}
          changeValue={(value: string) =>
            this.handleChangeValueCompetitionsRegion(value, "competitionReadId")
          }
          label={"Competition"}
          selectedOptionId={this.props.selectedCompetitionReadId}
          optionsArray={this.state.optionsCompetitions}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    traders: state.reports.traders,
    tradersWithCompetitions: state.reports.tradersWithCompetitions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTraders: () => {
      dispatch(getTraders());
    },
    getTradersCompetition: (traderId: string) => {
      dispatch(getTradersCompetition(traderId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TraderRegionCompetition);
