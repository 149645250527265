import React from "react";
import styles from "./loginCallback.module.css";

export default class SignoutPage extends React.Component {
  // this page is a loading page outside /dashboard routes, because while the openIdConnect is making the signout, 
  // the user is redirected here immediately
  render() {
    return (
      <div className={styles.loadingParent}>
        <div className={styles.loadingContainer}>
          <div className={"loader-circle"}></div>
        </div>
      </div>
    );
  }
}
