import React, { Component } from "react";
import { connect } from "react-redux";
import { getEventsByDay } from "src/redux/actions/action-events";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./addbets.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "src/css/react-datepicker-custom.css";
import Competitions from "./eventsList/competitions";
import { getDayString } from "src/utils/dateUtils";
import { getFavoriteCompetition } from "src/redux/actions/action-competitions";
import MyDatePicker from "src/components/date-picker/MyDatePicker";
import BetHistory from "src/views/add_bets/betHistory";

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;
type State = { currentDate: Date };

class AddBets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentDate: new Date(),
    };
    this.props.getFavoriteCompetition();
    this.props.getEventsByDay(this.state.currentDate);
  }

  handleChangeDate = (newDate: Date) => {
    this.props.getEventsByDay(newDate);
    this.setState({ currentDate: newDate });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.mainContent}>
          <div className={styles.filtersContainer}>
            <MyDatePicker currentDate={this.state.currentDate} changeValue={this.handleChangeDate} />
          </div>
          <Competitions
            isValueViewer={false}
            dayString={getDayString(this.state.currentDate)}
            targetDate={this.state.currentDate}
          />
        </div>
        <div className={styles.sideRight}>
          <BetHistory targetDate={this.state.currentDate}></BetHistory>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getEventsByDay: (day: Date) => {
      dispatch(getEventsByDay(day));
    },
    getFavoriteCompetition: () => {
      dispatch(getFavoriteCompetition());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBets);
