import React, { Component } from "react";
import styles from "./betsInfo.module.css";
import {
  BetStatus,
  MarketPeriod,
  MarketProvider,
  MarketSelectionType,
  MarketType,
  ScoreType,
} from "src/model/enums";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "src/utils/numberUtils";
import { MyBetInterface } from "src/model/myTypes";
import classNames from "classnames";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { connect } from "react-redux";
import { getBankRollValueFromDayBefore } from "src/utils/fundUtils";
import {
  formatDayShort,
  formatTimeHourMinuteSecond,
} from "src/utils/dateUtils";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import { MyBet } from "src/model/classes";
import ProtectedResource from "src/components/user-permissions/protected-resource";

type ownPropsType = {
  bets: MyBetInterface[];
  isFundPage: boolean;
  isBotsPage: boolean;
  eventStartTime?: string;
};

type Props = ownPropsType &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {};

class BetInfo extends Component<Props, State> {
  renderBetInfoHeader() {
    return (
      <div className={styles.betInfoHeader}>
        <div className={styles.date}>Placed at</div>
        <ProtectedResource
          componentName={"BetInfo"}
          resourceKey={"columnTrader"}
        >
          <div className={styles.trader}>
            {this.props.isBotsPage ? "Bot" : "Trader"}
          </div>
        </ProtectedResource>
        <ProtectedResource
          componentName={"BetInfo"}
          resourceKey={"columnBookie"}
        >
          <div className={styles.marketProvider}>Bookie</div>
        </ProtectedResource>
        <div className={styles.market}>Market</div>
        <div className={styles.marketSelected}>Selection</div>
        <div className={styles.line}>Line</div>
        <div className={styles.price}>Price</div>
        <div className={styles.stake}>Stake</div>
        <ProtectedResource
          componentName={"BetInfo"}
          resourceKey={"columnValue"}
        >
          <div className={styles.value}>Value</div>
        </ProtectedResource>
        <div className={styles.profit}>
          <span className={styles.hide_sm}>Profit/Loss</span>
          <span className={styles.hide_xl}>P/L</span>
        </div>
        <div className={styles.status}>Status</div>
        {this.props.isBotsPage && (
          <div className={styles.bookieStatus}>Bookie Status</div>
        )}
      </div>
    );
  }

  renderZeroBets() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>for the seletected filters</span>
      </div>
    );
  }

  renderBets() {
    let totalSettledStake: number = 0;
    let totalProfitLoss: number = 0;
    return (
      <div>
        {this.props.bets
          .sort((a, b) => {
            return (
              new Date(b.placedDate).getTime() -
              new Date(a.placedDate).getTime()
            );
          })
          .map((bet) => {
            let traderName = "";
            const betIsOpen = new MyBet(bet).isOpen();

            this.props.traders.forEach((trader) => {
              if (trader.id === bet.userId) {
                traderName = trader.name;
              }
            });

            const marketPeriod =
              MarketPeriod[bet.marketPeriodSelected] === "FullTime"
                ? "FT"
                : "HT";

            const market =
              marketPeriod + " - " + ScoreType[bet.scoreTypeSelected];

            let bankrollDayBefore = 100;
            if (this.props.isFundPage === true) {
              bankrollDayBefore = getBankRollValueFromDayBefore(
                this.props.bankRollFund,
                this.props.eventStartTime
              );
            }
            const isDeletedStyle =
              this.props.isBotsPage === true &&
              bet.status === BetStatus.Deleted;

            if (typeof bet.profit !== "undefined" && !betIsOpen) {
              totalProfitLoss +=
                ((bet.profit as number) / Math.abs(bankrollDayBefore)) * 100;
              totalSettledStake += bet.stake;
            }

            return (
              <div
                key={bet.id}
                className={classnames(
                  styles.betInfoWrapper,
                  isDeletedStyle === true && styles.isDeletedStyle
                )}
              >
                <div className={styles.date}>
                  <span>{formatDayShort(new Date(bet.placedDate))}</span>
                  <span className={styles.grayText}>
                    {formatTimeHourMinuteSecond(new Date(bet.placedDate))}
                  </span>
                </div>
                <ProtectedResource
                  componentName={"BetInfo"}
                  resourceKey={"columnTrader"}
                >
                  <div className={styles.trader}>{traderName}</div>
                </ProtectedResource>
                <ProtectedResource
                  componentName={"BetInfo"}
                  resourceKey={"columnBookie"}
                >
                  <div className={styles.marketProvider}>
                    {MarketProvider[bet.bookie]}
                  </div>
                </ProtectedResource>
                <div className={styles.market}>{market}</div>
                <div className={styles.marketSelected}>
                  {MarketType[bet.marketTypeSelected] === "AsianHandicap" ? (
                    <span>
                      Handicap{" "}
                      {MarketSelectionType[bet.marketSelectionTypeSelected]}
                    </span>
                  ) : (
                    <span>
                      Total{" "}
                      {MarketSelectionType[bet.marketSelectionTypeSelected]}
                    </span>
                  )}
                </div>
                <div className={styles.line}>{formatNumber(bet.line, 2)}</div>
                {this.renderPrice(bet)}
                {this.renderStake(bet, betIsOpen)}
                <ProtectedResource
                  componentName={"BetInfo"}
                  resourceKey={"columnValue"}
                >
                  <div className={styles.value}>
                    {formatPercentage(bet.value, 0)}
                  </div>
                </ProtectedResource>
                {!this.props.isFundPage ? (
                  <div
                    className={classNames(
                      styles.profit,
                      (bet.profit as number) >= 0 && styles.green,
                      (bet.profit as number) < 0 && styles.red
                    )}
                  >
                    {formatCurrency(bet.profit as number)}
                  </div>
                ) : (
                  <div
                    className={classNames(
                      styles.profit,
                      (bet.profit as number) >= 0 && styles.green,
                      (bet.profit as number) < 0 && styles.red
                    )}
                  >
                    <Tooltip
                      arrow={true}
                      title={
                        "Bankroll from day before: " +
                        formatCurrency(bankrollDayBefore)
                      }
                      aria-label="Bankroll"
                    >
                      <span>
                        {formatPercentage(
                          ((bet.profit as number) /
                            Math.abs(bankrollDayBefore)) *
                            100,
                          2
                        )}
                      </span>
                    </Tooltip>
                  </div>
                )}
                <div className={styles.status}>
                  {BetStatus[bet.status as BetStatus]}
                </div>
                {this.props.isBotsPage && (
                  <div className={styles.bookieStatus}>
                    {bet.bookieBetErrorCode !== null &&
                      bet.bookieBetErrorCode !== undefined &&
                      bet.bookieBetTextStatus !== null &&
                      bet.bookieBetTextStatus !== undefined && (
                        <span>
                          {bet.bookieBetErrorCode} - {bet.bookieBetTextStatus}
                        </span>
                      )}
                    {bet.bookieBetId !== null &&
                      typeof bet.bookieBetId !== "undefined" && (
                        <span className={styles.grayText}>
                          {"(BetId: " + bet.bookieBetId + ")"}
                        </span>
                      )}
                  </div>
                )}
              </div>
            );
          })}
        {this.props.isFundPage && (
          <div className={classnames(styles.betInfoWrapper, styles.betTotals)}>
            <div className={styles.date}></div>
            <ProtectedResource
              componentName={"BetInfo"}
              resourceKey={"columnTrader"}
            >
              <div className={styles.trader}></div>
            </ProtectedResource>
            <ProtectedResource
              componentName={"BetInfo"}
              resourceKey={"columnBookie"}
            >
              <div className={styles.marketProvider}></div>
            </ProtectedResource>
            <div className={styles.market}></div>
            <div className={styles.marketSelected}></div>
            <div className={styles.line}></div>
            <div className={styles.price}></div>
            <div className={classnames(styles.stake, styles.stakeTotal)}>
              <Tooltip
                arrow={true}
                title={
                  "Settled bets stake total / start bankroll (" +
                  this.props.startBankroll +
                  ") "
                }
                aria-label="Bankroll"
              >
                <span>
                  {formatPercentage(
                    (totalSettledStake * 100) / this.props.startBankroll,
                    1
                  )}
                </span>
              </Tooltip>
            </div>
            <ProtectedResource
              componentName={"BetInfo"}
              resourceKey={"columnValue"}
            >
              <div className={styles.value}></div>
            </ProtectedResource>
            <div
              className={classnames(
                styles.profit,
                styles.profitTotal,
                totalProfitLoss >= 0 && styles.green,
                totalProfitLoss < 0 && styles.red
              )}
            >
              <Tooltip
                arrow={true}
                title={"Total profit/loss"}
                aria-label="Bankroll"
              >
                <span>{formatPercentage(totalProfitLoss, 2)}</span>
              </Tooltip>
            </div>
            <div className={styles.status}></div>
          </div>
        )}
      </div>
    );
  }

  renderPrice(bet: MyBetInterface) {
    const hasBookieBetPrice =
      this.props.isBotsPage === true &&
      typeof bet.bookieMatchedOdd !== "undefined" &&
      bet.bookieMatchedOdd !== null;

    return (
      <div
        className={classnames(
          styles.price,
          hasBookieBetPrice === true && styles.hasMatchedBet
        )}
      >
        <span
          className={styles.requestedPrice}
          title={hasBookieBetPrice === true ? "Price requested" : undefined}
        >
          {formatNumber(bet.price, 3)}
        </span>
        {hasBookieBetPrice === true && (
          <span
            className={styles.bookiePrice}
            title={
              hasBookieBetPrice === true ? "Price matched in bookie" : undefined
            }
          >
            {formatNumber(Number(bet.bookieMatchedOdd), 3)}
          </span>
        )}
      </div>
    );
  }

  renderStake(bet: MyBetInterface, betIsOpen: boolean) {
    if (this.props.isBotsPage === true) {
      const hasBookieBetStake =
        typeof bet.bookieMatcheStake !== "undefined" &&
        bet.bookieMatcheStake !== null;
      return (
        <div
          className={classnames(
            styles.stake,
            hasBookieBetStake === true && styles.hasMatchedBet
          )}
        >
          <span
            className={styles.requestedStake}
            title={hasBookieBetStake === true ? "Stake requested" : undefined}
          >
            {formatCurrency(bet.stake, 0)}
          </span>
          {hasBookieBetStake === true && (
            <span
              className={styles.bookieStake}
              title={
                hasBookieBetStake === true
                  ? "Stake matched in bookie"
                  : undefined
              }
            >
              {formatCurrency(Number(bet.bookieMatcheStake), 0)}
            </span>
          )}
        </div>
      );
    } else if (!this.props.isFundPage) {
      return (
        <div className={styles.stake}>
          <span>{formatCurrency(bet.stake, 0)}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.stake}>
          <span
            className={classnames(betIsOpen === true && styles.openBetStake)}
          >
            {formatCurrency((bet.stake * 100) / this.props.startBankroll, 1) +
              "%"}
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div className={styles.scrollableContainer}>
        <div className={styles.betInfoTable}>
          {this.renderBetInfoHeader()}
          {this.props.bets.length > 0
            ? this.renderBets()
            : this.renderZeroBets()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    bankRollFund: state.fund.bankRollFund,
    startBankroll: state.fund.initialBankRoll,
    traders: state.reports.traders,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BetInfo);
