import React, { Component } from "react";
import styles from "./fundIndex.module.css";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import {
  ReportMarketPeriod,
  ReportScoreType,
  StatsChartType,
} from "src/model/enums";
import classnames from "classnames";
import MyDateRangePicker from "src/components/date-picker/MyDateRangePicker";
import TraderRegionCompetition from "src/views/reports/filters/traderRegionCompetition";
import { ButtonBase } from "@material-ui/core";
import {
  fundIndexOptions,
  getDefaultDatesPageFund,
  optionsReports,
} from "src/myEnv";
import Modal from "src/components/pop-up/modal";
import PLGrid from "src/views/my_bets/plgrid";
import {
  AreaChart,
  Area,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Label,
} from "recharts";
import { formatNumber, formatPercentage } from "src/utils/numberUtils";
import { ValueForm } from "src/components/inputs/valueForm";
import { StatsChartParameters } from "src/model/classes";
import {
  changeBankRoll,
  changeIndex,
  changeFundDates,
  getFundIndexChart,
} from "src/redux/actions/action-fundIndex";
import { EventBet, StatsChart } from "src/model/myTypes";
import { getBets } from "src/redux/actions/action-bets";
import BetsFund from "./bets/betsFund";
import { defaultEventBet } from "src/model/myTypesDefault";
import debounce from "lodash/debounce";
import DailyTable from "./daily-change-index/dailyTable";
import { EnumHelper } from "src/utils/enum-helper";
import ProtectedResource from "../../components/user-permissions/protected-resource";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

enum TabType {
  Chart = 1,
  Table = 2,
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  traderId: string;
  regionReadId: number;
  competitionId: string;
  reportMarketPeriod: ReportMarketPeriod;
  reportScoreType: ReportScoreType;
  eventIdGrid: string;
  startTime: string;
  homeCompetitor: string;
  awayCompetitor: string;
  eventBet: EventBet;
  resumeDetailsTab: TabType;
  fundEndDate: Date;
  showFilters: boolean;
};

const initialLocalState: State = {
  traderId: "",
  regionReadId: 0,
  competitionId: "",
  reportMarketPeriod: ReportMarketPeriod.All,
  reportScoreType: ReportScoreType.All,
  eventIdGrid: "",
  startTime: "",
  homeCompetitor: "",
  awayCompetitor: "",
  eventBet: defaultEventBet,
  resumeDetailsTab: TabType.Chart,
  fundEndDate: new Date(),
  showFilters: false, // option only visible in mobile (css display md-none and lg-none)
};

class FundIndex extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ...initialLocalState };
    this.handleGetChartData();
  }

  componentDidMount() {
    if (this.state.fundEndDate <= new Date(this.props.initialDay)) {
      this.props.changeFundDates(this.state.fundEndDate);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.traderId !== this.state.traderId ||
      prevState.regionReadId !== this.state.regionReadId ||
      prevState.competitionId !== this.state.competitionId ||
      prevState.reportMarketPeriod !== this.state.reportMarketPeriod ||
      prevState.reportScoreType !== this.state.reportScoreType ||
      prevProps.initialDay !== this.props.initialDay ||
      prevState.fundEndDate !== this.state.fundEndDate ||
      prevProps.startBankroll !== this.props.startBankroll ||
      (prevProps.betsShouldUpdate !== this.props.betsShouldUpdate &&
        this.props.betsShouldUpdate === true)
    ) {
      this.handleGetChartDataDebounced();
    }
  }

  handleGetChartDataDebounced = debounce(() => {
    this.handleGetChartData();
  }, 500);

  handleGetChartData() {
    if (this.props.isDirty) {
      const defaultRangeDates = getDefaultDatesPageFund();
      this.props.getBets(
        defaultRangeDates.initialDate,
        defaultRangeDates.endDate,
        this.state.traderId
      );
      const chartParemeters = new StatsChartParameters({
        initDay: defaultRangeDates.initialDate,
        endDay: defaultRangeDates.endDate,
        type: StatsChartType.ProfitLossChart,
        traderId: this.state.traderId,
        regionId: this.state.regionReadId,
        competitionId: this.state.competitionId,
        marketPeriod: this.state.reportMarketPeriod,
        scoreType: this.state.reportScoreType,
      });

      this.props.getFundIndexChart(
        chartParemeters,
        Number(this.props.startBankroll)
      );
      this.props.changeFundDates(defaultRangeDates.initialDate);
    } else {
      const chartParemeters = new StatsChartParameters({
        initDay: this.props.initialDay,
        endDay: this.state.fundEndDate,
        type: StatsChartType.ProfitLossChart,
        traderId: this.state.traderId,
        regionId: this.state.regionReadId,
        competitionId: this.state.competitionId,
        marketPeriod: this.state.reportMarketPeriod,
        scoreType: this.state.reportScoreType,
      });

      this.props.getFundIndexChart(
        chartParemeters,
        Number(this.props.startBankroll)
      );

      this.handleBetsChange(this.props.initialDay, this.state.fundEndDate);
    }
  }

  handleChangePLGridEventId = (
    eventIdGrid: string,
    startTime: string,
    homeCompetitor: string,
    awayCompetitor: string,
    event: Event,
    eventBet: EventBet
  ) => {
    event.stopPropagation(); // do not activate click bellow this click
    this.setState({
      eventIdGrid: eventIdGrid,
      startTime: startTime,
      homeCompetitor: homeCompetitor,
      awayCompetitor: awayCompetitor,
      eventBet: eventBet,
    });
  };

  handleResetPLGridEventId = () => {
    this.setState({
      eventIdGrid: "",
    });
  };

  handleChangeValueSelected = (newValue: string, key: string) => {
    switch (key) {
      case "traderId":
        this.setState({
          traderId: newValue,
          regionReadId: 0,
          competitionId: "",
        });
        break;
      case "regionReadId":
        this.setState({ regionReadId: Number(newValue), competitionId: "" });
        break;
      case "competitionReadId":
        this.setState({ competitionId: (newValue as unknown) as string });
        break;
      default:
        break;
    }
  };

  handleSelectMarketPeriod(id: number): void {
    this.setState({ reportMarketPeriod: id });
  }

  handleSelectScoreType(id: number): void {
    this.setState({ reportScoreType: id });
  }

  handleChangeValueFromChild = (newValue: string, optionName: string) => {
    if (optionName === "startBankroll") {
      this.props.changeBankroll(Number(newValue));
    } else if (optionName === "startIndex") {
      this.props.changeIndex(Number(newValue));
    }
  };

  handleResetClick = () => {
    this.setState({ ...initialLocalState });
    const defaultRangeDates = getDefaultDatesPageFund();
    this.props.changeFundDates(defaultRangeDates.initialDate);
  };

  handleBetsChange(newStartDate: Date, newEndDate: Date) {
    this.props.getBets(newStartDate, newEndDate, this.state.traderId);
  }

  handleChangeDates = (newStartDate: Date, newEndDate: Date) => {
    this.props.changeFundDates(newStartDate);
    this.setState({
      fundEndDate: newEndDate,
    });
    this.handleBetsChange(newStartDate, newEndDate);
  };

  handleChangeResumeDetailsTab(newTab: TabType) {
    this.setState({ resumeDetailsTab: newTab });
  }

  handleShowFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  renderMarketPeriod() {
    return (
      <div className={styles.marketPeriodContainer}>
        {optionsReports.marketPeroidReports.map((marketPeriod) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.marketPeriod,
                marketPeriod.id === this.state.reportMarketPeriod &&
                  styles.marketPeriodActive
              ),
            }}
            key={marketPeriod.id}
            onClick={() => this.handleSelectMarketPeriod(marketPeriod.id)}
          >
            {marketPeriod.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  renderScoreType() {
    return (
      <div className={styles.scoretypeContainer}>
        {optionsReports.scoreTypeReports.map((scoreType) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.scoreType,
                scoreType.id === this.state.reportScoreType &&
                  styles.scoreTypeActive
              ),
            }}
            key={scoreType.id}
            onClick={() => this.handleSelectScoreType(scoreType.id)}
          >
            {scoreType.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  renderResumeDetailsTabs() {
    return (
      <div className={styles.tabsContainer}>
        {EnumHelper.getNamesAndIds(TabType).map((tab) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.tab,
                tab.id === this.state.resumeDetailsTab && styles.tabActive
              ),
            }}
            key={tab.id}
            onClick={() => this.handleChangeResumeDetailsTab(tab.id)}
          >
            {tab.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  renderOption(fundIndexOption: typeof fundIndexOptions.values) {
    if (typeof this.props[fundIndexOption.name as keyof Props] === undefined) {
      return null;
    } else {
      return (
        <ProtectedResource
          componentName={"FundIndex"}
          resourceKey={fundIndexOption.name}
          key={fundIndexOption.name}
        >
          <ValueForm
            key={fundIndexOption.name}
            wrapperClassName={styles.customValueForm}
            name={
              fundIndexOption.name === "startIndex"
                ? "Start Index "
                : "Start Bankroll"
            }
            changeValue={(value: string) =>
              this.handleChangeValueFromChild(value, fundIndexOption.name)
            }
            step={fundIndexOption.step as number}
            hideArrows={fundIndexOption.hideArrows}
            min={fundIndexOption.min}
            value={this.props[fundIndexOption.name as keyof Props].toString()}
          ></ValueForm>
        </ProtectedResource>
      );
    }
  }

  renderResumeDetailsChart(adaptedDataChart: StatsChart[]) {
    return (
      <div className={styles.chartContainer}>
        <p className={styles.chartTitle}>Index Chart</p>
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <AreaChart
            data={adaptedDataChart}
            margin={{ top: 24, right: 10, bottom: 16, left: 16 }}
            className={styles.linearChart}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="day" />
            <YAxis
              domain={["auto", "auto"]}
              type="number"
              orientation={"right"}
            />
            <Tooltip
              formatter={this.formatLabelValueBets}
              wrapperStyle={{ boxShadow: "2px 2px 3px 0px rgb(0, 0, 0)" }}
              contentStyle={{
                backgroundColor: "rgba(255, 255, 255, 0.9)",
              }}
              labelStyle={{ color: "#22272b" }}
            />
            <Area
              type="monotone"
              dataKey="value"
              stroke="#00acee"
              strokeWidth={2}
              fill="#000000"
            />
            <ReferenceLine
              y={100}
              label={
                <Label
                  value="Break-Even"
                  fill={"#858585"} // css var(--color-text-secondary)
                />
              }
              stroke="rgb(180, 87, 78)" // css var(--color-negative-grid)
              strokeDasharray="5 5"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }

  renderResumeDetailsTable() {
    return (
      <div className={styles.dailyChangeContainer}>
        <p className={styles.chartTitle}>Daily Change to the Fund Index</p>
        <DailyTable />
      </div>
    );
  }

  formatLabelValueBets = (value: any) => {
    return [formatNumber(Number(value), 0), "Index"];
  };

  render() {
    let adaptedDataChart: StatsChart[] = [];
    let totalProfitLoss = 0;
    let endIndex = 0;
    this.props.fundChart.forEach((each) => {
      totalProfitLoss = totalProfitLoss + each.value;
      const adaptedValue =
        ((totalProfitLoss + Number(this.props.startBankroll)) /
          Number(this.props.startBankroll)) *
        Number(this.props.startIndex);
      const chartItem: StatsChart = {
        name: each.name,
        day: each.day,
        value: adaptedValue,
      };
      endIndex = chartItem.value;
      adaptedDataChart.push(chartItem);
    });
    const styleProfitLoss =
      totalProfitLoss < 0
        ? styles.red
        : totalProfitLoss > 0
        ? styles.green
        : "";

    return (
      <div className={styles.wrapper}>
        <div className={styles.mainContent}>
          <div className={styles.filtersContainer}>
            <ButtonBase
              classes={{
                root: classnames(
                  styles.filtersHead,
                  this.state.showFilters && styles.expanded,
                  "md-none",
                  "lg-none"
                ),
              }}
              onClick={this.handleShowFilters}
            >
              Filters
              <div className={styles.expandIcon}>
                {this.state.showFilters ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </ButtonBase>
            <div
              className={classnames(
                styles.filtersBody,
                this.state.showFilters === false && "xs-none"
              )}
            >
              <TraderRegionCompetition
                wrapperClassName={styles.traderRegionCompetitionContainer}
                handleChangeValueSelected={this.handleChangeValueSelected}
                selectedTraderId={this.state.traderId}
                selectedRegionReadId={this.state.regionReadId}
                selectedCompetitionReadId={this.state.competitionId}
              />
              {this.renderMarketPeriod()}
              {this.renderScoreType()}
              <MyDateRangePicker
                rootClassName={styles.datePickerContainer}
                defaultStartDate={new Date(this.props.initialDay)}
                defaultEndDate={new Date(this.state.fundEndDate)}
                changeDates={this.handleChangeDates}
              />
              <div className={styles.optionsContainer}>
                {Object.values(fundIndexOptions).map((option) =>
                  this.renderOption(option)
                )}
              </div>
              <div className={styles.resetBtnContainer}>
                <ButtonBase
                  classes={{ root: styles.resetBtn }}
                  onClick={this.handleResetClick}
                >
                  Reset filters
                </ButtonBase>
              </div>
            </div>
          </div>
          <p className={styles.resumeTitle}>Resume</p>
          <div className={styles.resumeContainer}>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Start Bankroll</p>
              <p className={styles.resumeValue}>
                {formatNumber(Number(this.props.startBankroll), 0)}
              </p>
            </div>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Current Bankroll</p>
              <p className={classnames(styles.resumeValue, styleProfitLoss)}>
                {formatNumber(
                  Number(this.props.startBankroll) + totalProfitLoss,
                  0
                )}
              </p>
            </div>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Profit/Loss</p>
              <p className={classnames(styles.resumeValue, styleProfitLoss)}>
                {formatNumber(totalProfitLoss, 0)}
              </p>
            </div>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Start Index</p>
              <p className={styles.resumeValue}>
                {formatNumber(Number(this.props.startIndex), 1)}
              </p>
            </div>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Current Index</p>
              <p className={classnames(styles.resumeValue, styleProfitLoss)}>
                {formatNumber(endIndex, 1)}
              </p>
            </div>
            <div className={styles.resume}>
              <p className={styles.resumeHeader}>Bankroll Change</p>
              <p className={styles.resumeValue}>
                {formatPercentage(
                  (totalProfitLoss / Number(this.props.startBankroll)) * 100,
                  1
                )}
              </p>
            </div>
          </div>
          <div className={styles.resumeDetailsContainer}>
            {this.renderResumeDetailsTabs()}
            <div className={styles.wrapper}>
              {this.state.resumeDetailsTab === TabType.Table
                ? this.renderResumeDetailsTable()
                : this.renderResumeDetailsChart(adaptedDataChart)}
            </div>
          </div>
          <div className={styles.footerNote}>
            Resumed data here, only takes into account settled bets.
          </div>
          <div className={styles.betsContainer}>
            <p className={styles.chartTitle}>Bets List</p>
            <BetsFund
              ScoreTypeSelected={this.state.reportScoreType}
              MarketPeriodSelected={this.state.reportMarketPeriod}
              traderId={this.state.traderId}
              competitionId={this.state.competitionId}
              regionReadId={this.state.regionReadId}
              changePLGridEventId={this.handleChangePLGridEventId}
              fundEndDate={this.state.fundEndDate}
            />
            {this.state.eventIdGrid !== "" && (
              <Modal handleClickOutside={this.handleResetPLGridEventId}>
                <PLGrid
                  eventId={this.state.eventIdGrid}
                  closePopup={this.handleResetPLGridEventId}
                  isFund={true}
                  traderId={this.state.traderId}
                  eventStartTime={this.state.startTime}
                  homeCompetitorName={this.state.homeCompetitor}
                  awayCompetitorName={this.state.awayCompetitor}
                  marketPeriodSelected={Number(this.state.reportMarketPeriod)}
                  scoreTypeSelected={Number(this.state.reportScoreType)}
                  eventBet={this.state.eventBet}
                />
              </Modal>
            )}
          </div>
          <div className={styles.footerNote}>
            Resumed data here, only takes into account settled bets.
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    fundChart: state.fund.fundChart,
    initialDay: state.fund.range.initialDay,
    isDirty: state.fund.range.isDirty,
    startBankroll: state.fund.initialBankRoll,
    startIndex: state.fund.initialIndex,
    betsShouldUpdate: state.bets.betsShouldUpdate,
    betsDatabase: state.bets.betsDatabase,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getFundIndexChart: (
      chartParameteres: StatsChartParameters,
      startBankroll: number
    ) => {
      dispatch(getFundIndexChart(chartParameteres, startBankroll));
    },
    changeFundDates: (initDay: Date) => {
      dispatch(changeFundDates(initDay));
    },
    changeBankroll: (bankroll: number) => {
      dispatch(changeBankRoll(bankroll));
    },
    changeIndex: (index: number) => {
      dispatch(changeIndex(index));
    },
    getBets: (initialDay: Date, endDay: Date, traderId: string) => {
      dispatch(getBets(initialDay, endDay, traderId, null));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundIndex);
