import React, { Component } from "react";
import styles from "./addCompetitorManual.module.css";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import SaveIcon from "@material-ui/icons/Save";
import TextField from "@material-ui/core/TextField";
import { getInstanceBackend } from "src/redux/actions/axios";
import { toast } from "react-toastify";

type OwnProps = {
  closePopup: Function;
};
type Props = OwnProps;
type State = {
  teamName: string;
};

type NewCompetitorManualDto = {
  name: string;
  userId?: string; // maybe optional to send? otherwise is the logged in userId ((stateOidc.user as User).profile.sub)
};

class AddCompetitorManual extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      teamName: "",
    };
  }

  isValidForm(): boolean {
    return typeof this.state.teamName === "string" && this.state.teamName.length >= 1;
  }

  handleChangeValue = (event: any) => {
    this.setState({ teamName: event.target.value });
  };

  handleSave = () => {
    const addCompetitorBody: NewCompetitorManualDto = { name: this.state.teamName };
    const request = getInstanceBackend()({
      method: "post",
      url: "/CompetitorsManual",
      data: addCompetitorBody,
    });
    request
      .then((response) => {
        toast.success("Competitor added successfully.");
        this.props.closePopup();
      })
      .catch((error) => {
        // do nothing
        console.log("error in add CompetitorsManual", error);
        toast.error("Something went wrong when trying to add the new Competitor. Please try again, or contact us.");
      });
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span className={styles.main}>Add new team</span>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.addCompetitor}>
            <TextField
              fullWidth={true}
              label="Team Name"
              type="text"
              defaultValue={this.state.teamName}
              InputLabelProps={{
                shrink: true,
                className: styles.label,
              }}
              InputProps={{
                classes: {
                  root: styles.textInput,
                  underline: styles.underline,
                },
              }}
              required={true}
              onChange={(event) => this.handleChangeValue(event)}
            />
          </div>
        </div>
        <div className={styles.btnCloseContainer}>
          <ButtonBase
            classes={{ root: classnames(styles.btn, styles.btnCancel) }}
            onClick={() => this.props.closePopup()}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            classes={{ root: classnames(styles.btn, styles.saveBtn), disabled: styles.isDisabled }}
            onClick={this.handleSave}
            disabled={this.isValidForm() === false}
          >
            <SaveIcon /> Save
          </ButtonBase>
        </div>
      </div>
    );
  }
}

export default AddCompetitorManual;
