import React, { Component } from "react";
import styles from "./MyDatePicker.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "src/css/react-datepicker-custom.css";
import { formatDay } from "src/utils/dateUtils";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import classnames from "classnames";
import { Locale } from "date-fns";
import { getLocaleDateFnsObject } from "src/myEnv";

type Props = {
  currentDate: Date;
  changeValue: Function;
  rootClassName?: string;
};

export default class MyDatePicker extends Component<Props> {
  private datepickerParentElement: React.RefObject<HTMLDivElement>;
  private dateFnsLocale: Locale; // used in react-datepicker property "locale"

  constructor(props: Props) {
    super(props);
    this.datepickerParentElement = React.createRef();
    this.dateFnsLocale = getLocaleDateFnsObject();
  }

  handleAddDay = (delta: number) => {
    let newDate = new Date(this.props.currentDate);
    newDate.setDate(this.props.currentDate.getDate() + delta);
    this.handleDateChange(newDate);
  };

  handleDateChange = (newDate: Date) => {
    this.props.changeValue(newDate);
  };

  handleDatepickerOpened = () => {
    ((this.datepickerParentElement.current as HTMLDivElement)
      .classList as DOMTokenList).add(styles.datepickerIsOpen);
  };

  handleDatepickerClosed = () => {
    ((this.datepickerParentElement.current as HTMLDivElement)
      .classList as DOMTokenList).remove(styles.datepickerIsOpen);
  };

  renderDatePickerInput = () => {
    // needed custom input, to format date with user locale and to make specific design
    const formatedDate = formatDay(this.props.currentDate);
    return (
      <ButtonBase classes={{ root: styles.datePickerInput }}>
        {formatedDate}
      </ButtonBase>
    );
  };

  render() {
    return (
      <div className={classnames(styles.wrapper, this.props.rootClassName)}>
        <ButtonBase
          classes={{ root: styles.iconArrow }}
          onClick={() => this.handleAddDay(-1)}
        >
          <ArrowLeftIcon />
        </ButtonBase>
        <div ref={this.datepickerParentElement}>
          <DatePicker
            selected={this.props.currentDate}
            onChange={this.handleDateChange}
            customInput={this.renderDatePickerInput()}
            popperClassName={"datePicker-custom"}
            onFocus={this.handleDatepickerOpened}
            onBlur={this.handleDatepickerClosed}
            onSelect={this.handleDatepickerClosed}
            locale={this.dateFnsLocale}
          />
        </div>
        <ButtonBase
          classes={{ root: styles.iconArrow }}
          onClick={() => this.handleAddDay(1)}
        >
          <ArrowRightIcon />
        </ButtonBase>
      </div>
    );
  }
}
