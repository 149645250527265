import React, { Component } from "react";
import styles from "./listCompetitions.module.css";
import { connect } from "react-redux";
import { getCompetitionsOverview } from "src/redux/actions/action-bets-overview";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import { CompetitionsOverview } from "src/model/myTypes";
import { flagConverter } from "src/utils/flag-converter";
import ButtonBase from "@material-ui/core/ButtonBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import classnames from "classnames";
import OverviewListEvents from "./listEvents";
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from "src/utils/numberUtils";
import { formatDay } from "src/utils/dateUtils";

const TABLE_HEADERS: { title: string; value: keyof CompetitionsOverview }[] = [
  { title: "Region", value: "region" as keyof CompetitionsOverview },
  { title: "Competition", value: "name" as keyof CompetitionsOverview },
  { title: "Stake", value: "stake" as keyof CompetitionsOverview },
  { title: "Profit/Loss", value: "profit" as keyof CompetitionsOverview },
  { title: "Margin", value: "margin" as keyof CompetitionsOverview },
  { title: "Bets", value: "bets" as keyof CompetitionsOverview },
];

type OwnProps = {
  timeline: String;
  startDate: Date;
  endDate: Date;
  close: () => void;
  traderId: string;
};
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  competitionIdOpen: number;
  sortColumn: keyof CompetitionsOverview;
  sortOrder: boolean; // true -> asc; false -> desc
};

class OverviewListCompetitions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      competitionIdOpen: -1,
      sortColumn: "profit",
      sortOrder: true,
    };
    this.props.getCompetitionsOverview(
      this.props.startDate,
      this.props.endDate,
      this.props.traderId
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.timeline !== prevProps.timeline ||
      prevProps.traderId !== this.props.traderId
    ) {
      this.props.getCompetitionsOverview(
        this.props.startDate,
        this.props.endDate,
        this.props.traderId
      );
    }
  }

  sortByString(stringOne: string, stringTwo: string) {
    return stringOne.localeCompare(stringTwo);
  }

  sortByNumber(numberOne: number, numberTwo: number) {
    return numberTwo - numberOne;
  }

  handleClickExpandCompetition = (competitionReadId: number) => {
    if (this.state.competitionIdOpen === competitionReadId) {
      this.setState({ competitionIdOpen: -1 });
    } else {
      this.setState({ competitionIdOpen: competitionReadId });
    }
  };

  handleClickSortCompetitions = (newSortColumn: keyof CompetitionsOverview) => {
    if (this.state.sortColumn === newSortColumn) {
      // case 1: user clicked one column that was already the sorted column, should invert order
      this.setState({ sortOrder: !this.state.sortOrder });
    } else {
      // case 2: user clicked a new column, so should sort default and change column
      this.setState({ sortColumn: newSortColumn, sortOrder: true });
    }
  };

  renderTableHeader() {
    return TABLE_HEADERS.map((header) => {
      return (
        <div className={styles[header.value]} key={header.value}>
          <ButtonBase
            component={"div"}
            onClick={() => this.handleClickSortCompetitions(header.value)}
          >
            <span className={styles.headerTitle}>{header.title}</span>
            <div className={styles.sortIcon}>
              <ArrowDropDownIcon
                classes={{
                  root: classnames(
                    styles.up,
                    this.state.sortColumn === header.value &&
                      !this.state.sortOrder &&
                      styles.active
                  ),
                }}
              ></ArrowDropDownIcon>
              <ArrowDropDownIcon
                classes={{
                  root: classnames(
                    styles.down,
                    this.state.sortColumn === header.value &&
                      this.state.sortOrder &&
                      styles.active
                  ),
                }}
              ></ArrowDropDownIcon>
            </div>
          </ButtonBase>
        </div>
      );
    });
  }

  renderEachCompetitionOverview(competition: CompetitionsOverview) {
    return (
      <div className={styles.wrapper} key={competition.id}>
        <ButtonBase
          classes={{
            root: classnames(
              styles.competitionWrapper,
              this.state.competitionIdOpen === competition.readId &&
                styles.expanded
            ),
          }}
          component={"div"}
          onClick={() => this.handleClickExpandCompetition(competition.readId)}
        >
          <div className={styles.region}>
            <div className={styles.flag}>
              {flagConverter(competition.region.alphaCode as string)}
            </div>
            <span className={styles.regionName}>{competition.region.name}</span>
          </div>
          <div className={styles.name}>{competition.name}</div>
          <div className={styles.stake}>
            {formatCurrency(competition.stake)}
          </div>
          <div
            className={classnames(
              styles.profit,
              competition.profit > 0 && styles.green,
              competition.profit < 0 && styles.red
            )}
          >
            {formatCurrency(competition.profit)}
          </div>
          <div
            className={classnames(
              styles.margin,
              competition.margin > 0 && styles.green,
              competition.margin < 0 && styles.red
            )}
          >
            {formatPercentage(competition.margin * 100, 2)}
          </div>
          <div className={styles.bets}>{formatNumber(competition.bets)}</div>
          <div className={styles.expandIcon}>
            {competition.readId === this.state.competitionIdOpen ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </div>
        </ButtonBase>
        {this.state.competitionIdOpen === competition.readId && (
          <OverviewListEvents
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            competitionId={competition.id}
            traderId={this.props.traderId}
          />
        )}
      </div>
    );
  }

  renderCompetitions() {
    return Object.values(this.props.competitions)
      .sort((a, b) => {
        let sortResult = 0;
        if (this.state.sortColumn === "region") {
          // case 1: sorting column is "region", which is an object and needs special treatment
          const aCountry = a.region.name as string;
          const bCountry = b.region.name as string;
          // sort by regionName
          sortResult = this.sortByString(aCountry, bCountry);
          if (sortResult === 0) {
            // sortByID
            sortResult = this.sortByNumber(a.readId, b.readId);
          }
        } else {
          // case 2: sorting column can be accessed directly
          const valueA = a[this.state.sortColumn];
          const valueB = b[this.state.sortColumn];
          if (typeof valueA === "string") {
            sortResult = this.sortByString(valueA as string, valueB as string);
          } else if (typeof valueA === "number") {
            sortResult = this.sortByNumber(valueA as number, valueB as number);
          }
        }

        // reverse sorting if this.state.sortOrder === false
        if (this.state.sortOrder === false) {
          sortResult = -1 * sortResult;
        }
        return sortResult;
      })
      .map((competition) => this.renderEachCompetitionOverview(competition));
  }

  renderZeroCompetitions() {
    return (
      <div className={styles.zeroBetsContainer}>
        <p className={classnames(styles.text, styles.big)}>No bets found</p>
        <span className={styles.text}>
          on events from period {this.props.timeline}
        </span>
      </div>
    );
  }

  render() {
    const isReady: boolean = this.props.competitions !== undefined;
    const hasBets: boolean = this.props.competitions.length > 0;

    return isReady ? (
      <div className={styles.competitionsOverview}>
        <div className={styles.header}>
          <p className={styles.title}>
            {this.props.timeline}
            <span className={styles.note}>
              (from {formatDay(this.props.startDate)}, to{" "}
              {formatDay(new Date(this.props.endDate.getTime() - 1))})
            </span>
          </p>
          <ButtonBase className={styles.close} onClick={this.props.close}>
            &#215;
          </ButtonBase>
        </div>
        <div className={styles.tableHead}>
          {this.renderTableHeader()}
          <div className={styles.expandIcon}></div>
        </div>
        {this.props.isLoadingCompetitions ? (
          <div className={styles.loadingParent}>
            <div className={styles.loadingContainer}>
              <div className={"loader-circle"}></div>
            </div>
          </div>
        ) : (
          <div className={styles.competitions}>
            {hasBets
              ? this.renderCompetitions()
              : this.renderZeroCompetitions()}
          </div>
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    competitions: state.betsOverview.competitions,
    isLoadingCompetitions: state.betsOverview.isLoadingCompetitions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCompetitionsOverview: (
      startDate: Date,
      endDate: Date,
      traderId: string
    ) => {
      dispatch(getCompetitionsOverview(startDate, endDate, traderId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverviewListCompetitions);
