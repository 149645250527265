import { getInstanceBackend } from "./axios";
import { MySettledMatchInterface } from "src/model/myTypes";
import { toast } from "react-toastify";

type SettleMatchAction = {
  type: "SETTLE_MATCH";
  payload: {};
};

type HandleMatchesToSettleAction = {
  type: "HANDLE_MATCHES_TO_SETTLE";
  payload: Array<string>;
};

type HandleMatchSettlingAction = {
  type: "HANDLE_MATCH_SETTLING";
  payload: string;
};

export type SettleActions =
  | SettleMatchAction
  | HandleMatchesToSettleAction
  | HandleMatchSettlingAction; // | action1 | action2 | ...

export function settleMatch(mySettledMatch: MySettledMatchInterface) {
  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "post",
      url: "/Events",
      data: { ...mySettledMatch },
    });
    return request
      .then((response) => {
        toast.success("Event settled successfully. Bets Updated.");
        dispatch({
          type: "SETTLE_MATCH",
          payload: {},
        });
      })
      .catch((error) => {
        console.log("error in settleMatch", error);
        toast.error(
          "Something went wrong when trying to settle the event and resolve Bets. Please try again, or contact us."
        );
      });
  };
}

export function handleMatchesToSettle(matchesToSettle: Array<String>) {
  return {
    type: "HANDLE_MATCHES_TO_SETTLE",
    payload: matchesToSettle,
  };
}

export function handleMatchSettling(matchSettling: string) {
  return {
    type: "HANDLE_MATCH_SETTLING",
    payload: matchSettling,
  };
}
