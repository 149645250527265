import { StatsChartReceived, StatsChart } from "src/model/myTypes";
import { formatDayShort } from "src/utils/dateUtils";

export function formatChartDataByDayBeforeToday(
  data: StatsChartReceived[],
  numberOfDays: number,
  date: Date = new Date()
) {
  // step 1: ensure we have normalized received data (with date as keys)
  let receivedDataObject: { [key: string]: StatsChartReceived } = {};
  data.forEach((item) => {
    const dayString = new Date(item.day).toISOString();
    const element: StatsChartReceived = {
      day: dayString,
      value: item.value,
    };
    receivedDataObject[dayString] = element;
  });

  // step 2: build array with all days filled in
  let completeChartData: StatsChart[] = [];

  for (let index = 0; index <= numberOfDays; index++) {
    const elementDay: Date = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - (numberOfDays - index)
    );
    let elementValue: number = 0;
    // check received data if has value, to use it
    const dayString = elementDay.toISOString();
    if (receivedDataObject.hasOwnProperty(dayString) === true) {
      elementValue = receivedDataObject[dayString].value;
    }

    const formattedDay = formatDayShort(elementDay);
    const element: StatsChart = {
      name: formattedDay,
      day: formattedDay,
      value: elementValue,
    };
    completeChartData.push(element);
  }

  return completeChartData;
}

export function formatChartDataByDayFund(data: StatsChartReceived[]) {
  const numberOfDays = 30;

  // step 1: ensure we have normalized received data (with date as keys)
  let receivedDataObject: { [key: string]: StatsChartReceived } = {};
  data.forEach((item) => {
    const dayString = new Date(item.day).toISOString();
    const element: StatsChartReceived = {
      day: dayString,
      value: item.value,
    };
    receivedDataObject[dayString] = element;
  });

  // step 2: build array with all days filled in
  let completeChartData: StatsChart[] = [];

  for (let index = 0; index <= numberOfDays; index++) {
    const elementDay: Date = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - (numberOfDays - index)
    );
    let elementValue: number = 0;
    // check received data if has value, to use it
    const dayString = elementDay.toISOString();
    if (receivedDataObject.hasOwnProperty(dayString) === true) {
      elementValue = receivedDataObject[dayString].value;
    }

    const formattedDay = formatDayShort(elementDay);
    const element: StatsChart = {
      name: formattedDay,
      day: formattedDay,
      value: elementValue,
    };
    completeChartData.push(element);
  }

  return completeChartData;
}
