import React, { Component } from "react";
import styles from "./deleteConfirmation.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";

type ownPropsType = {
  removeBet: Function;
  closePopup: Function;
};
type Props = ownPropsType;

export default class DeleteConfirmation extends Component<Props> {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.message}>PLEASE CONFIRM BET DELETION</div>
        <div className={styles.btnContainer}>
          <ButtonBase
            classes={{
              root: classnames(styles.cancelBtn),
            }}
            onClick={() => this.props.closePopup()}
          >
            CANCEL
          </ButtonBase>
          <ButtonBase
            classes={{
              root: classnames(styles.deleteBtn),
            }}
            onClick={() => this.props.removeBet()}
          >
            DELETE
          </ButtonBase>
        </div>
      </div>
    );
  }
}
