import { StatsChartParameters } from "src/model/classes";
import { StatsChartReceived } from "src/model/myTypes";
import { getInstanceBackend } from "./axios";

type getFundStatsChartAction = {
  type: "GET_FUND_STATS_CHART";
  payload: {
    data: StatsChartReceived[];
    numberOfDays: number;
    endDate: Date;
    initialBankRoll: number;
  };
};

type ChangeDatesFundAction = {
  type: "CHANGE_DATE_FUND";
  payload: { initialDay: Date };
};
type ChangeBankRollFundAction = {
  type: "CHANGE_BANK_ROLL";
  payload: number;
};
type ChangeIndexFundAction = {
  type: "CHANGE_INDEX";
  payload: number;
};

export type FundAction =
  | getFundStatsChartAction
  | ChangeDatesFundAction
  | ChangeBankRollFundAction
  | ChangeIndexFundAction; // | action1 | action2 | ...

export function getFundIndexChart(
  fundParemeters: StatsChartParameters,
  initialBankRoll: number
) {
  // set initial day as hours 0:00, to get integer "numberOfDays" value
  let safeInitDay = new Date(fundParemeters.initDay);
  safeInitDay.setHours(0, 0, 0, 0);

  // ensure all hours of end day is included in response
  let safeEndDay = new Date(fundParemeters.endDay);
  safeEndDay.setHours(23, 59, 59, 999);
  let numberOfDays = Math.floor(
    (safeEndDay.getTime() - safeInitDay.getTime()) / (24 * 60 * 60 * 1000)
  );

  return function action(dispatch: any) {
    const request = getInstanceBackend()({
      method: "get",
      url: "/StatsChart",
      params: {
        ...fundParemeters,
        endDay: safeEndDay.toISOString(),
      },
    });
    return request
      .then((response) => {
        dispatch({
          type: "GET_FUND_STATS_CHART",
          payload: {
            data: response.data,
            endDate: new Date(fundParemeters.endDay),
            numberOfDays: numberOfDays,
            initialBankRoll: initialBankRoll,
          },
        });
      })
      .catch((error) => {
        // do nothing
        console.log("error in getProfitLossGridsByMatch", error);
      });
  };
}

export function changeFundDates(initialDay: Date) {
  return {
    type: "CHANGE_DATE_FUND",
    payload: { initialDay: initialDay },
  };
}

export function changeBankRoll(bankRoll: number) {
  return {
    type: "CHANGE_BANK_ROLL",
    payload: bankRoll,
  };
}
export function changeIndex(index: number) {
  return {
    type: "CHANGE_INDEX",
    payload: index,
  };
}
