import React, { Component } from "react";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import styles from "./plgrid.module.css";
import { ButtonBase } from "@material-ui/core";
import classnames from "classnames";
import { MarketPeriod, ScoreType } from "src/model/enums";
import { options } from "src/myEnv";
import { getProfitLossGridsByMatch } from "src/redux/actions/action-plgrids";
import classNames from "classnames";
import { formatCurrency, formatPercentage } from "src/utils/numberUtils";
import { EventBet } from "src/model/myTypes";
import { formatDayShort } from "src/utils/dateUtils";
import { getBankRollValueFromDayBefore } from "src/utils/fundUtils";

type OwnProps = {
  eventId: string;
  closePopup: Function;
  isFund: boolean;
  isBotsPage?: boolean;
  bookieFilter?: number[];
  eventStartTime: string;
  homeCompetitorName: string;
  awayCompetitorName: string;
  marketPeriodSelected: MarketPeriod;
  scoreTypeSelected: ScoreType;
  traderId: string;
  eventBet: EventBet;
};
type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
type State = {
  marketPeriodSelected: number;
  scoreTypeSelected: number;
  stake: number;
};

type ResultIndexObject = {
  homeResultIndex: number;
  awayResultIndex: number;
};
enum HeaderType {
  Row = 0,
  Column = 1,
}

class PLGrid extends Component<Props, State> {
  protected bankRollValueFromDayBefore: number;
  private elementsHeaderRows: { [rowIndex: string]: HTMLElement | undefined };
  private elementsHeaderColumns: {
    [columnIndex: string]: HTMLElement | undefined;
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      marketPeriodSelected:
        this.props.marketPeriodSelected !== 0
          ? this.props.marketPeriodSelected
          : MarketPeriod.FullTime,
      scoreTypeSelected:
        this.props.scoreTypeSelected !== 0
          ? this.props.scoreTypeSelected
          : ScoreType.Goals,
      stake: 0,
    };
    this.bankRollValueFromDayBefore = getBankRollValueFromDayBefore(
      this.props.bankRollFund,
      this.props.eventStartTime
    );
    this.elementsHeaderRows = { "0": undefined };
    this.elementsHeaderColumns = { "0": undefined };
  }

  componentDidMount() {
    // Gets Values and fills grid values with FullTime/Goals
    this.props.getProfitLossGridsByMatch(
      this.props.eventId,
      this.props.isFund,
      this.props.traderId,
      typeof this.props.bookieFilter !== "undefined"
        ? this.props.bookieFilter
        : [0],
      this.props.isBotsPage === true
    );
    // Subscribe to action hub, to get livescores, to highlight current result
  }

  componentWillUnmount() {
    // remove action hub event subscription
  }

  handleCreateElementsHeaderRef = (el: HTMLElement | null) => {
    if (el !== null) {
      const type =
        el.dataset.type === HeaderType.Row.toString()
          ? HeaderType.Row
          : HeaderType.Column;
      const index = el.dataset.index as string;
      if (typeof index !== "undefined") {
        switch (type) {
          case HeaderType.Row:
            this.elementsHeaderRows[index] = el;
            break;
          case HeaderType.Column:
            this.elementsHeaderColumns[index] = el;
            break;
          default:
            break;
        }
      }
    }
  };

  handleSelectMarketPeriod = (marketPeriodId: number) => {
    this.setState({
      marketPeriodSelected: marketPeriodId,
    });
  };

  handleSelectSportType = (scoreType: number) => {
    this.setState({
      scoreTypeSelected: scoreType,
    });
  };

  handleOnMouseEnterCell = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (typeof event !== "undefined") {
      const indexRow = (event.target as HTMLSpanElement).dataset
        .indexrow as string;
      const indexColumn = (event.target as HTMLSpanElement).dataset
        .indexcolumn as string;
      if (
        typeof indexRow !== "undefined" &&
        typeof indexColumn !== "undefined"
      ) {
        // add class to header row
        if (
          typeof this.elementsHeaderRows[indexRow] !== undefined &&
          this.elementsHeaderRows[indexRow] !== null
        ) {
          (this.elementsHeaderRows[indexRow] as HTMLElement).classList.add(
            styles.hovered
          );
        }
        // add class to header column
        if (
          typeof this.elementsHeaderColumns[indexColumn] !== undefined &&
          this.elementsHeaderColumns[indexColumn] !== null
        ) {
          (this.elementsHeaderColumns[
            indexColumn
          ] as HTMLElement).classList.add(styles.hovered);
        }
      }
    }
  };

  handleOnMouseLeaveCell = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (typeof event !== "undefined") {
      const indexRow = (event.target as HTMLSpanElement).dataset
        .indexrow as string;
      const indexColumn = (event.target as HTMLSpanElement).dataset
        .indexcolumn as string;
      if (
        typeof indexRow !== "undefined" &&
        typeof indexColumn !== "undefined"
      ) {
        // add class to header row
        if (
          typeof this.elementsHeaderRows[indexRow] !== undefined &&
          this.elementsHeaderRows[indexRow] !== null
        ) {
          (this.elementsHeaderRows[indexRow] as HTMLElement).classList.remove(
            styles.hovered
          );
        }
        // add class to header column
        if (
          typeof this.elementsHeaderColumns[indexColumn] !== undefined &&
          this.elementsHeaderColumns[indexColumn] !== null
        ) {
          (this.elementsHeaderColumns[
            indexColumn
          ] as HTMLElement).classList.remove(styles.hovered);
        }
      }
    }
  };

  matrixHasNullValue(array: number[][]) {
    let result: boolean = false;
    if (Array.isArray(array) && array.length > 0) {
      array.some((row) => {
        if (Array.isArray(row) && row.length > 0) {
          row.some((value) => {
            if (value == null) {
              result = true;
            }
            return result;
          });
        }
        if (result === true) {
          result = true;
        }
        return result;
      });
    }
    return result;
  }

  getGridToShow() {
    let gridToShow: string = "";
    let stake: number = 0;

    if (this.state.marketPeriodSelected === MarketPeriod.FullTime) {
      switch (this.state.scoreTypeSelected) {
        case 11:
          gridToShow = "ftGoals";
          break;
        case 12:
          gridToShow = "ftCorners";
          break;
        case 91:
          gridToShow = "ftRedCards";
          break;
      }
    } else if (this.state.marketPeriodSelected === MarketPeriod.FirstHalf) {
      switch (this.state.scoreTypeSelected) {
        case 11:
          gridToShow = "htGoals";
          break;
        case 12:
          gridToShow = "htCorners";
          break;
        case 91:
          gridToShow = "htRedCards";
          break;
      }
    }

    Object.values(this.props.bets).forEach((bet) => {
      if (
        bet.event.id === this.props.eventId &&
        bet.marketPeriodSelected === this.state.marketPeriodSelected &&
        bet.scoreTypeSelected === this.state.scoreTypeSelected
      ) {
        stake = Number(bet.stake);
      }
    });

    if (
      this.matrixHasNullValue(this.props.plGridsInfo[gridToShow]) === true ||
      stake === 0
    ) {
      let emptyGrid: number[][] = [[]];
      for (let i = 0; i < 10; i++) {
        if (i < 9) {
          emptyGrid.push([0]);
        }
        for (let j = 0; j < 10; j++) {
          emptyGrid[i][j] = 0;
        }
      }
      return emptyGrid;
    } else {
      if (this.props.isFund) {
        let indexGrid: number[][] = [[]];
        for (let i = 0; i < 10; i++) {
          if (i < 9) {
            indexGrid.push([0]);
          }
          for (let j = 0; j < 10; j++) {
            indexGrid[i][j] =
              (this.props.plGridsInfo[gridToShow][i][j] /
                this.bankRollValueFromDayBefore) *
              100;
          }
        }
        return indexGrid;
      } else return this.props.plGridsInfo[gridToShow];
    }
  }

  getScoreTypeAndMarketPeriod(condition: string): keyof EventBet {
    let result = "ftGoals";
    if (this.state.marketPeriodSelected === MarketPeriod.FullTime) {
      if (this.state.scoreTypeSelected === ScoreType.Goals) {
        result = "ftGoals";
      } else if (this.state.scoreTypeSelected === ScoreType.Corners) {
        result = "ftCorners";
      } else if (this.state.scoreTypeSelected === ScoreType.Bookings) {
        result = "ftRedCards";
      }
    } else if (this.state.marketPeriodSelected === MarketPeriod.FirstHalf) {
      if (this.state.scoreTypeSelected === ScoreType.Goals) {
        result = "htGoals";
      } else if (this.state.scoreTypeSelected === ScoreType.Corners) {
        result = "htCorners";
      } else if (this.state.scoreTypeSelected === ScoreType.Bookings) {
        result = "htRedCards";
      }
    }
    return (result + condition) as keyof EventBet;
  }

  getResultIndex() {
    let resultIndex = {
      homeResultIndex: -1,
      awayResultIndex: -1,
    };
    // case 1: get scores from ownProps.eventBet
    const homeKey = this.getScoreTypeAndMarketPeriod("Home");
    const awayKey = this.getScoreTypeAndMarketPeriod("Away");
    resultIndex.homeResultIndex =
      typeof this.props.eventBet[homeKey] === "number"
        ? Number(this.props.eventBet[homeKey])
        : -1;
    resultIndex.awayResultIndex =
      typeof this.props.eventBet[awayKey] === "number"
        ? Number(this.props.eventBet[awayKey])
        : -1;
    // case 2: get scores from props.eventActionsData (from actions hub)
    let resultIndexFromHubActions = {
      homeResultIndex: -1,
      awayResultIndex: -1,
    };

    // finally: understand which scores to use
    // if have score on both sources, trust scores from ownProps.eventBet
    // if only have score on 1 source, trust that source
    if (
      resultIndex.homeResultIndex === -1 ||
      resultIndex.awayResultIndex === -1
    ) {
      if (
        resultIndexFromHubActions.homeResultIndex > -1 &&
        resultIndexFromHubActions.awayResultIndex > -1
      ) {
        // use hubActions score (they are valid)
        resultIndex = { ...resultIndexFromHubActions };
      }
    } // else use scores from ownProps.eventBet

    return resultIndex;
  }

  renderHA(resultIndex: ResultIndexObject, type: HeaderType) {
    const items = [];
    const isRow = type === HeaderType.Row;
    for (let i = 0; i < 10; i++) {
      items.push(
        <div
          key={i}
          className={classNames(
            styles.HAeachValue,
            isRow && resultIndex.homeResultIndex === i && styles.result,
            !isRow && resultIndex.awayResultIndex === i && styles.result
          )}
          data-index={i}
          data-type={type}
          ref={this.handleCreateElementsHeaderRef}
        >
          {i}
        </div>
      );
    }
    return items;
  }

  renderAllGridValues(resultIndex: ResultIndexObject) {
    return (this.getGridToShow() as number[][]).map((rows, indexRow) => (
      <div key={indexRow} className={styles.eachRow}>
        {rows.map((values, indexColumn) => (
          <span
            className={classNames(
              styles.eachValue,
              indexColumn === resultIndex.awayResultIndex &&
                indexRow === resultIndex.homeResultIndex &&
                styles.result,
              values === 0 && styles.eachValueNeutral,
              values > 0 && styles.eachValuePositive
            )}
            key={indexColumn}
            data-indexrow={indexRow}
            data-indexcolumn={indexColumn}
            onMouseEnter={this.handleOnMouseEnterCell}
            onMouseLeave={this.handleOnMouseLeaveCell}
          >
            {this.props.isFund
              ? formatPercentage(values, 2)
              : formatCurrency(values, 0)}
          </span>
        ))}
      </div>
    ));
  }

  renderGrid() {
    const resultIndexObject: ResultIndexObject = this.getResultIndex();
    return (
      <div className={styles.grid}>
        <div className={styles.HAcolumn}>
          <div className={styles.HAeachValue}>H/A</div>
          {this.renderHA(resultIndexObject, HeaderType.Row)}
        </div>
        <div className={styles.HArowContainer}>
          <div className={styles.HArow}>
            {this.renderHA(resultIndexObject, HeaderType.Column)}
          </div>{" "}
          <div className={styles.values}>
            {this.renderAllGridValues(resultIndexObject)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            <span className={styles.main}>P/L Grid</span>
            <span className={styles.subtitle}>
              {formatDayShort(new Date(this.props.eventStartTime))}{" "}
              {this.props.homeCompetitorName} {" vs "}
              {this.props.awayCompetitorName}
            </span>
          </div>
          <div className={styles.filters}>
            <div className={styles.periodSelection}>
              {options.marketPeroid.map((marketPeriod) => (
                <ButtonBase
                  classes={{
                    root: classnames(
                      styles.periodSelected,
                      marketPeriod.id === this.state.marketPeriodSelected &&
                        styles.periodSelectedActive
                    ),
                  }}
                  key={marketPeriod.id}
                  onClick={() => this.handleSelectMarketPeriod(marketPeriod.id)}
                >
                  {marketPeriod.name}
                </ButtonBase>
              ))}
            </div>
            <div className={styles.scoreTypeSelection}>
              {options.scoreType.map((scoreType) => (
                <ButtonBase
                  classes={{
                    root: classnames(
                      styles.scoreTypeSelected,
                      scoreType.id === this.state.scoreTypeSelected &&
                        styles.scoreTypeSelectedActive
                    ),
                  }}
                  key={scoreType.id}
                  onClick={() => this.handleSelectSportType(scoreType.id)}
                >
                  {scoreType.name}
                </ButtonBase>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.content}>{this.renderGrid()}</div>
        <div className={styles.btnCloseContainer}>
          <ButtonBase
            classes={{
              root: classnames(styles.btnCancel),
            }}
            onClick={() => this.props.closePopup()}
          >
            CLOSE
          </ButtonBase>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE, ownProps: OwnProps) => {
  return {
    plGridsInfo: state.plGrids,
    bets: state.bets.betsDatabase,
    bankRollFund: state.fund.bankRollFund,
    initialBankRoll: state.fund.initialBankRoll,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getProfitLossGridsByMatch: (
      eventId: string,
      isFundPage: boolean,
      traderId: string,
      bookieFilter: number[],
      isBotsPage: boolean
    ) => {
      dispatch(
        getProfitLossGridsByMatch(
          eventId,
          isFundPage,
          traderId,
          bookieFilter,
          isBotsPage
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PLGrid);
