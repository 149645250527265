import {
  MyBetsReducer,
  MyCompetitionsByDay,
  MyFavoriteCompetitions,
  MyPLGridsInfo,
  StatsOverview,
  ReportsReducer,
  StatsChartReducer,
  BetsOverviewReducer,
  Settings,
  FundReducer,
} from "src/model/myTypes";
import { UserState } from "redux-oidc";
import { getDefaultDatesPageFund, getDefaultDatesPageReports } from "src/myEnv";

export const InitialStateEvents: MyCompetitionsByDay = {
  list: {},
  isLoadingEvents: false,
};
export const InitialStateBets: MyBetsReducer = {
  range: {
    initialDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ),
    endDay: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 7
    ),
    isDirty: true,
  },
  betsShouldUpdate: false,
  betsByCompetition: {},
  betsDatabase: {},
  betsByEvent: {},
  betsOpenByEvent: {},
  betsDayHistory: {
    betsDatabase: {},
    betsByEvent: {},
    day: new Date(),
  },
  autoSettle: {
    matchesToSettle: [],
    matchSettling: "",
  },
  isLoadingBets: false,
  betsFiltered: {
    betIds: [],
    tittle: "",
  },
};

export const InitialStatefavoriteCompetitions: MyFavoriteCompetitions = {};

export const InitialStatePLGridsInfo: MyPLGridsInfo = {
  ftGoals: [new Array(10)],
  htGoals: [new Array(10)],
  ftCorners: [new Array(10)],
  htCorners: [new Array(10)],
  ftRedCards: [new Array(10)],
  htRedCards: [new Array(10)],
};

export const InitialStatsOverview: StatsOverview = {
  stake: {
    today: 0,
    yesterday: 0,
    thisWeek: 0,
    lastWeek: 0,
    thisMonth: 0,
    lastMonth: 0,
    thisYear: 0,
    overall: 0,
  },
  profitLoss: {
    today: 0,
    yesterday: 0,
    thisWeek: 0,
    lastWeek: 0,
    thisMonth: 0,
    lastMonth: 0,
    thisYear: 0,
    overall: 0,
  },
  margin: {
    today: 0,
    yesterday: 0,
    thisWeek: 0,
    lastWeek: 0,
    thisMonth: 0,
    lastMonth: 0,
    thisYear: 0,
    overall: 0,
  },
  bets: {
    today: 0,
    yesterday: 0,
    thisWeek: 0,
    lastWeek: 0,
    thisMonth: 0,
    lastMonth: 0,
    thisYear: 0,
    overall: 0,
  },
  configs: {
    today: { startDate: null, endDate: null },
    yesterday: { startDate: null, endDate: null },
    thisWeek: { startDate: null, endDate: null },
    lastWeek: { startDate: null, endDate: null },
    thisMonth: { startDate: null, endDate: null },
    lastMonth: { startDate: null, endDate: null },
    thisYear: { startDate: null, endDate: null },
    overall: { startDate: null, endDate: null },
  },
};

export const InitialStatsChart: StatsChartReducer = {
  betsChart: [],
  profitLossChart: [],
};

const InitialSateOidc: UserState = {
  user: undefined,
  isLoadingUser: false,
};

export const InitialStateReports: ReportsReducer = {
  traders: [{ id: "", name: "ALL" }],
  tradersWithCompetitions: {},
  betType: [],
  calender: [],
  isLoading: false,
  range: {
    initialDay: getDefaultDatesPageReports().initialDate,
    endDay: getDefaultDatesPageReports().endDate,
  },
};
export const InitialStateFund: FundReducer = {
  initialBankRoll: 2375000, // requested on slack, by Christopher, to be 2375000
  initialIndex: 100,
  range: {
    initialDay: getDefaultDatesPageFund().initialDate,
    isDirty: true,
  },
  fundChart: [],
  fundChangeTable: [],
  bankRollFund: {},
};

export const InitialStateBetsOverview: BetsOverviewReducer = {
  competitions: [],
  events: [],
  bets: [],
  isLoadingBets: false,
  isLoadingCompetitions: true,
};

export const InitialStateSettings: Settings = {
  isSidebarOpen: false,
  traderId: "",
};

export const INITIAL_STATE = {
  oidc: InitialSateOidc,
  events: InitialStateEvents,
  bets: InitialStateBets,
  favoriteCompetitions: InitialStatefavoriteCompetitions,
  plGrids: InitialStatePLGridsInfo,
  statsOverview: InitialStatsOverview,
  reports: InitialStateReports,
  charts: InitialStatsChart,
  fund: InitialStateFund,
  betsOverview: InitialStateBetsOverview,
  settings: InitialStateSettings,
};
