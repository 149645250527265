import * as React from "react";
import Header from "./Header";
import MainSidebar from "./MainSidebar";
import MainTabs from "./MainTabs";
import styles from "./LayoutDashboard.module.css";

export default (props: { children?: React.ReactNode; pathname: string }) => (
  <React.Fragment>
    <Header showLogout={true} />
    <div className={styles.body}>
      <MainSidebar />
      <div className={styles.center}>
        <MainTabs pathname={props.pathname} />
        <div className={styles.mainContainer}>{props.children}</div>
      </div>
    </div>
  </React.Fragment>
);
