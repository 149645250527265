import React, { Component } from "react";
import classnames from "classnames";
import styles from "./valueForm.module.css";
import { NativeSelect } from "@material-ui/core";
import "./custom-mui-style.css";

export type optionItem = { id: number | string; name: string };
type ownPropsType = {
  changeValue: Function;
  label: string | null;
  selectedOptionId: number | string;
  optionsArray: optionItem[];
  wrapperClassName?: string;
  rootClassName?: string;
  isDisabled?: boolean;
};
type Props = ownPropsType;

export class SelectForm extends Component<Props> {
  handleChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.changeValue(event.target.value);
  };

  renderLabel() {
    if (typeof this.props.label === "string" && this.props.label.length > 0) {
      return <p className={styles.option}>{this.props.label}</p>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div
        className={classnames(
          styles.optionContainer,
          this.props.wrapperClassName,
          "apply-custom-mui-styles"
        )}
      >
        {this.renderLabel()}
        <NativeSelect
          className={classnames(
            styles.inputSelection,
            this.props.rootClassName
          )}
          classes={{ icon: styles.selectIcon, disabled: styles.isDisabled }}
          onChange={this.handleChangeValue}
          value={this.props.selectedOptionId}
          disabled={this.props.isDisabled ? true : false}
        >
          {this.props.optionsArray.map((item) => (
            <option
              className={styles.selectOption}
              key={item.id}
              value={item.id}
            >
              {item.name}
            </option>
          ))}
        </NativeSelect>
      </div>
    );
  }
}
