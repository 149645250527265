import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styles from "./MainTabs.module.css";
import ButtonBase from "@material-ui/core/ButtonBase";
import ProtectedResource from "src/components/user-permissions/protected-resource";

export type MainTabsItem = {
  link: string;
  name: string;
  hasChildren: boolean;
  key?: string;
};

const ITEMS: MainTabsItem[] = [
  {
    link: "/dashboard/bets-accounting/",
    name: "Main",
    hasChildren: false,
    key: "betsAccounting_main",
  },
  {
    link: "/dashboard/bets-accounting/addbets",
    name: "Add bets",
    hasChildren: false,
    key: "betsAccounting_addBets",
  },
  {
    link: "/dashboard/bets-accounting/mybets",
    name: "My bets",
    hasChildren: false,
    key: "betsAccounting_myBets",
  },
  {
    link: "/dashboard/bets-accounting/reports",
    name: "Reports",
    hasChildren: false,
    key: "betsAccounting_reports",
  },
  {
    link: "/dashboard/bets-accounting/fund-index",
    name: "Fund",
    hasChildren: false,
    key: "betsAccounting_fund",
  },
];

type Props = { pathname: string };

class MainTabs extends Component<Props> {
  render() {
    let items = ITEMS;
    return (
      <div className={styles.mainTabs}>
        {items.map((item, index) => (
          <ProtectedResource
            componentName={"MainTabs"}
            resourceKey={item.key as string}
            key={index}
          >
            <ButtonBase
              key={index}
              classes={{ root: styles.item }}
              component={NavLink}
              exact={item.hasChildren === false}
              to={item.link}
              activeClassName={styles.isActive}
            >
              {item.name}
            </ButtonBase>
          </ProtectedResource>
        ))}
      </div>
    );
  }
}

export default MainTabs;
