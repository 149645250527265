import { Action, Reducer } from "redux";
import { InitialStateFund } from "./initial-state";
import { FundAction } from "../actions/action-fundIndex";
import { BankRollFund, FundChangeTable, FundReducer } from "src/model/myTypes";
import { UserActions } from "../actions/action-user";
import { formatChartDataByDayBeforeToday } from "../mappers/mapper-charts";
import {
  formatBankRollDataByRange,
  formatFundChangeTableData,
} from "../mappers/mapper-fund";

const unloadedState: FundReducer = InitialStateFund;

export const fundReducer: Reducer<FundReducer> = (
  state: FundReducer | undefined,
  incomingAction: Action
): FundReducer => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as FundAction | UserActions;
  switch (action.type) {
    case "CHANGE_DATE_FUND":
      return {
        ...state,
        range: {
          initialDay: action.payload.initialDay,
          isDirty: false,
        },
      };
    case "CHANGE_BANK_ROLL":
      return {
        ...state,
        initialBankRoll: action.payload,
      };
    case "CHANGE_INDEX":
      return {
        ...state,
        initialIndex: action.payload,
      };

    case "GET_FUND_STATS_CHART":
      const chartData = formatChartDataByDayBeforeToday(
        action.payload.data,
        action.payload.numberOfDays,
        action.payload.endDate
      );
      const bankRollData: BankRollFund = formatBankRollDataByRange(
        action.payload.data,
        action.payload.numberOfDays,
        action.payload.endDate,
        action.payload.initialBankRoll
      );
      const changeTableData: FundChangeTable[] = formatFundChangeTableData(
        action.payload.data,
        state.range.initialDay,
        action.payload.endDate,
        state.initialBankRoll,
        state.initialIndex
      );
      return {
        ...state,
        fundChart: chartData,
        bankRollFund: bankRollData,
        fundChangeTable: changeTableData,
      };

    case "DO_LOGOUT": {
      return {
        ...unloadedState,
        range: {
          ...unloadedState.range,
          isDirty: true,
        },
      };
    }
  }
  return state;
};
