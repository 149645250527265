import { Action, Reducer } from "redux";
import { InitialStateBets } from "./initial-state";
import { BetActions } from "../actions/action-bets";
import { MyBetsReducer } from "src/model/myTypes";
import { GetEventsStatusAction } from "../actions/action-events";
import { fromArrayToObject } from "../mappers/mapper-utils";
import {
  adaptedOBetsWithStatus,
  myBetsArrayToBetsByCompetition,
  myBetsArrayToBetsByEvent,
  myBetsArrayToBetsOpenByEvent,
  myBetToBetsByEvent,
} from "../mappers/mapper-bets";
import { UserActions } from "../actions/action-user";
import { SettleActions } from "../actions/action-settle";

const unloadedState: MyBetsReducer = InitialStateBets;

export const reducer: Reducer<MyBetsReducer> = (
  state: MyBetsReducer | undefined,
  incomingAction: Action
): MyBetsReducer => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as
    | BetActions
    | SettleActions
    | UserActions
    | GetEventsStatusAction;
  // console.log("action type!", action.type);
  switch (action.type) {
    case "SETTLE_MATCH":
      return {
        ...state,
        betsShouldUpdate: true,
      };

    case "PLACE_BET":
      const newBetId: number = action.payload.id as number;
      return {
        ...state,
        betsDatabase: {
          ...state.betsDatabase,
          [newBetId]: action.payload, //id comes from backend
        },
        betsByEvent: myBetToBetsByEvent(state.betsByEvent, action.payload),
        betsShouldUpdate: true,
      };
    case "REMOVE_BET":
      return {
        ...state,
        betsShouldUpdate: true,
      };
    case "GET_BETS": {
      const betsList = fromArrayToObject(action.payload.bets, "id");
      return {
        ...state,
        betsShouldUpdate: false,
        betsByCompetition: myBetsArrayToBetsByCompetition(action.payload.bets),
        betsDatabase: {
          ...betsList,
        },
        betsByEvent: myBetsArrayToBetsByEvent(action.payload.bets),
        betsOpenByEvent: myBetsArrayToBetsOpenByEvent(action.payload.bets),
        isLoadingBets: false,
      };
    }
    case "GET_BETS_DAY_HISTORY": {
      const betsDayHistoryList = fromArrayToObject(action.payload.bets, "id");
      return {
        ...state,
        betsDayHistory: {
          betsDatabase: betsDayHistoryList,
          betsByEvent: myBetsArrayToBetsByEvent(action.payload.bets),
          day: action.payload.day,
        },
        betsShouldUpdate: false,
      };
    }
    case "CHANGE_BETS_RANGE_DATES": {
      return {
        ...state,
        range: {
          initialDay: action.payload.initialDate,
          endDay: action.payload.endDate,
          isDirty: false,
        },
      };
    }
    case "HANDLE_MATCHES_TO_SETTLE": {
      return {
        ...state,
        autoSettle: {
          ...state.autoSettle,
          matchesToSettle: action.payload,
        },
      };
    }
    case "HANDLE_MATCH_SETTLING": {
      return {
        ...state,
        autoSettle: {
          ...state.autoSettle,
          matchSettling: action.payload,
        },
      };
    }
    case "GET_EVENTS_STATUS": {
      const adaptedBetsWithStatus = adaptedOBetsWithStatus(
        action.payload.response,
        state.betsDatabase
      );
      return {
        ...state,
        betsDatabase: {
          ...adaptedBetsWithStatus,
        },
      };
    }
    case "IS_LOADING_BETS": {
      return {
        ...state,
        isLoadingBets: true,
      };
    }

    case "ADD_FILTERED_BETS": {
      return {
        ...state,
        betsFiltered: {
          betIds: action.payload.betIds,
          tittle: action.payload.title,
        },
      };
    }

    case "CLEAR_FILTERED_BETS": {
      return {
        ...state,
        betsFiltered: {
          betIds: [],
          tittle: "",
        },
        betsDatabase: {},
        betsByEvent: {},
        betsShouldUpdate: true,
      };
    }
    case "GET_FILTERED_BETS": {
      const betsList = fromArrayToObject(action.payload.bets, "id");
      return {
        ...state,
        betsByEvent: myBetsArrayToBetsByEvent(action.payload.bets),
        betsDatabase: {
          ...betsList,
        },
        betsByCompetition: myBetsArrayToBetsByCompetition(action.payload.bets),
        isLoadingBets: false,
      };
    }

    case "DO_LOGOUT": {
      return {
        ...unloadedState,
        range: {
          ...unloadedState.range,
          isDirty: true,
        },
      };
    }
  }
  return state;
};
