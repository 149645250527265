import React, { Component } from "react";
import styles from "./eventMarkets.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { options } from "src/myEnv";
import { ButtonBase } from "@material-ui/core";

import {
  MarketPeriod,
  ScoreType,
  MarketType,
  MarketSelectionType,
} from "../../../model/enums";
import Betslip from "./betslip";
import classnames from "classnames";
import { MyCompetitionBase, MyEvent } from "src/model/myTypes";

type Props = {
  event: MyEvent;
  competitionBase: MyCompetitionBase;
};
type State = {
  showBetslip: boolean;
  marketPeriodSelected: number;
  scoreTypeSelected: number;
  marketTypeSelected: number;
  marketSelectionTypeSelected: number;
};

class EventMarkets extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showBetslip: false,
      marketPeriodSelected: MarketPeriod.FullTime,
      scoreTypeSelected: ScoreType.NoScoreType,
      marketTypeSelected: MarketType.NoMarketType,
      marketSelectionTypeSelected: MarketSelectionType.NoMarketSelection,
    };
  }

  handleSelectMarketPeriod = (marketPeriodId: number) => {
    // if user changed marketPeriod, should close betslip and reset betslip inputs
    const newShowBetslip = marketPeriodId === this.state.marketPeriodSelected;
    this.setState({
      showBetslip: newShowBetslip,
      marketPeriodSelected: marketPeriodId,
      scoreTypeSelected: ScoreType.NoScoreType,
      marketTypeSelected: MarketType.NoMarketType,
      marketSelectionTypeSelected: MarketSelectionType.NoMarketSelection,
    });
  };

  handleSelectBetType = (
    scoreTypeId: number,
    market: MarketType,
    marketSelection: MarketSelectionType
  ) => {
    if (
      market === this.state.marketTypeSelected &&
      marketSelection === this.state.marketSelectionTypeSelected &&
      scoreTypeId === this.state.scoreTypeSelected
    ) {
      // user clicked the same market&selection, should close betslip
      this.setState({
        showBetslip: false,
        scoreTypeSelected: ScoreType.NoScoreType,
        marketTypeSelected: MarketType.NoMarketType,
        marketSelectionTypeSelected: MarketSelectionType.NoMarketSelection,
      });
    } else {
      // user clicked other market&selection, should open betslip (or keep open)
      this.setState({
        showBetslip: true,
        scoreTypeSelected: scoreTypeId,
        marketTypeSelected: market,
        marketSelectionTypeSelected: marketSelection,
      });
    }
  };

  renderBetslip() {
    return this.state.showBetslip ? (
      <Betslip
        event={this.props.event}
        competitionBase={this.props.competitionBase}
        marketPeriod={this.state.marketPeriodSelected}
        scoreType={this.state.scoreTypeSelected}
        marketType={this.state.marketTypeSelected}
        marketSelectionType={this.state.marketSelectionTypeSelected}
      />
    ) : null;
  }

  renderBetType() {
    return options.scoreType.map((scoreType) => (
      <div key={scoreType.id} className={styles.wrapperBetType}>
        <span className={styles.title3}>{scoreType.name}</span>
        <div className={styles.scoreTypeContainer}>
          <div className={styles.markets}>
            <div className={styles.scoreTypeSelectionsContainer}>
              <span className={styles.scoreTypeText}>Handicap</span>
              <ButtonBase
                classes={{
                  root: classnames(
                    styles.btn,
                    this.state.marketSelectionTypeSelected === 1 &&
                      this.state.scoreTypeSelected === scoreType.id &&
                      styles.marketTypeActive
                  ),
                }}
                onClick={() =>
                  this.handleSelectBetType(
                    scoreType.id,
                    MarketType.AsianHandicap,
                    MarketSelectionType.Home
                  )
                }
              >
                Home
              </ButtonBase>
              <ButtonBase
                classes={{
                  root: classnames(
                    styles.btn,
                    this.state.marketSelectionTypeSelected === 3 &&
                      this.state.scoreTypeSelected === scoreType.id &&
                      styles.marketTypeActive
                  ),
                }}
                onClick={() =>
                  this.handleSelectBetType(
                    scoreType.id,
                    MarketType.AsianHandicap,
                    MarketSelectionType.Away
                  )
                }
              >
                Away
              </ButtonBase>
            </div>
            <div className={styles.scoreTypeSelectionsContainer}>
              <span className={styles.scoreTypeText}>
                Total {scoreType.name}
              </span>
              <ButtonBase
                classes={{
                  root: classnames(
                    styles.btn,
                    this.state.marketSelectionTypeSelected === 4 &&
                      this.state.scoreTypeSelected === scoreType.id &&
                      styles.marketTypeActive
                  ),
                }}
                onClick={() =>
                  this.handleSelectBetType(
                    scoreType.id,
                    MarketType.AsianOverUnder,
                    MarketSelectionType.Over
                  )
                }
              >
                Over
              </ButtonBase>
              <ButtonBase
                classes={{
                  root: classnames(
                    styles.btn,
                    this.state.marketSelectionTypeSelected === 5 &&
                      this.state.scoreTypeSelected === scoreType.id &&
                      styles.marketTypeActive
                  ),
                }}
                onClick={() =>
                  this.handleSelectBetType(
                    scoreType.id,
                    MarketType.AsianOverUnder,
                    MarketSelectionType.Under
                  )
                }
              >
                Under
              </ButtonBase>
            </div>
          </div>
          {this.state.scoreTypeSelected === scoreType.id &&
            this.renderBetslip()}
        </div>
      </div>
    ));
  }

  renderMarketPeriod() {
    return (
      <div className={styles.wrapperRight}>
        <span className={styles.marketPeriodText}>Market Period</span>
        {options.marketPeroid.map((marketPeriod) => (
          <ButtonBase
            classes={{
              root: classnames(
                styles.marketPeriod,
                marketPeriod.id === this.state.marketPeriodSelected &&
                  styles.marketPeriodActive
              ),
            }}
            key={marketPeriod.id}
            onClick={() => this.handleSelectMarketPeriod(marketPeriod.id)}
          >
            {marketPeriod.name}
          </ButtonBase>
        ))}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        {this.renderMarketPeriod()}
        {this.renderBetType()}
      </div>
    );
  }
}

export default EventMarkets;
