import React, { Component } from "react";
import styles from "./valueForm.module.css";
import { ButtonBase } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./custom-mui-style.css";
import InputAdornment from "@material-ui/core/InputAdornment";

type ownPropsType = {
  changeValue: Function;
  name: string;
  step: number;
  hideArrows: boolean;
  min?: string | null;
  max?: string | null;
  adornment?: boolean | null;
  disabled?: boolean;
  value: string;
  wrapperClassName?: string;
};
type Props = ownPropsType;

export class ValueForm extends Component<Props> {
  private elementParent: { [key: string]: HTMLElement | null };
  constructor(props: Props) {
    super(props);
    this.elementParent = {};
  }

  handleParentElementRef = (
    el: HTMLElement,
    hideArrows: boolean,
    key: string
  ) => {
    if (
      hideArrows === false &&
      this.elementParent.hasOwnProperty(key) === false
    ) {
      this.elementParent[key] = el as HTMLElement;
      const stepNumber = this.props.step;
      (this.elementParent[key] as HTMLElement).addEventListener(
        "keydown",
        (event) =>
          this.handleArrowKeyPress(
            (event as unknown) as KeyboardEvent,
            stepNumber as number
          )
      );
    } else {
      this.elementParent[key] = el as HTMLElement;
    }
  };

  invalidValueAlert = (key: string) => {
    if (
      typeof this.elementParent !== "undefined" &&
      typeof (this.elementParent[key] as HTMLDivElement) !== "undefined" &&
      (this.elementParent[key] as HTMLDivElement) !== null
    ) {
      ((this.elementParent[key] as HTMLDivElement)
        .classList as DOMTokenList).add(styles.invalid);
      setTimeout(() => {
        if (
          typeof this.elementParent !== "undefined" &&
          typeof (this.elementParent[key] as HTMLDivElement) !== "undefined" &&
          (this.elementParent[key] as HTMLDivElement) !== null
        ) {
          ((this.elementParent[key] as HTMLDivElement)
            .classList as DOMTokenList).remove(styles.invalid);
        }
      }, 300);
    }
  };

  handleArrowKeyPress = (event: KeyboardEvent, step: number) => {
    switch (event.key) {
      case "ArrowUp":
        this.changeOptionClick(step * 1);
        break;
      case "ArrowDown":
        this.changeOptionClick(step * -1);
        break;
      default:
        break;
    }
  };

  getDecimals = (delta: number) => {
    let num = delta.toString();
    let index = num.indexOf(".");
    if (index === -1) {
      // is integer
      return 0;
    } else {
      // is decimal
      return num.length - index - 1;
    }
  };

  /**
   * Handles keyboard arrows
   * @param {Number} step
   */
  changeOptionClick(delta: number) {
    let nextValue: string = "";
    const max = this.props.max;
    const min = this.props.min;
    const decimals = this.getDecimals(delta);
    if (
      this.props.hasOwnProperty("max") &&
      Number(this.props.value) === Number(max)
    ) {
      if (delta < 0) {
        nextValue = ((Number(this.props.value) + delta).toFixed(
          decimals
        ) as unknown) as string;
      } else nextValue = this.props.value;
    } else if (
      this.props.hasOwnProperty("min") &&
      Number(this.props.value) === Number(min)
    ) {
      if (delta > 0) {
        nextValue = ((Number(this.props.value) + delta).toFixed(
          decimals
        ) as unknown) as string;
      } else nextValue = this.props.value;
    } else if (
      this.props.hasOwnProperty("min") &&
      Number(this.props.value) < Number(min)
    ) {
      nextValue = (this.props.min as unknown) as string;
    } else if (
      this.props.hasOwnProperty("max") &&
      Number(this.props.value) > Number(max)
    ) {
      nextValue = (this.props.max as unknown) as string;
    } else
      nextValue = ((Number(this.props.value) + delta).toFixed(
        decimals
      ) as unknown) as string;

    this.props.changeValue(nextValue);
  }

  /**
   * Evaluates and formats user input
   * @param event
   */
  handleChangeValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let newValue = event.target.value;
    if (/[,.]/.test(newValue)) {
      if (this.props.step === 1) {
        //no decimals
        //dont let write
        newValue = newValue.split(/[,.]/).join("");
      } else {
        // alow comma/point
        // but replace comma with point
        newValue = newValue.replace(/,/g, ".");
        if (newValue.split(".").length > 2) {
          // can't have more than 1 decimal separator
        }
      }
    }
    let newValueNumber = Number(newValue);
    if (isNaN(newValueNumber) === true) {
      //not a number return
    } else {
      if (
        this.props.hasOwnProperty("max") === true &&
        newValueNumber > ((this.props.max as unknown) as number)
      ) {
        // ensure newValue is not higher than max
        newValue = (this.props.max as unknown) as string;
      }
      if (
        this.props.hasOwnProperty("min") === true &&
        newValueNumber < ((this.props.min as unknown) as number)
      ) {
        // ensure newValue is not bellow min
        newValue = (this.props.min as unknown) as string;
      }

      this.props.changeValue(newValue);
    }
  };

  /**
   * Evaluates if user input is corrected. If so, a color indication happens
   * @param event
   */
  handleValueOnBlur = (
    event: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const optionName = event.target.name;
    let newValue = event.target.value;
    let newValueNumber = Number(newValue);
    if (optionName === "Price") {
      /*       if (decimals === true) { */
      //check if has decimals
      let splited = newValue.split("."); //split the string
      if (splited.length > 1 && splited[1].length > 3) {
        //dont let more than 3 decimals
        newValue = parseFloat(newValue).toFixed(3);
      }
      /*       } */
    }
    if (optionName === "Line") {
      //check if has decimals
      let splited = newValue.split(".");
      if (splited.length > 1 && splited[1].length > 0) {
        //Only runs if values are inserted after comma
        //Changes value to closest step
        let tempValue = newValueNumber * 100;
        const step = (this.props.step as number) * 100;
        const divideByStep = tempValue / step;
        tempValue = Math.round(divideByStep) * step;
        newValue = ((tempValue / 100) as unknown) as string;
      }
    }
    //If value is changed from input, change styles for 300ms
    if (Number(newValue) !== Number(event.target.value)) {
      this.invalidValueAlert(optionName);
    }
    this.props.changeValue(newValue);
  };

  render() {
    const adornment = this.props.adornment ? (
      <InputAdornment position="start">%</InputAdornment>
    ) : null;
    return (
      <div
        className={classnames(
          styles.optionContainer,
          this.props.wrapperClassName,
          "apply-custom-mui-styles"
        )}
        ref={(el) =>
          this.handleParentElementRef(
            el as HTMLDivElement,
            this.props.hideArrows as boolean,
            this.props.name
          )
        }
      >
        {this.props.name !== "" && (
          <p className={styles.option}>{this.props.name}</p>
        )}
        <div className={styles.relative}>
          <TextField
            name={this.props.name}
            inputProps={{
              step: this.props.hasOwnProperty("step") ? this.props.step : null,
              min: this.props.hasOwnProperty("min") ? this.props.min : null,
              max: this.props.hasOwnProperty("max") ? this.props.max : null,
            }}
            value={
              typeof this.props.value === "undefined" ||
              this.props.value === null
                ? ""
                : this.props.value
            }
            classes={{
              root: classnames(
                styles.inputText,
                this.props.hideArrows === true && styles.hideArrows
              ),
            }}
            InputProps={{
              classes: { underline: styles.inputProps },
              endAdornment: adornment,
            }}
            onChange={this.handleChangeValue}
            onBlur={this.handleValueOnBlur}
            disabled={
              typeof this.props.disabled !== "undefined"
                ? this.props.disabled
                : false
            }
          />
          <div
            className={classnames(
              styles.arrowsContainer,
              this.props.hideArrows === true && styles.hide
            )}
          >
            <ButtonBase
              classes={{
                root: styles.arrow,
              }}
              onClick={() => this.changeOptionClick(this.props.step * 1)}
              tabIndex="-1"
            >
              <ArrowDropDownIcon className={styles.up}></ArrowDropDownIcon>
            </ButtonBase>
            <ButtonBase
              classes={{
                root: styles.arrow,
              }}
              onClick={() => this.changeOptionClick(this.props.step * -1)}
              tabIndex="-1"
            >
              <ArrowDropDownIcon className={styles.down}></ArrowDropDownIcon>
            </ButtonBase>
          </div>
        </div>
      </div>
    );
  }
}
