import { Action, Reducer } from "redux";
import { InitialStateSettings } from "./initial-state";
import { Settings } from "src/model/myTypes";
import { UserActions } from "../actions/action-user";
import { SettingsActions } from "../actions/action-settings";

const unloadedState: Settings = InitialStateSettings;

export const reducer: Reducer<Settings> = (
  state: Settings | undefined,
  incomingAction: Action
): Settings => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as UserActions | SettingsActions;
  switch (action.type) {
    case "HANDLE_SIDEBAR":
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      };
    case "CLOSE_SIDEBAR":
      return {
        ...state,
        isSidebarOpen: false,
      };
    case "CHANGE_TRADER":
      return {
        ...state,
        traderId: action.payload,
      };
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
