import * as React from "react";
import FlagIcon, { isValidCountryCode } from "./FlagIcon";
import classnames from "classnames";
import styles from "./flag-converter.module.css";

export function flagConverter(countryAlpha2Code: string) {
  if (isValidCountryCode(countryAlpha2Code)) {
    return (
      <FlagIcon
        className={styles.flagIconContainer}
        code={countryAlpha2Code}
        Component={"span"}
      />
    );
  } else {
    return (
      <span
        className={classnames(
          styles.flagIconContainer,
          styles.specialFlag,
          styles.footballIcon
        )}
      />
    );
  }
}
