import { Action, Reducer } from "redux";
import { InitialStateBetsOverview } from "./initial-state";
import { BetsOverviewReducer } from "src/model/myTypes";
import { UserActions } from "../actions/action-user";
import { OverviewActions } from "../actions/action-bets-overview";

const unloadedState: BetsOverviewReducer = InitialStateBetsOverview;

export const reducer: Reducer<BetsOverviewReducer> = (
  state: BetsOverviewReducer | undefined,
  incomingAction: Action
): BetsOverviewReducer => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as UserActions | OverviewActions;
  switch (action.type) {
    case "IS_LOADING_BETS_OVERVIEW":
      return {
        ...state,
        isLoadingBets: true,
      };
    case "IS_LOADING_COMPETITIONS_OVERVIEW":
      return {
        ...state,
        isLoadingCompetitions: true,
      };
    case "GET_EVENTS_OVERVIEW":
      return {
        ...state,
        events: action.payload,
      };
    case "GET_BETS_OVERVIEW":
      return {
        ...state,
        bets: action.payload,
        isLoadingBets: false,
      };
    case "GET_COMPETITIONS_OVERVIEW":
      return {
        ...state,
        competitions: action.payload,
        isLoadingCompetitions: false,
      };
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
