export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  let stateToSave = {
    ...state,
    bets: { ...state.bets },
    reports: { ...state.reports },
  };
  delete stateToSave.events;
  delete stateToSave.botLogs;
  stateToSave.bets.betsDatabase = {};
  stateToSave.reports.tradersWithCompetitions = [];
  const serializedState = JSON.stringify(stateToSave);
  localStorage.setItem("state", serializedState);
};

export function clearStorage() {
  localStorage.removeItem("state");
}
