import React, { Component } from "react";
import styles from "./reportsData.module.css";
import { connect } from "react-redux";
import { INITIAL_STATE } from "src/redux/reducers/initial-state";
import {
  ReportViewType,
  ReportMarketPeriod,
  ReportScoreType,
} from "src/model/enums";
import {
  formatCurrency,
  formatPercentage,
  formatNumber,
} from "src/utils/numberUtils";
import classNames from "classnames";
import { ButtonBase } from "@material-ui/core";
import { ViewTypeReports } from "src/model/myTypes";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { addBetsFiltered } from "src/redux/actions/action-bets";

type ownPropsType = {
  viewType: ReportViewType;
};

type Props = ownPropsType &
  RouteComponentProps<{}> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  traderId: number;
  regionReadId: number;
  competitionReadId: number;
  dateFrom: Date;
  dateTo: Date;
  reportViewType: ReportViewType;
  reportMarketPeriod: ReportMarketPeriod;
  reportScoreType: ReportScoreType;
  marketPeriodSelected: ReportMarketPeriod;
  scoreTypeSelected: ReportScoreType;
  viewTypeSelected: ReportViewType;
};

class ReportsData extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      traderId: 0,
      regionReadId: 0,
      competitionReadId: 0,
      marketPeriodSelected: ReportMarketPeriod.All,
      scoreTypeSelected: ReportScoreType.All,
      viewTypeSelected: ReportViewType.Calendar,
      dateFrom: this.props.range.initialDay,
      dateTo: this.props.range.endDay,
      reportViewType: ReportViewType.Calendar,
      reportMarketPeriod: ReportMarketPeriod.All,
      reportScoreType: ReportScoreType.All,
    };
  }

  handleClickBets(viewTypeReports: ViewTypeReports) {
    if (viewTypeReports.bets === 0) {
    } else {
      this.props.addBetsFiltered(
        viewTypeReports.betIds,
        "Reports, " + viewTypeReports.name
      );
      this.props.history.push("/dashboard/bets-accounting/mybets");
    }
  }

  renderTableCalender() {
    return this.props.calendarArray
      .sort((a, b) => {
        if (a.name === "OVERALL") {
          return 1;
        } else if (b.name === "OVERALL") {
          return -1;
        } else {
          return 0;
        }
      })
      .map((line) => {
        // console.log("line", line);
        return (
          <div key={line.name} className={styles.tableLine}>
            <div className={styles.lineName}>{line.name}</div>
            <div className={styles.lineStake}>
              {formatCurrency(line.stake, 0)}
            </div>
            <div
              className={classNames(
                styles.lineProfit,
                line.profitLoss > 0 && styles.green,
                line.profitLoss < 0 && styles.red
              )}
            >
              {formatCurrency(line.profitLoss, 0)}
            </div>
            <div
              className={classNames(
                styles.lineMargin,
                line.margin > 0 && styles.green,
                line.margin < 0 && styles.red
              )}
            >
              {formatPercentage(line.margin, 2)}
            </div>
            <div className={styles.lineBets}>
              <ButtonBase
                classes={{
                  root: styles.btn,
                  disabled: styles.disabled,
                }}
                component={"div"}
                disabled={line.bets === 0}
                onClick={() => this.handleClickBets(line)}
              >
                {formatNumber(line.bets, 0)}
              </ButtonBase>
            </div>
          </div>
        );
      });
  }

  renderTableBetType() {
    return this.props.betTypeArray.map((line) => {
      return (
        <div key={line.name} className={styles.tableLine}>
          <div className={styles.lineName}>{line.name}</div>
          <div className={styles.lineStake}>
            {formatCurrency(line.stake, 2)}
          </div>
          <div
            className={classNames(
              styles.lineProfit,
              line.profitLoss > 0 && styles.green,
              line.profitLoss < 0 && styles.red
            )}
          >
            {formatCurrency(line.profitLoss, 2)}
          </div>
          <div
            className={classNames(
              styles.lineMargin,
              line.margin > 0 && styles.green,
              line.margin < 0 && styles.red
            )}
          >
            {formatPercentage(line.margin, 2)}
          </div>
          <div className={styles.lineBets}>
            <ButtonBase
              classes={{
                root: styles.btn,
                disabled: styles.disabled,
              }}
              component={"div"}
              disabled={line.bets === 0}
              onClick={() => this.handleClickBets(line)}
            >
              {formatNumber(line.bets, 0)}
            </ButtonBase>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className={styles.tableReports}>
        <div className={styles.tableHead}>
          <div className={styles.lineName}></div>
          <div className={styles.lineStake}>Stake</div>
          <div className={styles.lineProfit}>Profit/loss</div>
          <div className={styles.lineMargin}>Margin</div>
          <div className={styles.lineBets}>Bets</div>
        </div>
        <div className={styles.loadingParent}>
          {this.props.isLoadingReports && (
            <div className={styles.loadingContainer}>
              <div className={"loader-circle"}></div>
            </div>
          )}
          {this.props.viewType === ReportViewType.Calendar
            ? this.renderTableCalender()
            : this.renderTableBetType()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: typeof INITIAL_STATE) => {
  return {
    betTypeArray: state.reports.betType,
    calendarArray: state.reports.calender,
    isLoadingReports: state.reports.isLoading,
    range: state.reports.range,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addBetsFiltered: (betIds: number[], title: string) => {
      dispatch(addBetsFiltered(betIds, title));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsData)
);
