export class EnumHelper {
  private constructor() {}

  static getNamesAndIds<T extends number>(
    e: any
  ): { name: string; id: number }[] {
    return EnumHelper.getNames(e).map((n) => {
      return { name: this.convertToReadableName(n), id: e[n] as T };
    });
  }

  static getNamesAndIdsFiltered<T extends number>(
    e: any,
    excludeIdsArray: number[]
  ): { name: string; id: number }[] {
    return EnumHelper.getNamesAndIds(e).filter(
      (result) => excludeIdsArray.indexOf(result.id) === -1
    );
  }

  static getNamesAndIdsFilteredIncludeOnly<T extends number>(
    e: any,
    includeOnlyIdsArray: number[]
  ): { name: string; id: number }[] {
    return EnumHelper.getNamesAndIds(e).filter(
      (result) => includeOnlyIdsArray.indexOf(result.id) !== -1
    );
  }

  static convertToReadableName(n: string): string {
    let name = n;

    if (name === "AverageFairResult") return "xG";
    else if (name === "BasicStats") return "Shots";

    // Converts the enum name to 'Camel Case Format'

    let splits = n.split("_");
    if (splits.length > 1) {
      name = "";
      for (let split of splits) {
        name += split[0].toUpperCase();
        name += split.slice(1).toLowerCase();
      }
    }
    if (name !== name.toUpperCase()) {
      // insert a space before all caps
      name = name.replace(/([A-Z])/g, " $1");
    } else {
      name = name.toLowerCase();
    }

    return (
      name
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
        // trim spaces at the beggining and end
        .trim()
    );
  }

  static getNames(e: any) {
    return Object.keys(e).filter((k) => typeof e[k] === "number") as string[];
  }

  static getIds(e: any) {
    return Object.keys(e).filter((k) => typeof e[k] !== "number") as string[];
  }

  static getValues<T extends number>(e: any) {
    return Object.keys(e)
      .map((k) => e[k])
      .filter((v) => typeof v === "number") as T[];
  }

  static getName(enumerator: any, id: number) {
    return enumerator[id] || "";
  }

  static getIdFromName(enumerator: any, name: string) {
    const enumArray = EnumHelper.getNamesAndIds(enumerator);
    const resultEnumItem = enumArray.find(
      (e) => e.name.toLowerCase() === name.toLowerCase()
    );
    if (typeof resultEnumItem === "undefined") {
      return -1;
    } else {
      return resultEnumItem.id;
    }
  }

  static getReadableName(enumerator: any, id: number) {
    return EnumHelper.convertToReadableName(EnumHelper.getName(enumerator, id));
  }

  static getNameInitial(enumerator: any, id: number) {
    return EnumHelper.getName(enumerator, id).charAt(0);
  }
}
