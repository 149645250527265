import { Action, Reducer } from "redux";
import { InitialStatePLGridsInfo } from "./initial-state";
import { KnownAction } from "../actions/action-plgrids";
import { MyPLGridsInfo } from "src/model/myTypes";
import { UserActions } from "../actions/action-user";

const unloadedState: MyPLGridsInfo = InitialStatePLGridsInfo;

export const reducer: Reducer<MyPLGridsInfo> = (
  state: MyPLGridsInfo | undefined,
  incomingAction: Action
): MyPLGridsInfo => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as KnownAction | UserActions;
  switch (action.type) {
    case "GET_PROFIT_LOSS_GRIDS_BY_MATCH":
      const gridInfoPayload = action.gridInfoPayload;
      return {
        ...gridInfoPayload,
      };
    case "DO_LOGOUT": {
      return {
        ...unloadedState,
      };
    }
  }
  return state;
};
